<div fxLayout="row" fxLayoutAlign="space-between">
  <label class="title">Cruscotto</label>
  <img class="image_close" src="assets/image/dashboard/back.svg" alt="" (click)="dialog.closeAll()">
</div>
<div class="container">
  <mat-grid-list
    cols="12"
    rowHeight="90px"
    [gutterSize]="'10px'"
    class="container_grid">
    <mat-grid-tile [colspan]="this.innerWidth > 1000 ? 4 : 12" [rowspan]="2" class="card">
      <div class="container_card" fxLayout="column" fxLayoutAlign="flex-start" fxLayoutGap="10px">
        <div fxLayout="row" fxLayoutAlign="flex-start" fxLayoutGap="10px">
          <label class="title">Wallet</label>
          <img class="image_wallet" src="assets/image/dashboard/euro.svg" alt="">
        </div>
        <div class="container_data" fxLayout="column" fxLayoutAlign="flex-start" fxLayoutGap="20px">
          <div  class="container_chart" fxLayout="row wrap" fxLayoutAlign="space-between" fxLayoutGap="10px">
            <div *ngIf="walletEuro" fxLayout="column" fxLayoutGap="5px">
              <label class="subtitle_gray">Bilancio</label>
              <label class="subtitle">€ {{this.walletEuro.balance | number: "1.2-2":"it" }}</label>
            </div>
          </div>
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="this.innerWidth > 1000 ? 4 : 12" [rowspan]="2" class="card">
      <div class="container_card" fxLayout="column" fxLayoutAlign="flex-start" fxLayoutGap="40px">
        <label class="title">Totale caparre pagate</label>
        <div fxLayout="row" fxLayoutAlign="space-between">
          <label class="number" >{{this.deposit ? this.deposit : 0}}</label>
          <img class="goToComplexDetails" src="assets/image/dashboard/arrow-open-complex.svg" alt="" (click)="goToPage('/dashboard/buy/escrow')">
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="this.innerWidth > 1000 ? 4 : 12" [rowspan]="2" class="card">
      <div class="container_card" fxLayout="column" fxLayoutAlign="flex-start" fxLayoutGap="40px">
        <label class="title">Appartamenti venduti</label>
        <div fxLayout="row" fxLayoutAlign="space-between">
          <label class="number" >{{this.apartment_purchased ? this.apartment_purchased : 0}}</label>
          <img class="goToComplexDetails" src="assets/image/dashboard/arrow-open-complex.svg" alt="" (click)="goToPage('/dashboard/buy/complex-buy')">
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="this.innerWidth > 1000 ? 4 : 12" [rowspan]="2" class="card">
      <div class="container_card" fxLayout="column" fxLayoutAlign="flex-start" fxLayoutGap="10px">
        <div fxLayout="row" fxLayoutAlign="flex-start" fxLayoutGap="10px">
          <label class="title">Wallet</label>
          <img class="image_wallet" src="assets/image/dashboard/HT.svg" alt="">
        </div>
        <div *ngIf="!loadWalletCrypto" class="container_data" fxLayout="column" fxLayoutAlign="flex-start" fxLayoutGap="20px">
          <div class="container_chart" fxLayout="row wrap" fxLayoutAlign="space-between" fxLayoutGap="40px">
            <div fxLayout="column" fxLayoutGap="5px">
              <label class="subtitle_gray">Bilancio</label>
              <label class="subtitle">HT {{this.walletCrypto.balance | number: "1.2-2":"it" }}</label>
            </div>
          </div>
        </div>
        <div *ngIf="loadWalletCrypto" class="container_spinner" fxLayout="column" fxLayoutGap="10px">
          <mat-spinner [diameter]="20"></mat-spinner>
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="this.innerWidth > 1000 ? 4 : 12" [rowspan]="2" class="card">
      <div class="container_card" fxLayout="column" fxLayoutAlign="flex-start" fxLayoutGap="40px">
        <label class="title">Totale visite prenotate</label>
        <div fxLayout="row" fxLayoutAlign="space-between">
          <label class="number" >{{this.booking ? this.booking : 0}}</label>
          <img class="goToComplexDetails" src="assets/image/dashboard/arrow-open-complex.svg" alt="" (click)="goToPage('/dashboard/buy/reserved')">
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="this.innerWidth > 1000 ? 4 : 12" [rowspan]="2" class="card">
      <div class="container_card" fxLayout="column" fxLayoutAlign="flex-start" fxLayoutGap="40px">
        <label class="title">Totale proposte voting</label>
        <div fxLayout="row" fxLayoutAlign="space-between">
          <label class="number" >{{this.proposal ? this.proposal : 0}}</label>
          <img class="goToComplexDetails" src="assets/image/dashboard/arrow-open-complex.svg" alt="" (click)="goToPage('/dashboard/invest/admin-voting-proposals')">
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="this.innerWidth > 1000 ? 4 : 12" [rowspan]="2" class="card">
      <div class="container_card" fxLayout="column" fxLayoutAlign="flex-start" fxLayoutGap="40px">
        <label class="title">Totale investimenti</label>
        <div fxLayout="row" fxLayoutAlign="space-between">
          <label class="number" >{{this.investment ? this.investment : 0}}</label>
          <img class="goToComplexDetails" src="assets/image/dashboard/arrow-open-complex.svg" alt="" (click)="goToPage('/dashboard/invest/project')" >
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="this.innerWidth > 1000 ? 4 : 12" [rowspan]="2" class="card">
      <div class="container_card" fxLayout="column" fxLayoutAlign="flex-start" fxLayoutGap="40px">
        <label class="title">Totale votazioni</label>
        <div fxLayout="row" fxLayoutAlign="space-between">
          <label class="number" >{{this.voting ? this.voting : 0}}</label>
          <img class="goToComplexDetails" src="assets/image/dashboard/arrow-open-complex.svg" alt="" (click)="goToPage('/dashboard/invest/admin-voting')">
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="this.innerWidth > 1000 ? 4 : 12" [rowspan]="2" class="card">
      <div class="container_card" fxLayout="column" fxLayoutAlign="flex-start" fxLayoutGap="40px">
        <label class="title">Totale pratiche C/R</label>
        <div fxLayout="row" fxLayoutAlign="space-between">
          <label class="number" >{{this.practices ? this.practices : 0}}</label>
          <img class="goToComplexDetails" src="assets/image/dashboard/arrow-open-complex.svg" alt="" (click)="goToPage('/dashboard/build/admin/practices')">
        </div>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>
