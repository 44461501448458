import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AdminService } from '../../../../../../../../../../../core/service/admin.service';
import {MatDialog} from "@angular/material/dialog";
import {
  DialogDashboardAdminComponent
} from "../../../../../../../../../../../components/dashboard/dialog-dashboard-admin/dialog-dashboard-admin.component";

@Component({
  selector: 'app-invest-superadmin-home',
  templateUrl: './invest-super-admin-home.component.html',
  styleUrls: ['./invest-super-admin-home.component.scss'],
})
export class InvestSuperAdminHomeComponent implements OnInit {
  displayedColumns: string[] = ['project', 'user', 'status', 'data'];
  displayedColumns1: string[] = ['user_id', 'data', 'amount', 'name', 'wallet'];
  allComplexToInvest: any;
  innerWidth: any;
  allWithdraw = [];

  constructor(
    private _formBuilder: FormBuilder,
    private adminService: AdminService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    const dashboard = localStorage.getItem('dashboard');
    if(dashboard){
      this.dialog.open(DialogDashboardAdminComponent, {width: '90%',})
      localStorage.removeItem('dashboard');
    }
    this.innerWidth = window.innerWidth;
    this.adminService.getInvestmentsPaginationOrder('0', '9', null).subscribe(
      (res) => {
        this.allComplexToInvest = res.data;
      },
      () => {}
    );
    this.adminService.getAllWithdrawRequestsPagination('0', '9').subscribe(
      (res) => {
        this.allWithdraw = res.data;
      },
      () => {}
    );
  }
}
