import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { NotifierService } from '../../../../../../../core/service/notifier.service';
import { BuildRenovateService } from '../../../../../../../core/service/buildRenovate.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-dialog-reserved-content',
  templateUrl: './dialog-view-open-c-m-e.component.html',
  styleUrls: ['./dialog-view-open-c-m-e.component.scss'],
})
export class DialogViewOpenCMEComponent implements OnInit {
  info: any;

  constructor(
    public dialogRef: MatDialogRef<DialogViewOpenCMEComponent>,
    public dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private buildRenovateService: BuildRenovateService,
    private _notifierService: NotifierService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) private data: DialogViewOpenCMEComponent
  ) {}

  ngOnInit(): void {
    this.info = this.data;
  }
  openEdit() {
    this.router.navigate([this.info.edit]);
    this.dialog.closeAll();
  }
  openView() {
    this.router.navigate([this.info.view]);
    this.dialog.closeAll();
  }
}
