import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class PriceService {
  public USDPrice: any;

  constructor(private _http: HttpClient) {}

  get token(): string | null {
    return localStorage.getItem('token');
  }

  public getEuroPrice() {
    this._http
      .get('https://api.exchangerate.host/latest?base=USDEUR')
      .subscribe((res: any) => {
        const rates = res.rates;
        this.USDPrice = rates.USD;
      });
  }
}
