<div *ngIf="animation" class="container_animation">
  <div class="container">
    <img class="image image1" src="assets/image/animation/img_0.png" alt="" />
    <img class="image image2" src="assets/image/animation/img_1.png" alt="" />
  </div>
</div>
<div *ngIf="env.primary_network !== 'POLYGON'" class="staging_toolbar">
  <label>Ambiente di Staging</label>
</div>
<mat-drawer-container
  *ngIf="
    routerLink !== 'dashboard' &&
    routerLink !== '' &&
    routerLink !== 'undefined'
  "
  class="main-container"
>
  <mat-drawer #drawer [mode]="'over'" style="position: fixed">
    <app-sidenav [drawer]="drawer"></app-sidenav>
  </mat-drawer>
  <mat-drawer #drawerRight mode="over" position="end" style="position: fixed">
    <app-sidenav-right [drawerRight]="drawerRight"></app-sidenav-right>
  </mat-drawer>
  <mat-drawer-content>
    <div
      [ngClass]="{ hdrScroll: !scrolling, hdr: scrolling }"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      fxLayoutGap="20px"
    >
      <div
        class="toolbar"
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="20px"
      >
        <img
          [ngClass]="{
            button_menu_left: !scrolling,
            button_menu_left_scroll: scrolling
          }"
          src="assets/image/logo/complete_logo.svg"
          alt=""
          [routerLink]="'/home'"
        />
        <img
          [ngClass]="{
            button_menu_right: !scrolling,
            button_menu_right_scroll: scrolling
          }"
          src="assets/image/home/menu.svg"
          (click)="drawer.open()"
          alt=""
        />
      </div>
    </div>
    <router-outlet></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>
<mat-drawer-container
  *ngIf="routerLink === 'dashboard' && homeService.profile"
  class="main-container"
>
  <mat-drawer
    #drawerMobile
    [mode]="innerWidth >= 1000 ? 'side' : 'over'"
    [opened]="innerWidth >= 1000"
    style="position: fixed; border: transparent"
  >
    <app-sidenav-dashboard
      [drawer]="innerWidth < 1000 && drawerMobile"
      [typeRouter]="type"
    ></app-sidenav-dashboard>
  </mat-drawer>

  <mat-drawer-content style="padding: 0 20px">
    <div *ngIf="innerWidth >= 1000" class="toolbar-dashboard">
      <form
        [formGroup]="searchForm"
        fxLayout="row"
        fxLayoutAlign="space-between"
        style="width: 100%; padding: 10px 20px !important"
      >
        <mat-icon class="search">search</mat-icon>
        <input
          class="input_search"
          placeholder="Cerca"
          formControlName="search"
          (ngModelChange)="filterAllComplex($event)"
        />
      </form>
      <div
        class="container_column_select"
        style="padding: 10px 20px !important"
      >
        <div class="container_select" fxLayoutGap="20px">
          <form [formGroup]="selectForm" fxLayout="column" fxLayoutGap="20px">
            <select
              id="how"
              class="styling_select"
              (change)="setSelect()"
              formControlName="select"
            >
              <option
                *ngFor="let o of option"
                value="{{
                  o === 'Acquista'
                    ? 'buy'
                    : o === 'Costruisci/Ristruttura'
                    ? 'build'
                    : o === 'Investi'
                    ? 'invest'
                    : null
                }}"
                class="option"
              >
                {{ o }}
              </option>
            </select>
          </form>
          <div *ngIf="!this.notification" fxLayout="row" style="width: 40px">
            <mat-icon class="notify" [matMenuTriggerFor]="menu"
              >notifications_none</mat-icon
            >
          </div>
          <div *ngIf="this.notification" fxLayout="row" style="width: 40px">
            <mat-icon class="notify_enable" [matMenuTriggerFor]="menu"
              >notifications_active</mat-icon
            >
            <div class="container-notification" [matMenuTriggerFor]="menu">
              <label class="number-notification">{{
                index > 100 ? "99+" : index
              }}</label>
            </div>
          </div>
          <mat-menu
            #menu="matMenu"
            class="customize"
            style="margin-top: 30px !important"
            yPosition="below"
          >
            <div fxLayout="column">
              <div
                class="container_notification"
                fxLayout="row"
                fxLayoutAlign="space-between"
              >
                <label class="title">Notifiche</label>
                <img
                  [matTooltipClass]="{ 'tool-tip': true }"
                  [matTooltipPosition]="'above'"
                  matTooltip="Marca tutte come lette"
                  style="cursor: pointer"
                  src="assets/image/dashboard/notifications_off.svg"
                  alt=""
                  class="load_image"
                  (click)="readAllNotifications()"
                />
              </div>
              <div fxLayout="column" fxLayoutGap="5px">
                <div
                  [ngClass]="
                    i % 2 === 0
                      ? 'container_notification'
                      : 'container_data_blue'
                  "
                  *ngFor="let notify of this.allNotify; let i = index"
                  fxLayout="column"
                >
                  <div fxLayout="row" fxLayoutAlign="space-between">
                    <label class="data">{{
                      notify.date * 1000 | date: "dd/MM/YYYY HH:mm":"it"
                    }}</label>
                    <div *ngIf="!notify.isRead" class="unread"></div>
                  </div>
                  <label
                    [ngClass]="
                      notify.path !== 'none' ? 'message_pointer' : 'message'
                    "
                    (click)="notify.path !== 'none' && goToPage(notify.path)"
                    >{{ notify.message }}</label
                  >
                </div>
              </div>
              <div class="container_notification" fxLayoutAlign="center">
                <label class="text_show_all" (click)="goToNotification()"
                  >Vedi tutte</label
                >
              </div>
            </div>
          </mat-menu>
          <label class="timer">{{
            this.dateNow | date: "dd MMM YYYY | HH:mm:ss":"":"it" | titlecase
          }}</label>
        </div>
      </div>
    </div>
    <div *ngIf="innerWidth < 1000" class="toolbar-mobile">
      <div
        class="container_column_select"
        style="padding: 10px 20px !important"
      >
        <div class="container_select" fxLayoutGap="20px">
          <form
            [formGroup]="searchForm"
            fxLayout="row"
            fxLayoutAlign="space-between"
            style="width: 100%; padding: 10px 20px !important"
          >
            <mat-icon *ngIf="innerWidth > 600" class="search">search</mat-icon>
            <input
              class="input_search"
              type="search"
              placeholder="Cerca"
              formControlName="search"
              (ngModelChange)="filterAllComplex($event)"
            />
          </form>
          <form
            *ngIf="innerWidth > 600"
            [formGroup]="selectForm"
            fxLayout="column"
            fxLayoutGap="20px"
          >
            <select
              id="how1"
              class="styling_select"
              (change)="setSelect()"
              formControlName="select"
            >
              <option
                *ngFor="let o of option"
                value="{{
                  o === 'Acquista'
                    ? 'buy'
                    : o === 'Costruisci/Ristruttura'
                    ? 'build'
                    : o === 'Investi'
                    ? 'invest'
                    : null
                }}"
                class="option"
              >
                {{ o }}
              </option>
            </select>
          </form>
          <div *ngIf="!this.notification" fxLayout="row" style="width: 40px">
            <mat-icon class="notify" [matMenuTriggerFor]="menu"
              >notifications_none</mat-icon
            >
          </div>
          <div *ngIf="this.notification" fxLayout="row" style="width: 40px">
            <mat-icon class="notify_enable" [matMenuTriggerFor]="menu"
              >notifications_active</mat-icon
            >
            <div class="container-notification" [matMenuTriggerFor]="menu">
              <label class="number-notification">{{
                index > 100 ? "99+" : index
              }}</label>
            </div>
          </div>
          <mat-menu
            #menu="matMenu"
            class="customize"
            style="margin-top: 30px !important"
            yPosition="below"
          >
            <div fxLayout="column">
              <div
                class="container_notification"
                fxLayout="row"
                fxLayoutAlign="space-between"
              >
                <label class="title">Notifiche</label>
                <img
                  [matTooltipClass]="{ 'tool-tip': true }"
                  [matTooltipPosition]="'above'"
                  matTooltip="Marca tutte come lette"
                  style="cursor: pointer"
                  src="assets/image/dashboard/notifications_off.svg"
                  alt=""
                  class="load_image"
                  (click)="readAllNotifications()"
                />
              </div>
              <div fxLayout="column" fxLayoutGap="5px">
                <div
                  [ngClass]="
                    i % 2 === 0
                      ? 'container_notification'
                      : 'container_data_blue'
                  "
                  *ngFor="let notify of this.allNotify; let i = index"
                  fxLayout="column"
                >
                  <div fxLayout="row" fxLayoutAlign="space-between">
                    <label class="data">{{
                      notify.date * 1000 | date: "dd/MM/YYYY":"it"
                    }}</label>
                    <div *ngIf="!notify.isRead" class="unread"></div>
                  </div>
                  <label
                    [ngClass]="
                      notify.path !== 'none' ? 'message_pointer' : 'message'
                    "
                    (click)="notify.path !== 'none' && goToPage(notify.path)"
                    >{{ notify.message }}</label
                  >
                </div>
              </div>
              <div class="container_notification" fxLayoutAlign="center">
                <label class="text_show_all" (click)="goToNotification()"
                  >Vedi tutte</label
                >
              </div>
            </div>
          </mat-menu>
          <img
            class="button_menu_mobile"
            src="assets/image/home/menu.svg"
            (click)="drawerMobile.open()"
            alt=""
          />
        </div>
      </div>
    </div>
    <router-outlet *ngIf="searchValue === undefined"></router-outlet>
    <app-buy-superadmin-search
      [value]="this.searchValue"
      [type]="type"
      *ngIf="searchValue !== undefined && role === 'admin'"
    ></app-buy-superadmin-search>
    <app-buy-user-search
      [value]="this.searchValue"
      [type]="type"
      *ngIf="searchValue !== undefined && role === 'user'"
    ></app-buy-user-search>
    <app-build-supplier-search
      [value]="this.searchValue"
      [type]="type"
      *ngIf="searchValue !== undefined && role === 'buildRenovateSupplier'"
    ></app-build-supplier-search>
    <app-build-technician-search
      [value]="this.searchValue"
      [type]="type"
      *ngIf="searchValue !== undefined && role === 'technicianHauses'"
    ></app-build-technician-search>
    <app-build-company-search
      [value]="this.searchValue"
      [type]="type"
      *ngIf="searchValue !== undefined && role === 'buildRenovateCompany'"
    ></app-build-company-search>
    <app-build-condominium-search
      [value]="this.searchValue"
      [type]="type"
      *ngIf="searchValue !== undefined && role === 'buildRenovateCondominium'"
    ></app-build-condominium-search>
    <app-build-consultant-search
      [value]="this.searchValue"
      [type]="type"
      *ngIf="searchValue !== undefined && role === 'buildRenovateConsultant'"
    ></app-build-consultant-search>
    <app-build-contractor-search
      [value]="this.searchValue"
      [type]="type"
      *ngIf="
        searchValue !== undefined && role === 'buildRenovateGeneralContractor'
      "
    ></app-build-contractor-search>
  </mat-drawer-content>
</mat-drawer-container>
