import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotifierComponent } from '../../children/main/children/home/component/notifier/notifier.component';

@Injectable({
  providedIn: 'root',
})
export class NotifierService {
  constructor(private _snackbar: MatSnackBar) {}

  showNotification(message: string) {
    this._snackbar.openFromComponent(NotifierComponent, {
      data: {
        message: message,
      },
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      panelClass: 'error',
    });
  }

  showNotificationSuccess(message: string) {
    this._snackbar.openFromComponent(NotifierComponent, {
      data: {
        message: message,
      },
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      panelClass: 'success',
    });
  }

  showNotificationFirstRegistration(message: string) {
    this._snackbar.openFromComponent(NotifierComponent, {
      data: {
        message: message,
      },
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: 'success',
    });
  }
}
