import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import {HomeService} from "../../../core/service/home.service";
import {ContractService} from "@scalingparrots/dapp-angular-lib";
import {AdminService} from "../../../core/service/admin.service";
import {BuildRenovateService} from "../../../core/service/buildRenovate.service";
import {Router} from "@angular/router";
import {
  DialogViewMessageComponent
} from "../../../children/main/children/home/component/dialog/dialog-view-message/dialog-view-message.component";
import {
  DialogViewOpenCMEComponent
} from "../../../children/main/children/home/component/dialog/dialog-view-open-cme/dialog-view-open-c-m-e.component";
@Component({
  selector: 'app-dialog-reserved-content',
  templateUrl: './dialog-dashboard-technician.component.html',
  styleUrls: ['./dialog-dashboard-technician.component.scss'],
})
export class DialogDashboardTechnicianComponent implements OnInit {
  innerWidth:any;
  profile:any;
  requests:any;
  practices:any;
  enabledPractices:any;
  finishPractices:any;
  cmeConfirmed:any;
  updates:any;
  confirmedWork:any[]=[];
  waitingWork:any[]=[];
  allContractLink:any[]=[];
  loadLatestPractices:boolean = false;
  constructor(
    public dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private _homeService: HomeService,
    private _adminService: AdminService,
    private _buildRenovateService: BuildRenovateService,
    private _contractService: ContractService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) private data: DialogDashboardTechnicianComponent
  ) {}

  ngOnInit(): void {
    this.innerWidth = window.innerWidth
    this.profile = this._homeService.profile
    this.loadLatestPractices = true;
    this._buildRenovateService.getBuildRenovatesTechnicianHauses(localStorage.getItem('token'), '0', '999').subscribe(
      (res) => {
        this.enabledPractices = res.total;
        this.finishPractices = res.data.filter((data)=>data.status === 'finished');
      },
      () => {}
    );
    this._buildRenovateService.getBuildRenovatesTechnicianHauses(localStorage.getItem('token'), '0', '9').subscribe(
      (res) => {
        this.getLatestPractices(res.data);
      },
      () => {}
    );
  }

  async getLatestPractices(practices) {
    if(practices && practices.length >0){
      const latestPractices = practices.sort(function (a: { dateCreation: number }, b: { dateCreation: number }) {
        return b.dateCreation - a.dateCreation;
      });
      this.getBuildRenovate(latestPractices[0].id)
    } else {
      this.practices = null
      this.loadLatestPractices = false;
    }
  }
  getBuildRenovate(id: any) {
    this._buildRenovateService.getBuildRenovate(id).subscribe(
      async (res) => {
        this.practices = res;
        this.updates = res.updates.reverse();
        for (let i = 0; i < res.channels.length; i++) {
          if (res.channels[i].status === 'verifiedSignatures') {
            this.confirmedWork.push(res.channels[i]);
          }
        }
        for (let i = 0; i < res.channels.length; i++) {
          if (res.channels[i].status !== 'verifiedSignatures') {
            this.waitingWork.push(res.channels[i]);
          }
        }
        for (let i = 0; i < res.channels.length; i++) {
          if (res.channels[i].status === 'verifiedSignatures') {
            this.allContractLink.push(res.channels[i].channelContract.contract);
          }
        }
        this.loadLatestPractices = false;
      },
      () => {}
    );
  }
  goToPage(url){
    this.router.navigate([url])
    this.dialog.closeAll()
  }
  openViewMessage(message: string) {
    this.dialog.open(DialogViewMessageComponent, {
      width: '90%',
      maxWidth: '500px',
      data: message,
    });
  }
  openPopupCME() {
    this.dialog.open(DialogViewOpenCMEComponent, {
      width: '90%',
      maxWidth: '500px',
      data: {
        edit:
          '/dashboard/build/technician/practices/build/' +
          this.practices.id +
          '/edit/all',
        view:
          '/dashboard/build/technician/practices/build/' +
          this.practices.id +
          '/edit/all',
        isDisabled:
          this.practices.status === 'approvedAll' ||
          this.practices.status === 'finished',
      },
    });
  }
  downloadAllContract() {
    for (let i = 0; i < this.allContractLink.length; i++) {
      setTimeout(
        function (path) {
          window.location = path;
        },
        200 + i * 200,
        this.allContractLink[i]
      );
    }
  }

  openUrl(url: string) {
    window.open(url);
  }
}
