import {
  Component,
  DoCheck,
  Input,
  OnInit,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AdminService } from '../../../core/service/admin.service';
import { NotifierService } from '../../../core/service/notifier.service';
import { BuildRenovateService } from '../../../core/service/buildRenovate.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-buy-superadmin-search',
  templateUrl: './buy-super-admin-search.component.html',
  styleUrls: ['./buy-super-admin-search.component.scss'],
})
export class BuySuperAdminSearchComponent implements OnInit, DoCheck {
  @Input() value: any;
  @Input() type: any;
  displayedColumns: string[] = ['result', 'type'];
  displayedColumns1: string[] = ['result', 'type', 'id', 'details'];
  complex: any[] = [];
  apartment: any[] = [];
  depositPaid: any[] = [];
  booking: any[] = [];
  user: any[] = [];
  filterValue: any[] = [];
  investment: any[] = [];
  buildRenovate: any[] = [];
  objBuildRenovate: any[] = [];
  dataFiltered: any[] = [];
  constructor(
    private _formBuilder: FormBuilder,
    private buildRenovateService: BuildRenovateService,
    private adminService: AdminService,
    private _notifierService: NotifierService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.filterValue = this.value;
    this.getBuildings();
  }

  setLocalStorageData(
    routing: string,
    firstName: string,
    lastName: string,
    id: string
  ) {
    this.router.navigate([routing]);
    localStorage.setItem('id', id);
  }

  getBuildings() {
    if (this.type === 'buy') {
      this.adminService.getBuildingSearch(this.value).subscribe(
        (buildings) => {
          this.complex = buildings;
          this.dataFiltered = buildings;
        },
        () => {}
      );
      this.adminService.getApartmentSearch(this.value).subscribe(
        (apartments) => {
          this.apartment = apartments;
          this.dataFiltered = this.dataFiltered.concat(apartments);
        },
        () => {}
      );
      this.adminService.getBookingSearch(this.value).subscribe(
        (booking) => {
          this.booking = booking;
          this.dataFiltered = this.dataFiltered.concat(this.booking);
        },
        () => {}
      );
      this.adminService.getUserSearch(this.value, 'buyer').subscribe(
        (user) => {
          this.user = user;
          this.dataFiltered = this.dataFiltered.concat(user);
        },
        () => {}
      );
    } else if (this.type === 'invest') {
      this.adminService.getInvestmentSearch(this.value).subscribe(
        (investment) => {
          this.investment = investment;
          this.dataFiltered = investment;
        },
        () => {}
      );
      this.adminService
        .getUserSearch(this.value, 'investorNormal;investorBlack;investorBlue')
        .subscribe(
          (user) => {
            this.user = user;
            this.dataFiltered = this.dataFiltered.concat(user);
          },
          () => {}
        );
    } else if (this.type === 'build') {
      this.buildRenovateService.buildRenovateSearch(this.value).subscribe(
        (res) => {
          this.buildRenovate = res;
          this.dataFiltered = res;
        },
        () => {}
      );
      this.buildRenovateService.objBuildRenovateSearch(this.value).subscribe(
        (res) => {
          this.objBuildRenovate = res;
          this.dataFiltered = this.dataFiltered.concat(res);
        },
        () => {}
      );
    }
  }

  ngDoCheck(): void {
    if (
      this.value !== undefined &&
      this.value !== this.filterValue &&
      this.type === 'buy'
    ) {
      this.filterValue = this.value;
      this.adminService.getBuildingSearch(this.value).subscribe(
        (buildings) => {
          this.complex = buildings;
          this.dataFiltered = buildings;
        },
        () => {}
      );
      this.adminService
        .getApartmentSearch(this.value.toLocaleLowerCase())
        .subscribe(
          (apartments) => {
            this.apartment = apartments;
            this.dataFiltered = this.dataFiltered.concat(apartments);
          },
          () => {}
        );
      this.adminService.getBookingSearch(this.value).subscribe(
        (booking) => {
          this.booking = booking;
          this.dataFiltered = this.dataFiltered.concat(this.booking);
        },
        () => {}
      );
      this.adminService.getUserSearch(this.value, 'buyer').subscribe(
        (user) => {
          this.user = user;
          this.dataFiltered = this.dataFiltered.concat(user);
        },
        () => {}
      );
    } else if (
      this.value !== undefined &&
      this.value !== this.filterValue &&
      this.type === 'invest'
    ) {
      this.filterValue = this.value;
      this.adminService.getInvestmentSearch(this.value).subscribe(
        (investment) => {
          this.investment = investment;
          this.dataFiltered = investment;
        },
        () => {}
      );
      this.adminService
        .getUserSearch(this.value, 'investorNormal;investorBlack;investorBlue')
        .subscribe(
          (user) => {
            this.user = user;
            this.dataFiltered = this.dataFiltered.concat(user);
          },
          () => {}
        );
    } else if (
      this.value !== undefined &&
      this.value !== this.filterValue &&
      this.type === 'build'
    ) {
      this.filterValue = this.value;
      this.buildRenovateService.buildRenovateSearch(this.value).subscribe(
        (res) => {
          this.buildRenovate = res;
          this.dataFiltered = res;
        },
        () => {}
      );
      this.buildRenovateService.objBuildRenovateSearch(this.value).subscribe(
        (res) => {
          this.objBuildRenovate = res;
          this.dataFiltered = this.dataFiltered.concat(res);
        },
        () => {}
      );
    }
  }

  openPage(url: any) {
    this.router.navigate([url]);
  }

  getRoleLabel(role: string) {
    let label: string;
    if (role === 'admin') {
      label = 'Amministratore';
    } else if (role === 'technicianHauses') {
      label = 'Tecnico';
    } else if (role === 'buildRenovateConsultant') {
      label = 'Consulente';
    } else if (role === 'buildRenovateGeneralContractor') {
      label = 'General contractor';
    } else if (role === 'buildRenovateSupplier') {
      label = 'Fornitore';
    } else if (role === 'buildRenovateCompany') {
      label = 'Azienda';
    } else if (role === 'buildRenovateCondominium') {
      label = 'Amministratore condominio';
    } else {
      label = 'Utente';
    }
    return label;
  }
}
