// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'https://engine-staging.hauses.it',
  primary_network: 'MUMBAI',
  TOKEN_HT: '0x097225D845e03237BA43d8f97dAeD1Bf4Fc7E778',
  SWAP_HT: '0xBf9A332869D2cd4dFd0127a4eE7C053Cee9AA72f',
  INVESTMENT_HT: '0x0D1eFa4CcdF35fEf9248d66e87869b2E28Dcfd38',
  BUY_HT: '0x06940026517BAe3c0321E390B8f83876aa4d59ee',
  BUILD_HT: '0xa656eE710f2CA5d7A0784183ac0aDCD10298262B',
  VOTING_HT: '0xb08f7d02c57eE9b7171670154036d0Efa16A79F4',
  MARKETPLACE_HT: '0x0A2B0372313F0CF222cdDA2851e7Bc7ADa551E0D',
  UNISWAPV2: '0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff',
  UNISWAPV3: '0xE592427A0AEce92De3Edee1F18E0157C05861564',
  RPC: 'https://rpc.buildbear.io/wee-rugor-nass-507ad955',
  RPC_MAINNET: 'https://polygon-mainnet.infura.io/v3/33304103dfd14b8dbea7d3b6de1b1355',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
