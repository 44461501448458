import { Component, Input, OnInit } from '@angular/core';
import { ContractService } from '@scalingparrots/dapp-angular-lib';
import { environment } from '../../../../../../../../../../../../environments/environment';
const investmentABI = require('src/app/core/abi/investmentABI.json');

@Component({
  selector: 'app-start-date-w3',
  templateUrl: './start-date-w3.component.html',
  styleUrls: ['./start-date-w3.component.scss'],
})
export class StartDateW3Component implements OnInit {
  @Input() investment: any;
  startDate: any;
  w3Index: any;
  constructor(private _contractService: ContractService) {}

  ngOnInit(): void {
    this.getInvestment();
  }

  async getInvestment() {
    this.w3Index = this.investment.w3Index;
    if (
      this.investment.wType.includes('W3') &&
      this.investment.wType.includes('W2')
    ) {
      const w3Data = await this._contractService.readContract(
        environment.INVESTMENT_HT,
        environment.RPC,
        investmentABI,
        'projectMap',
        [this.w3Index]
      );
      this.startDate = +w3Data.timestampStart;
    } else if (this.investment.wType.includes('W3')) {
      const w3Data = await this._contractService.readContract(
        environment.INVESTMENT_HT,
        environment.RPC,
        investmentABI,
        'projectMap',
        [this.w3Index]
      );
      this.startDate = +w3Data.timestampStart;
    } else {
      this.startDate = this.investment.w2StartDate;
    }
  }
}
