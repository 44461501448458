import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { CardConnectWalletComponent } from '../../../../components/card-connect-wallet/card-connect-wallet.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SwapCryptoWalletComponent } from '../../../../components/swap-crypto-wallet/swap-crypto-wallet.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { WalletNotConnectedComponent } from '../../../../components/wallet-not-connected/wallet-not-connected.component';
import {MatSliderModule} from "@angular/material/slider";
import {DialogDashboardComponent} from "../../../../components/dashboard/dialog-dashboard-user/dialog-dashboard.component";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {DialogDashboardACComponent} from "../../../../components/dashboard/dialog-dashboard-ac/dialog-dashboard-a-c.component";
import {MatFormFieldModule} from "@angular/material/form-field";
import {
  DialogDashboardCompanyComponent
} from "../../../../components/dashboard/dialog-dashboard-company/dialog-dashboard-company.component";
import {
  DialogDashboardContractorComponent
} from "../../../../components/dashboard/dialog-dashboard-contractor/dialog-dashboard-contractor.component";
import {
  DialogDashboardAdminComponent
} from "../../../../components/dashboard/dialog-dashboard-admin/dialog-dashboard-admin.component";
import {
  DialogDashboardConsultantComponent
} from "../../../../components/dashboard/dialog-dashboard-consultant/dialog-dashboard-consultant.component";
import {
  DialogDashboardTechnicianComponent
} from "../../../../components/dashboard/dialog-dashboard-technician/dialog-dashboard-technician.component";

@NgModule({
  declarations: [
    CardConnectWalletComponent,
    SwapCryptoWalletComponent,
    WalletNotConnectedComponent,
    DialogDashboardComponent,
    DialogDashboardACComponent,
    DialogDashboardCompanyComponent,
    DialogDashboardContractorComponent,
    DialogDashboardAdminComponent,
    DialogDashboardConsultantComponent,
    DialogDashboardTechnicianComponent
  ],
  exports: [
    CardConnectWalletComponent,
    SwapCryptoWalletComponent,
    WalletNotConnectedComponent,
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    FlexModule,
    MatIconModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatGridListModule,
    MatTooltipModule,
    MatMenuModule,
    MatSliderModule,
    MatProgressBarModule,
    MatFormFieldModule,
  ],
})
export class DashboardModule {}
