import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NotifierService } from '../../../core/service/notifier.service';
import { HomeService } from '../../../core/service/home.service';
import { BuildRenovateService } from '../../../core/service/buildRenovate.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-buy-user-search',
  templateUrl: './buy-user-search.component.html',
  styleUrls: ['./buy-user-search.component.scss'],
})
export class BuyUserSearchComponent implements OnInit, DoCheck {
  @Input() value: any;
  @Input() type: any;
  displayedColumns: string[] = ['result', 'type'];
  displayedColumns1: string[] = ['result', 'type', 'id', 'details'];
  filterValue: string = '';
  depositPaid: any[] = [];
  booking: any[] = [];
  Investment: any[] = [];
  buildRenovate: any[] = [];
  objBuildRenovate: any[] = [];
  dataFiltered: any[] = [];
  constructor(
    private _formBuilder: FormBuilder,
    private homeService: HomeService,
    private buildRenovateService: BuildRenovateService,
    private _notifierService: NotifierService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.filterValue = this.value;
    this.getBuildings();
  }

  getBuildings() {
    if (this.type === 'buy') {
      this.depositPaid = this.homeService.profile.historyBuy;
      this.booking = this.homeService.profile.historyBooking;
      this.dataFiltered = this.homeService.profile.historyBuy.filter((v: any) =>
        v.apartment.name
          .toLocaleLowerCase()
          .includes(this.value.toLocaleLowerCase())
      );
      this.dataFiltered = this.dataFiltered.concat(
        this.homeService.profile.historyBooking.filter((v: any) =>
          v.message.toLocaleLowerCase().includes(this.value.toLocaleLowerCase())
        )
      );
    } else if (this.type === 'invest') {
      this.Investment = this.homeService.profile.historyInvestment;
      this.dataFiltered = this.homeService.profile.historyInvestment.filter(
        (v: any) =>
          v.investment.name
            .toLocaleLowerCase()
            .includes(this.value.toLocaleLowerCase())
      );
    } else if (this.type === 'build') {
      this.buildRenovateService.buildRenovateSearch(this.value).subscribe(
        (res) => {
          this.buildRenovate = res;
          this.dataFiltered = res;
        },
        () => {}
      );
      this.buildRenovateService.objBuildRenovateSearch(this.value).subscribe(
        (res) => {
          this.objBuildRenovate = res;
          this.dataFiltered = this.dataFiltered.concat(res);
        },
        () => {}
      );
    }
  }

  ngDoCheck(): void {
    if (
      this.value !== undefined &&
      this.value !== this.filterValue &&
      this.type === 'buy'
    ) {
      this.filterValue = this.value;
      this.dataFiltered = this.depositPaid.filter((v: any) =>
        v.apartment.name
          .toLocaleLowerCase()
          .includes(this.value.toLocaleLowerCase())
      );
      this.dataFiltered = this.dataFiltered.concat(
        this.booking.filter((v: any) =>
          v.message.toLocaleLowerCase().includes(this.value.toLocaleLowerCase())
        )
      );
    } else if (
      this.value !== undefined &&
      this.value !== this.filterValue &&
      this.type === 'invest'
    ) {
      this.filterValue = this.value;
      this.dataFiltered = this.Investment.filter((v: any) =>
        v.investment.name
          .toLocaleLowerCase()
          .includes(this.value.toLocaleLowerCase())
      );
    } else if (
      this.value !== undefined &&
      this.value !== this.filterValue &&
      this.type === 'build'
    ) {
      this.filterValue = this.value;
      this.buildRenovateService.buildRenovateSearch(this.value).subscribe(
        (res) => {
          this.buildRenovate = res;
          this.dataFiltered = res;
        },
        () => {}
      );
      this.buildRenovateService.objBuildRenovateSearch(this.value).subscribe(
        (res) => {
          this.objBuildRenovate = res;
          this.dataFiltered = this.dataFiltered.concat(res);
        },
        () => {}
      );
    }
  }

  openPage(url: any) {
    this.router.navigate([url]);
  }

  getRoleLabel(role: string) {
    let label: string;
    if (role === 'admin') {
      label = 'Amministratore';
    } else if (role === 'technicianHauses') {
      label = 'Tecnico';
    } else if (role === 'buildRenovateConsultant') {
      label = 'Consulente';
    } else if (role === 'buildRenovateGeneralContractor') {
      label = 'General contractor';
    } else if (role === 'buildRenovateSupplier') {
      label = 'Fornitore';
    } else if (role === 'buildRenovateCompany') {
      label = 'Azienda';
    } else if (role === 'buildRenovateCondominium') {
      label = 'Amministratore condominio';
    } else {
      label = 'Utente';
    }
    return label;
  }
}
