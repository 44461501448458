<div class="container_dialog" fxLayout="column" (close)="dialog.closeAll()">
  <label class="title">Hai perso il tuo wallet?</label>
  <label class="subtitle">Se hai perso il tuo wallet verrà inviata una richiesta ad Hauses che, entro
    qualche giorno, disattiverà il tuo wallet e potrai collegarne uno
    nuovo</label>
  <div
    class="container_button"
    fxLayout="row"
    fxLayoutAlign="center"
    fxLayoutGap="20px"
  >
    <button class="button_cancel" (click)="dialog.closeAll()">Annulla</button>
    <button
      *ngIf="!loading && !this.newAccountData"
      class="button_register"
      (click)="connectWallet()"
    >
      Cambia wallet
    </button>
    <button
      *ngIf="!loading && this.newAccountData"
      [disabled]="!newAccountData"
      [class]="newAccountData ? 'button_register' : 'button_register_disable'"
      (click)="confirmChange()"
    >
      Invia richiesta
    </button>
    <button *ngIf="loading" class="button_register">
      <mat-spinner diameter="20"></mat-spinner>
    </button>
  </div>
</div>
