import { Component, DoCheck, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HomeService } from './core/service/home.service';
import * as moment from 'moment/moment';
import { AdminService } from './core/service/admin.service';
import { PriceService } from './core/service/price.service';
import {ChainId, GlobalVariables, NETWORK_INFO, NetworkService, WalletService} from '@scalingparrots/dapp-angular-lib';
import { NotifierService } from './core/service/notifier.service';
import { PRIMARY_NETWORK } from './components/card-connect-wallet/card-connect-wallet.component';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements DoCheck {
  innerWidth: any;
  routerLink: any;
  dateNow: any;
  searchForm: FormGroup = new FormGroup({});
  selectForm: FormGroup = new FormGroup({});
  type: string | undefined;
  index: any;
  path: any;
  routing: any;
  option: any;
  role: any;
  allNotify: any;
  notification: boolean = false;
  scrolling: boolean = false;
  timeout: number = 0;
  searchValue: Event | undefined;
  walletConnected: any;
  env: any;
  w3Address: string = '';
  animation: boolean = false;
  primary_network =
    environment.primary_network === 'POLYGON'
      ? NETWORK_INFO[ChainId.Polygon]
      : PRIMARY_NETWORK;

  constructor(
    private _formBuilder: FormBuilder,
    private router: Router,
    public homeService: HomeService,
    private adminService: AdminService,
    private _priceService: PriceService,
    private notifierService: NotifierService,
    private _walletService: WalletService,
    private _networkService: NetworkService,
   // private meta: Meta,
  ) {
    // init network necessary
    _walletService.initNetwork(this.primary_network);
    this.getProvider();
  }
  @HostListener('window:scroll', ['$event'])
  onScroll(event: any) {
    if (this.timeout < event.target['scrollingElement'].scrollTop) {
      this.timeout = event.target['scrollingElement'].scrollTop;
      this.scrolling = true;
    } else {
      this.timeout = event.target['scrollingElement'].scrollTop;
      this.scrolling = false;
    }
  }
  async ngOnInit(): Promise<void> {
    this.env = environment;
    this._networkService.checkNetwork(this.primary_network);
    setInterval(() => {
      this.getProvider();
    }, 3000);
    if (localStorage.getItem('token')) {
      this.homeService.getProfile(localStorage.getItem('token')).subscribe(
        (res) => {
          this.homeService.profile = res;
          this.homeService.Notification(res);
          this.updateNotification(res);
          if (res.w3Addresses && res.w3Addresses.length > 0) {
            const w3Addresses = res.w3Addresses.filter(
              (address: any) => address.isMain === true
            );
            this.w3Address = w3Addresses[0].address;
          } else {
            this.w3Address = '';
          }
          this.getWallet();
        },
        () => {
          localStorage.removeItem('token');
          this.router.navigate(['/home']);
        }
      );
    }
    this.notification = false;
    this.searchForm = this._formBuilder.group({
      search: [null],
    });
    this._priceService.getEuroPrice();
    moment.locale('it');
    this.selectForm = this._formBuilder.group({
      select: [''],
    });
    setInterval(() => {
      this.dateNow = Date.now();
      const url = this.router.url.split('/');
      const path = this.router.url;
      if (localStorage.getItem('token')) {
        if (this.path !== path) {
          this.path = path;
          if (this.homeService.profile) {
            this.homeService.Notification(this.homeService.profile);
            this.updateNotification(this.homeService.profile);
          }
        }
        const role = this.homeService.getRole(localStorage.getItem('token'));
        if (role && role.length > 0) {
          this.role = role[0].type;
          this.option = role[0].routing;
        }
      }
      if (this.path !== path && url[1] !== 'dashboard') {
        this.animation = true;
        this.path = path;
        setTimeout(() => {
          this.animation = false;
        }, 1300);
      }
    }, 1000);
  }
  updateNotification(res: any) {
    if (res.notifications) {
      const notify = res.notifications.sort(function (
        a: { date: number },
        b: { date: number }
      ) {
        return b.date - a.date;
      });
      this.allNotify = notify.slice(0, 5);
    }
  }
  async getWallet() {
    this.walletConnected = await this.getGlobalVariables().wallet.address;
    setInterval(() => {
      if (this.getGlobalVariables().wallet.address !== undefined) {
        if (this.walletConnected !== this.getGlobalVariables().wallet.address) {
          this.walletConnected = this.getGlobalVariables().wallet.address;
          if (
            this.w3Address &&
            this.w3Address.toLowerCase() !==
              this.getGlobalVariables().wallet.address
          ) {
            this.notifierService.showNotification(
              'Accedi con il wallet corretto (' +
                this.w3Address +
                ') per poter usufruire del servizio WEB3'
            );
          }
        }
      }
    }, 2000);
  }

  async getProvider(): Promise<void> {
    await this._walletService.getWebProvider();
  }

  getGlobalVariables(): GlobalVariables {
    return this._walletService.getGlobalVariables();
  }

  setSelect() {
    this.type = this.selectForm.get('select').value;
    this.routing = this.homeService.getRouting(localStorage.getItem('token'));
    if (this.type === 'build') {
      this.router.navigate([
        '/dashboard/' + this.type + '/' + this.routing[0].type + '/',
      ]);
    } else {
      this.router.navigate(['/dashboard/' + this.type]);
    }
  }

  goToNotification() {
    const path = this.router.url.split('/');
    if (path[2] !== 'build') {
      this.router.navigate(['/dashboard/' + path[2] + '/notification']);
    } else {
      this.router.navigate([
        '/dashboard/' + path[2] + '/' + path[3] + '/notification',
      ]);
    }
  }

  filterAllComplex(e: Event) {
    if (e) {
      this.searchValue = e;
    } else {
      this.searchValue = undefined;
    }
  }

  setValue(): void {
    this.searchValue = undefined;
  }

  ngDoCheck(): void {
    const path = this.router.url.split('/');
    this.routerLink = path[1];
    this.type = path[2];
    this.selectForm.get('select').setValue(path[2]);
    this.notification = !!localStorage.getItem('notification');
    this.innerWidth = window.innerWidth;
    this.index = localStorage.getItem('notification');
  }

  goToPage(path: string) {
    const role = this.homeService.getRole(localStorage.getItem('token'));
    if (role[0]) {
      this.getRoleLabel(role[0].type, path);
    }
  }

  getRoleLabel(role: string, path: string) {
    if (role === 'buildRenovateGeneralContractor') {
      const user = path.split('user');
      const company = path.split('company');
      if (user[0] && user[1]) {
        this.router.navigate([user[0] + 'contractor' + user[1]]);
      } else {
        if (company[0] && company[1]) {
          this.router.navigate([company[0] + 'contractor' + company[1]]);
        }
      }
    } else if (role === 'buildRenovateSupplier') {
      if (path.includes('user')) {
        const p = path.split('user');
        this.router.navigate([p[0] + 'supplier' + p[1]]);
      } else if (path.includes('company')) {
        const p = path.split('company');
        this.router.navigate([p[0] + 'supplier' + p[1]]);
      }
    } else if (role === 'buildRenovateCondominium') {
      const p = path.split('user');
      this.router.navigate([p[0] + 'condominium' + p[1]]);
    } else if (role === 'admin') {
      if (path.includes('my-wallet')) {
        const p = path.split('my-wallet');
        this.router.navigate([p[0] + 'admin-wallet']);
      } else {
        this.router.navigate([path]);
      }
    } else {
      this.router.navigate([path]);
    }
  }

  readAllNotifications() {
    this.homeService.getProfile(localStorage.getItem('token')).subscribe(
      (res) => {
        const objIndex = res.notifications;
        for (let i = 0; i < objIndex.length; i++) {
          objIndex[i].isRead = true;
        }
        const data = {
          notifications: objIndex,
        };
        this.adminService.updateNotification(data).subscribe(
          (res) => {
            this.homeService.profile = res;
            const notify = res.notifications.sort(function (
              a: { date: number },
              b: { date: number }
            ) {
              return b.date - a.date;
            });
            this.allNotify = notify.slice(0, 5);
            this.homeService.Notification(res);
          },
          () => {}
        );
      },
      () => {
        localStorage.removeItem('token');
        this.router.navigate(['/home']);
      }
    );
  }
}
