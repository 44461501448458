import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { NotifierService } from '../../../../../../../core/service/notifier.service';
import { BuildRenovateService } from '../../../../../../../core/service/buildRenovate.service';
import { Web3Service } from '../../../../../../../core/service/web3.service';
import {
  NetworkService,
  WalletService,
} from '@scalingparrots/dapp-angular-lib';
@Component({
  selector: 'app-dialog-reserved-content',
  templateUrl: './dialog-recover-wallet.component.html',
  styleUrls: ['./dialog-recover-wallet.component.scss'],
})
export class DialogRecoverWalletComponent implements OnInit {
  info: any;
  loading: boolean = false;
  win: any;
  newAccountData: any;

  constructor(
    public dialogRef: MatDialogRef<DialogRecoverWalletComponent>,
    public dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private buildRenovateService: BuildRenovateService,
    private web3Service: Web3Service,
    private _notifierService: NotifierService,
    private _walletService: WalletService,
    private _networkService: NetworkService,
    @Inject(MAT_DIALOG_DATA) private data: DialogRecoverWalletComponent
  ) {
    this.win = window as any;
  }

  ngOnInit(): void {}

  confirmChange() {
    this.loading = true;
    this.web3Service.w3RequestResetAccount(this.newAccountData).subscribe(
      () => {
        this.loading = false;
        this._notifierService.showNotificationSuccess(
          'Richiesta reset wallet inviata'
        );
        this.dialog.closeAll();
      },
      (err) => {
        if (err.error.message === 'Address already connected to another user') {
          this._notifierService.showNotification(
            'Indirizzo già connesso ad un altro utente'
          );
        }
        this.loading = false;
      }
    );
  }

  connectWallet() {
    this._walletService.connectWallet('metamask').then(async (res) => {
      if (res === undefined) {
        window.open('https://metamask.io/download/');
      } else {
        const eth = this.win['ethereum'];
        const dateNow = Date.now();
        const startDate = new Date(dateNow);
        const startDateSeconds = Math.floor(startDate.getTime() / 1000);
        const sign = await eth.request({
          method: 'personal_sign',
          params: [
            'Abilitazione ad Hauses Web3: ' + startDateSeconds,
            eth.selectedAddress,
          ],
        });
        this.newAccountData = {
          newAddress: eth.selectedAddress,
          message: 'Abilitazione ad Hauses Web3: ' + startDateSeconds,
          signedMessage: sign,
        };
      }
    });
  }
}
