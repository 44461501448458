import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { HomeService } from '../../core/service/home.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit, DoCheck {
  @Input() drawer: any;
  show: boolean = false;
  path: string | undefined;
  token: any;

  constructor(
    private _location: Location,
    private router: Router,
    private _homeService: HomeService
  ) {}

  ngOnInit(): void {}

  ngDoCheck(): void {
    this.token = localStorage.getItem('token');
    const path = this.router.url.split('/');
    this.path = path[1];
  }

  goToDashboard() {
    this.drawer.close();
    const typeRouting = this._homeService.getRoutingOnLogin(
      localStorage.getItem('token')
    );
    const routing = this._homeService.getRouting(localStorage.getItem('token'));
    if (typeRouting === 'build') {
      this.router.navigate([
        '/dashboard/' + typeRouting + '/' + routing[0].type + '/home',
      ]);
    } else {
      this.router.navigate(['/dashboard/' + typeRouting + '/home']);
    }
  }

  isActive(loc: string): boolean {
    return this._location.path().includes(loc);
  }

  Logout() {
    localStorage.removeItem('token');
    this._homeService.profile = null;
    this.drawer.close();
    this.router.navigate(['/home']);
  }

  openBlog() {
    this.drawer.close();
    window.open('https://blog.hauses.it/blog/');
  }

  goToLogin() {
    this.router.navigate(['/login']);
    this.drawer.close();
  }

  goToPage(url: string) {
    this.router.navigate([url]);
    this.drawer.close();
  }
}
