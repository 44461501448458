import { Component, OnInit } from '@angular/core';
import {
  ChainId,
  GlobalVariables,
  NETWORK_INFO,
  NetworkService,
  WalletService,
} from '@scalingparrots/dapp-angular-lib';
import { DialogRecoverWalletComponent } from '../../children/main/children/home/component/dialog/dialog-recover-wallet/dialog-recover-wallet.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogInfoWalletComponent } from '../../children/main/children/home/component/dialog/dialog-info-wallet/dialog-info-wallet.component';
import { Web3Service } from '../../core/service/web3.service';
import { HomeService } from '../../core/service/home.service';
import { NotifierService } from '../../core/service/notifier.service';
import { environment } from '../../../environments/environment';

export const PRIMARY_NETWORK = {
  chainId: '0x25D4',
  chainName: 'Polygon',
  nativeCurrency: {
    name: 'Polygon',
    symbol: 'MATIC',
    decimals: 18,
  },
  rpcUrls: ['https://rpc.buildbear.io/wee-rugor-nass-507ad955'],
  blockExplorerUrls: ['https://rpc.buildbear.io/wee-rugor-nass-507ad955'],
};
@Component({
  selector: 'app-card-connect-wallet',
  templateUrl: './card-connect-wallet.component.html',
  styleUrls: ['./card-connect-wallet.component.scss'],
})
export class CardConnectWalletComponent implements OnInit {
  win: any;
  primary_network =
    environment.primary_network === 'POLYGON'
      ? NETWORK_INFO[ChainId.Polygon]
      : PRIMARY_NETWORK;
  routerLink = 'home';
  w3Address: string = '';
  newAddress: string = '';
  w3Addresses: any[] = [];
  balance: any;
  loadConnectFirstTime: boolean = false;

  constructor(
    private _walletService: WalletService,
    private _networkService: NetworkService,
    private web3Service: Web3Service,
    private homeService: HomeService,
    private notifierService: NotifierService,
    public dialog: MatDialog
  ) {
    this.win = window as any;
  }

  ngOnInit(): void {
    this.w3Addresses = this.homeService.profile.w3Addresses;
    if (
      this.homeService.profile.w3Addresses &&
      this.homeService.profile.w3Addresses.length > 0
    ) {
      const w3Addresses = this.homeService.profile.w3Addresses.filter(
        (address: any) => address.isMain === true
      );
      this.w3Address = w3Addresses[0].address;
      if (
        this.homeService.profile.w3Addresses[
          this.homeService.profile.w3Addresses.length - 1
        ].isMain === false
      ) {
        this.newAddress =
          this.homeService.profile.w3Addresses[
            this.homeService.profile.w3Addresses.length - 1
          ].address;
      }
    } else {
      this.w3Address = '';
    }
  }

  getGlobalVariables(): GlobalVariables {
    return this._walletService.getGlobalVariables();
  }

  async disconnectWallet(): Promise<void> {
    await this._walletService.disconnectWallet();
  }

  connectWallet() {
    this._walletService.connectWallet('metamask').then(async (res) => {
      if (res === undefined) {
        window.open('https://metamask.io/download/');
      } else {
        if (this.w3Address === '' || this.w3Address === undefined) {
          const eth = this.win['ethereum'];
          const dateNow = Date.now();
          const startDate = new Date(dateNow);
          const startDateSeconds = Math.floor(startDate.getTime() / 1000);
          const sign = await eth.request({
            method: 'personal_sign',
            params: [
              'Abilitazione ad Hauses Web3: ' + startDateSeconds,
              eth.selectedAddress,
            ],
          });
          const data = {
            address: eth.selectedAddress,
            message: 'Abilitazione ad Hauses Web3: ' + startDateSeconds,
            signedMessage: sign,
          };
          this.loadConnectFirstTime = true;
          this.web3Service.w3ConnectAccount(data).subscribe(
            (res) => {
              this.homeService.profile = res;
              this.loadConnectFirstTime = false;
              this.w3Addresses = res.w3Addresses;
              if (res.w3Addresses && res.w3Addresses.length > 0) {
                const w3Addresses = res.w3Addresses.filter(
                  (address: any) => address.isMain === true
                );
                this.w3Address = w3Addresses[0].address;
              } else {
                this.w3Address = '';
              }
            },
            (error) => {
              this.loadConnectFirstTime = false;
              if (error.error.error.indexOf('already whitelisted') > -1) {
                this.notifierService.showNotification(
                  'Address già whitelistato'
                );
              } else if (
                error.error.error ===
                'Address has already connected to another user'
              ) {
                this.notifierService.showNotification(
                  'Address già utilizzato da un altro utente'
                );
              }
            }
          );
        }
      }
    });
  }

  recoverWallet() {
    this.dialog.open(DialogRecoverWalletComponent, {
      width: '90%',
      maxWidth: '700px',
    });
  }

  infoWallet() {
    this.dialog.open(DialogInfoWalletComponent, {
      width: '90%',
      maxWidth: '700px',
    });
  }
}
