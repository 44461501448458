import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDrawer } from '@angular/material/sidenav';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  filter: any;
  arrayFiltered: any;

  constructor(private _http: HttpClient, private drawer: MatDrawer) {}

  setDrawer(drawer: MatDrawer) {
    this.drawer = drawer;
  }

  toggle(): void {
    this.drawer.toggle();
  }

  get token(): string | null {
    return localStorage.getItem('token');
  }

  setFilter(arrayData: any) {
    this.filter = arrayData;
  }

  getFilter() {
    return this.filter;
  }

  setArrayFiltered(array: any) {
    this.arrayFiltered = array;
  }

  getArrayFiltered() {
    return this.arrayFiltered;
  }
}
