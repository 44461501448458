import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-reserved-content',
  templateUrl: './dialog-view-message.component.html',
  styleUrls: ['./dialog-view-message.component.scss'],
})
export class DialogViewMessageComponent implements OnInit {
  message: any;
  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: DialogViewMessageComponent
  ) {}

  ngOnInit(): void {
    this.message = this.data;
  }
}
