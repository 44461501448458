import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class Web3Service {
  private baseUrl = environment.baseUrl;
  win: any;
  constructor(private _http: HttpClient) {
    this.win = window as any;
  }

  addTokenToMetamask() {}
  get token(): string | null {
    return localStorage.getItem('token');
  }

  public w3ConnectAccount(data: any): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.post<any>(`${this.baseUrl}/user/w3ConnectAccount`, data, {
      headers,
    });
  }
  public w3RequestResetAccount(data: any): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.post<any>(
      `${this.baseUrl}/user/w3RequestResetAccount`,
      data,
      { headers }
    );
  }

  public w3SwapEuroToHT(data: any): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.patch<any>(`${this.baseUrl}/wallet/w3SwapToHT`, data, {
      headers,
    });
  }

  public w3SwapHTToEuro(data: any): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.patch<any>(`${this.baseUrl}/wallet/w3SwapToEUR`, data, {
      headers,
    });
  }

  public w3ResetAccount(userId: any): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.post<any>(
      `${this.baseUrl}/admin/w3ResetAccount`,
      {},
      { headers, params: new HttpParams().set('userId', userId) }
    );
  }

  public sendNotificationInvestW3(
    investmentId: any,
    body: any
  ): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.post<any>(
      `${this.baseUrl}/investment/sendNotificationInvestW3`,
      body,
      { headers, params: new HttpParams().set('investmentId', investmentId) }
    );
  }
  public createVoting(body: any): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.post<any>(`${this.baseUrl}/voting/create`, body, {
      headers,
    });
  }

  public getVoting(votingId: any): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.get<any>(`${this.baseUrl}/voting/getVoting`, {
      headers,
      params: new HttpParams().set('votingId', votingId),
    });
  }

  public getVotings(): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.get<any>(`${this.baseUrl}/voting/getVotings`, {
      headers,
    });
  }
  public confirmWinner(votingId: any,isCreate:boolean): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.post<any>(`${this.baseUrl}/voting/confirmWinner`, {isCreate:isCreate}, {
      headers,
      params: new HttpParams().set('votingId', votingId),
    });
  }
  public getSwapToken(address: string): Observable<any> {
    return this._http.get(`https://web.zapper.fi/v2/balances/tokens`, {
      params: new HttpParams()
        .set('addresses[0]', address)
        .set('networks[0]', 'polygon'),
      headers: new HttpHeaders().set('Authorization', ``),
    });
  }
  public simplePrice(ids: any): Observable<any> {
    return this._http.get<any>(
      `https://api.coingecko.com/api/v3/simple/price`,
      { params: new HttpParams().set('ids', ids).set('vs_currencies', 'usd') }
    );
  }
}
