<div class="container_card" fxLayout="column" fxLayoutAlign="space-between">
  <div *ngIf="this.w3Addresses && this.w3Addresses.length > 0">
    <div
      class="container_header_card"
      fxLayout="row"
      fxLayoutAlign="space-between"
    >
      <label class="title_card">Wallet</label>
    </div>
    <div
      class="container_header_card"
      fxLayout="row"
      fxLayoutAlign="space-between"
    >
      <label *ngIf="!getGlobalVariables().isWalletConnected" class="title_input"
        >Vuoi collegare il tuo wallet web3?</label
      >
      <label
        *ngIf="
          getGlobalVariables().isWalletConnected &&
          getGlobalVariables().wallet.address === this.w3Address.toLowerCase()
        "
        class="title_input"
        >Wallet collegato</label
      >
      <label
        *ngIf="
          getGlobalVariables().isWalletConnected &&
          getGlobalVariables().wallet.address !==
            this.w3Address.toLowerCase() &&
          getGlobalVariables().wallet.address.toLowerCase() !==
            this.newAddress.toLowerCase()
        "
        class="title_input"
        >Il wallet collegato è diverso da quello collegato iniziamente ({{
          this.w3Address.slice(0, 5) +
            "..." +
            this.w3Address.slice(
              this.w3Address.length - 4,
              this.w3Address.length
            )
        }}), cambia wallet oppure clicca sul bottone 'Ho perso il mio wallet'
        per ripristinarlo e poter usufruire del servizio.</label
      >
      <label
        *ngIf="
          getGlobalVariables().isWalletConnected &&
          getGlobalVariables().wallet.address !==
            this.w3Address.toLowerCase() &&
          getGlobalVariables().wallet.address.toLowerCase() ===
            this.newAddress.toLowerCase()
        "
        class="title_input"
        >La tua richesta di cambiamento del wallet non è ancora stata accettata,
        ricontrolla più tardi!</label
      >
    </div>
  </div>
  <div *ngIf="this.w3Addresses && this.w3Addresses.length > 0">
    <button class="button_recover" (click)="recoverWallet()">
      Ho perso il mio wallet
    </button>
    <button
      *ngIf="getGlobalVariables().isWalletConnected"
      class="button_submit"
      (click)="disconnectWallet()"
    >
      <div
        *ngIf="
          getGlobalVariables().wallet.address.toLowerCase() !==
            this.newAddress.toLowerCase() &&
          getGlobalVariables().wallet.address.toLowerCase() !==
            this.w3Address.toLowerCase()
        "
        class="point-red"
      ></div>
      <div
        *ngIf="
          getGlobalVariables().wallet.address.toLowerCase() ===
          this.newAddress.toLowerCase()
        "
        class="point-orange"
      ></div>
      <div
        *ngIf="
          getGlobalVariables().isWalletConnected &&
          getGlobalVariables().wallet.address === this.w3Address.toLowerCase()
        "
        class="point-green"
      ></div>
      {{ getGlobalVariables().wallet.addressShort }}
    </button>
    <button
      *ngIf="!getGlobalVariables().isWalletConnected"
      class="button_submit"
      (click)="connectWallet()"
    >
      Connect wallet
    </button>
  </div>
  <div *ngIf="this.w3Addresses && this.w3Addresses.length === 0">
    <div
      class="container_header_card"
      fxLayout="row"
      fxLayoutAlign="space-between"
    >
      <label class="title_card">Wallet</label>
      <img
        class="image"
        (click)="infoWallet()"
        src="assets/image/dashboard/info.svg"
        alt=""
      />
    </div>
    <div
      class="container_header_card"
      fxLayout="row"
      fxLayoutAlign="space-between"
    >
      <label class="title_input">Vuoi collegare il tuo wallet web3?</label>
    </div>
  </div>
  <div *ngIf="this.w3Addresses && this.w3Addresses.length === 0">
    <button
      *ngIf="!loadConnectFirstTime"
      class="button_submit"
      (click)="connectWallet()"
    >
      Connect wallet
    </button>
    <button *ngIf="loadConnectFirstTime" class="button_submit">
      <mat-spinner diameter="20"></mat-spinner>
    </button>
  </div>
</div>
