<div fxLayout="row" fxLayoutAlign="space-between">
  <label class="title">Cruscotto</label>
  <img class="image_close" src="assets/image/dashboard/back.svg" alt="" (click)="dialog.closeAll()">
</div>
<div class="container">
  <mat-grid-list
    cols="12"
    rowHeight="90px"
    [gutterSize]="'10px'"
    class="container_grid">
    <mat-grid-tile [colspan]="this.innerWidth > 1000 ? 4 : 12" [rowspan]="3" class="card">
      <div class="container_card" fxLayout="column" fxLayoutAlign="flex-start" fxLayoutGap="10px">
        <div fxLayout="row" fxLayoutAlign="flex-start" fxLayoutGap="10px">
          <label class="title">Wallet</label>
          <img class="image_wallet" src="assets/image/dashboard/euro.svg" alt="">
        </div>
        <div class="container_data" fxLayout="column" fxLayoutAlign="flex-start" fxLayoutGap="20px">
          <div  class="container_chart" fxLayout="row wrap" fxLayoutAlign="space-between" fxLayoutGap="10px">
            <div *ngIf="walletEuro" fxLayout="column" fxLayoutGap="5px">
              <label class="subtitle_gray">Bilancio</label>
              <label class="subtitle">€ {{this.walletEuro.balance | number: "1.2-2":"it" }}</label>
              <label class="subtitle_gray">Fondi utilizzati</label>
              <label class="subtitle">€ {{this.walletEuro.balanceUsed | number: "1.2-2":"it" }}</label>
            </div>
            <div fxLayout="column" class="chart-container">
              <canvas id="chartEuro" >{{ chartEuro }}</canvas>
            </div>
          </div>
          <div class="container_data_wallet" *ngIf="walletEuro" fxLayout="column" fxLayoutGap="5px">
            <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
              <div fxLayout="row">
                <div class="point-blue"></div>
                <label class="subtitle">Investimenti:</label>
              </div>
              <label class="subtitle">{{this.walletEuro.investment}} %</label>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
              <div fxLayout="row">
                <div class="point-red"></div>
                <label class="subtitle">Caparre pagate:</label>
              </div>
              <label class="subtitle">{{this.walletEuro.buy}} %</label>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
              <div fxLayout="row">
                <div class="point-dark-blue"></div>
                <label class="subtitle">Costruisci ristruttura:</label>
              </div>
              <label class="subtitle">{{this.walletEuro.build}} %</label>
            </div>
          </div>
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="this.innerWidth > 1000 ? 4 : 12" [rowspan]="6" class="card">
      <div class="container_card" fxLayout="column" fxLayoutAlign="flex-start" fxLayoutGap="20px">
        <label class="title">Ultimo Investimento</label>
        <div *ngIf="!loadLatestInvestment && this.latestInvestment" class="container_data_project" fxLayout="column" fxLayoutAlign="flex-start" fxLayoutGap="20px">
          <div fxLayout="column" fxLayoutGap="5px">
            <label class="subtitle_gray">Investimento {{this.latestInvestment.investment.id}}</label>
            <label class="subtitle">{{this.latestInvestment.investment.name}}</label>
            <label class="subtitle">{{this.latestInvestment.investment.address}}, {{this.latestInvestment.investment.city}},{{this.latestInvestment.investment.country}}</label>
          </div>
          <div fxLayout="column" fxLayoutGap="5px">
            <div class="image"
                 [ngStyle]="{ background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.45) 100%),url('+this.latestInvestment.investment.images1[0]+')','background-repeat': 'no-repeat','background-size': 'cover','background-position': 'center center' }">
            </div>
          </div>
          <div fxLayout="column" fxLayoutGap="5px">
            <div fxLayout="row" fxLayoutAlign="flex-start" fxLayoutGap="5px">
              <label class="subtitle">Investimento:</label>
              <label *ngIf="this.latestInvestment.attributes" class="subtitle_blue">{{this.latestInvestment.amount / 100}} HT</label>
              <label *ngIf="!this.latestInvestment.attributes" class="subtitle_blue">{{this.latestInvestment.amount}} €</label>
            </div>
            <div fxLayout="row" fxLayoutAlign="flex-start" fxLayoutGap="5px">
              <label class="subtitle">Inizio progetto:</label>
              <label class="subtitle_blue">{{this.latestInvestment.investment.w2StartDate * 1000 | date: "dd-MM-YYYY":"it"}}</label>
            </div>
            <div fxLayout="row" fxLayoutAlign="flex-start" fxLayoutGap="5px">
              <label class="subtitle">Fine progetto:</label>
              <label class="subtitle_blue">{{this.latestInvestment.investment.w2EndDate * 1000 | date: "dd-MM-YYYY":"it"}}</label>
            </div>
            <div *ngIf="this.latestInvestment.investment.wType.includes('W2')" fxLayout="row" fxLayoutAlign="flex-start" fxLayoutGap="5px">
              <label class="subtitle">Yield W2 annuale:</label>
              <label class="subtitle_blue">{{this.latestInvestment.investment.w2Yield}} %</label>
            </div>
            <div *ngIf="this.latestInvestment.attributes && this.latestInvestment.investment.wType.includes('W3')" fxLayout="row" fxLayoutAlign="flex-start" fxLayoutGap="5px">
              <label class="subtitle">Yield W3 annuale:</label>
              <label *ngIf="this.latestInvestment.attributes" class="subtitle_blue">{{this.latestInvestment.w3Yield}} %</label>
            </div>
          </div>
          <div *ngIf="this.latestInvestment.investment.wType.includes('W2')" fxLayout="column" fxLayoutGap="5px">
            <label class="subtitle">Raccolta totale: {{(this.latestInvestment.investment.w2ActualInvestment * 100 )/ this.latestInvestment.investment.w2TotalInvestment | number: "1.2-2":"it" }} %</label>
            <mat-progress-bar class="progress_bar" [value]="(this.latestInvestment.investment.w2ActualInvestment * 100) / this.latestInvestment.investment.w2TotalInvestment "></mat-progress-bar>
            <label class="subtitle">{{this.latestInvestment.investment.w2ActualInvestment}}€ / {{this.latestInvestment.investment.w2TotalInvestment}}€</label>
          </div>
          <div *ngIf="this.latestInvestment.attributes && this.latestInvestment.investment.wType.includes('W3')" fxLayout="column" fxLayoutGap="5px">
            <label class="subtitle">Raccolta totale: {{(this.latestInvestment.w3ActualInvestment * 100 )/ this.latestInvestment.w3TotalInvestment | number: "1.2-2":"it" }} %</label>
            <mat-progress-bar class="progress_bar" [value]="(this.latestInvestment.w3ActualInvestment * 100) / this.latestInvestment.w3TotalInvestment "></mat-progress-bar>
            <label class="subtitle">{{this.latestInvestment.w3ActualInvestment}}HT / {{this.latestInvestment.w3TotalInvestment}}HT</label>
          </div>
        </div>
        <div *ngIf="!loadLatestInvestment && this.latestInvestment === null" class="container_spinner">
          <label class="subtitle_blue">Non hai investimenti attivi</label>
        </div>
        <div *ngIf="loadLatestInvestment" class="container_spinner" fxLayout="column" fxLayoutGap="10px">
          <mat-spinner [diameter]="20"></mat-spinner>
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="this.innerWidth > 1000 ? 4 : 12" [rowspan]="1" class="card">
      <div class="container_card" fxLayout="column" fxLayoutAlign="flex-start" fxLayoutGap="5px">
        <label class="title">Caparre pagate</label>
        <div fxLayout="row" fxLayoutAlign="space-between">
          <label class="number" >{{this.deposit ? this.deposit.length : 0}}</label>
          <img class="goToComplexDetails" src="assets/image/dashboard/arrow-open-complex.svg" alt="" (click)="goToPage('/dashboard/buy/deposit-paid')">
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="this.innerWidth > 1000 ? 4 : 12" [rowspan]="1" class="card">
      <div class="container_card" fxLayout="column" fxLayoutAlign="flex-start" fxLayoutGap="5px">
        <label class="title">Appartamenti acquistati</label>
        <div fxLayout="row" fxLayoutAlign="space-between">
          <label class="number" >{{this.apartment_purchased ? this.apartment_purchased.length : 0}}</label>
          <img class="goToComplexDetails" src="assets/image/dashboard/arrow-open-complex.svg" alt="" (click)="goToPage('/dashboard/buy/apartment-purchased')">
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="this.innerWidth > 1000 ? 4 : 12" [rowspan]="1" class="card">
      <div class="container_card" fxLayout="column" fxLayoutAlign="flex-start" fxLayoutGap="5px">
        <label class="title">Visite prenotate</label>
        <div fxLayout="row" fxLayoutAlign="space-between">
          <label class="number" >{{this.booking ? this.booking.length : 0}}</label>
          <img class="goToComplexDetails" src="assets/image/dashboard/arrow-open-complex.svg" alt="" (click)="goToPage('/dashboard/buy/reservation')">
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="this.innerWidth > 1000 ? 4 : 12" [rowspan]="3" class="card">
      <div class="container_card" fxLayout="column" fxLayoutAlign="flex-start" fxLayoutGap="10px">
        <div fxLayout="row" fxLayoutAlign="flex-start" fxLayoutGap="10px">
          <label class="title">Wallet</label>
          <img class="image_wallet" src="assets/image/dashboard/HT.svg" alt="">
        </div>
        <div class="container_data" fxLayout="column" fxLayoutAlign="flex-start" fxLayoutGap="20px">
          <div [class]="!loadWalletCrypto ? 'container_chart':''" fxLayout="row wrap" fxLayoutAlign="space-between" fxLayoutGap="10px">
            <div *ngIf="!loadWalletCrypto" fxLayout="column" fxLayoutGap="5px">
              <label class="subtitle_gray">Bilancio</label>
              <label class="subtitle">HT {{this.walletCrypto.balance | number: "1.2-2":"it" }}</label>
              <label class="subtitle_gray">Fondi utilizzati</label>
              <label class="subtitle">HT {{this.walletCrypto.balanceUsed | number: "1.2-2":"it" }}</label>
            </div>
            <div fxLayout="column" class="chart-container">
              <canvas id="chartCrypto" >{{ chartCrypto }}</canvas>
            </div>
          </div>
          <div class="container_data_wallet" *ngIf="!loadWalletCrypto" fxLayout="column" fxLayoutGap="5px">
            <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
              <div fxLayout="row">
                <div class="point-blue"></div>
                <label class="subtitle">Investimenti:</label>
              </div>
              <label class="subtitle">{{this.walletCrypto.investment}} %</label>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
              <div fxLayout="row">
                <div class="point-red"></div>
                <label class="subtitle">Caparre pagate:</label>
              </div>
              <label class="subtitle">{{this.walletCrypto.buy}} %</label>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
              <div fxLayout="row">
                <div class="point-dark-blue"></div>
                <label class="subtitle">Costruisci ristruttura:</label>
              </div>
              <label class="subtitle">{{this.walletCrypto.build}} %</label>
            </div>
          </div>
        </div>
        <div *ngIf="loadWalletCrypto" class="container_spinner" fxLayout="column" fxLayoutGap="10px">
          <mat-spinner [diameter]="20"></mat-spinner>
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="this.innerWidth > 1000 ? 4 : 12" [rowspan]="1" class="card">
      <div class="container_card" fxLayout="column" fxLayoutAlign="flex-start" fxLayoutGap="5px">
        <label class="title">Pratiche attive</label>
        <div fxLayout="row" fxLayoutAlign="space-between">
          <label class="number" >{{this.practices ? this.practices.length : 0}}</label>
          <img class="goToComplexDetails" src="assets/image/dashboard/arrow-open-complex.svg" alt="" (click)="goToPage('/dashboard/build/user/my_practices')">
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="this.innerWidth > 1000 ? 4 : 12" [rowspan]="1" class="card">
      <div class="container_card" fxLayout="column" fxLayoutAlign="flex-start" fxLayoutGap="5px">
        <label class="title">Le tue proposte</label>
        <div fxLayout="row" fxLayoutAlign="space-between">
          <label class="number" >{{this.proposals ? this.proposals : 0}}</label>
          <img class="goToComplexDetails" src="assets/image/dashboard/arrow-open-complex.svg" alt="" (click)="goToPage('/dashboard/invest/my-proposals')">
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="this.innerWidth > 1000 ? 4 : 12" [rowspan]="1" class="card">
      <div class="container_card" fxLayout="column" fxLayoutAlign="flex-start" fxLayoutGap="5px">
        <label class="title">Investimenti attivi</label>
        <div *ngIf="!loadWalletCrypto" fxLayout="row" fxLayoutAlign="space-between">
          <label class="number" >{{this.investments ? this.investments : 0}}</label>
          <img class="goToComplexDetails" src="assets/image/dashboard/arrow-open-complex.svg" alt="" (click)="goToPage('/dashboard/invest/my-investments')">
        </div>
        <div *ngIf="loadWalletCrypto" class="container_spinner" fxLayout="column" fxLayoutGap="10px">
          <mat-spinner [diameter]="20"></mat-spinner>
        </div>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>
