import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialogModule,
} from '@angular/material/dialog';
import { SidenavDashboardComponent } from './components/sidenav-dashboard/sidenav-dashboard.component';
import { HomeService } from './core/service/home.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { DashboardModule } from './children/main/children/dashboard/dashboard.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SidenavRightComponent } from './components/sidenav-right/sidenav-right.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NotifierService } from './core/service/notifier.service';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarModule,
} from '@angular/material/snack-bar';
import { NotifierComponent } from './children/main/children/home/component/notifier/notifier.component';
import localeIt from '@angular/common/locales/it';
import { registerLocaleData } from '@angular/common';
import { BuySuperAdminSearchComponent } from './components/search/superadmin-search/buy-super-admin-search.component';
import { MatTableModule } from '@angular/material/table';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { BuyUserSearchComponent } from './components/search/user-search/buy-user-search.component';
import { BuildTechnicianSearchComponent } from './components/search/technician-search/build-technician-search.component';
import { BuildConsultantSearchComponent } from './components/search/consultant-search/build-consultant-search.component';
import { BuildSupplierSearchComponent } from './components/search/supplier-search/build-supplier-search.component';
import { BuildContractorSearchComponent } from './components/search/contractor-search/build-contractor-search.component';
import { BuildCondominiumSearchComponent } from './components/search/condominium-search/build-condominium-search.component';
import { BuildCompanySearchComponent } from './components/search/company-search/build-company-search.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { GlobalVariables } from '@scalingparrots/dapp-angular-lib';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

registerLocaleData(localeIt);
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    SidenavComponent,
    SidenavDashboardComponent,
    SidenavRightComponent,
    NotifierComponent,
    BuySuperAdminSearchComponent,
    BuyUserSearchComponent,
    BuildTechnicianSearchComponent,
    BuildConsultantSearchComponent,
    BuildSupplierSearchComponent,
    BuildContractorSearchComponent,
    BuildCondominiumSearchComponent,
    BuildCompanySearchComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatSidenavModule,
    MatListModule,
    BrowserAnimationsModule,
    MatButtonModule,
    FlexLayoutModule,
    MatDialogModule,
    HttpClientModule,
    DashboardModule,
    TranslateModule.forRoot({
      defaultLanguage: 'it',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ReactiveFormsModule,
    MatSnackBarModule,
    MatTableModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
  ],
  providers: [
    HomeService,
    MatDrawer,
    NotifierService,
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
    { provide: MAT_SNACK_BAR_DATA, useValue: { duration: 4000 } },
    { provide: LOCALE_ID, useValue: 'it-IT' },
    GlobalVariables,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
