<div class="sidenav-container">
  <div class="sidenav-container_top" fxLayout="column" fxLayoutGap="10px">
    <div
      class="container_logo"
      fxLayout="row"
      fxLayoutAlign="space-between"
      fxLayoutGap="10px"
    >
      <div
        class="container_link"
        fxLayout="row"
        fxLayoutAlign="space-between"
        fxLayoutGap="10px"
      >
        <div class="container_text"></div>
        <div class="container_text">
          <label class="title_close">CHIUDI</label>
          <img
            src="assets/image/home/close.svg"
            alt=""
            class="image_close"
            (click)="closeSidenav()"
          />
        </div>
      </div>
    </div>
    <div class="navigation" fxLayout="column" fxLayoutGap="5px">
      <span
        (click)="openFilter1()"
        class="container_link"
        fxLayout="row"
        fxLayoutAlign="space-between"
        fxLayoutGap="10px"
      >
        <div class="container_text">
          <label class="title">Dimensione</label>
        </div>
        <div class="container_text">
          <label *ngIf="filter1" class="title">+</label>
          <label *ngIf="!filter1" class="title">-</label>
        </div>
      </span>
    </div>
    <div *ngIf="filter1">
      <div fxLayout="row" class="container_filter">
        <div
          *ngIf="filterType !== '1'"
          class="pointFilter"
          (click)="setFilter('1', '0', '100')"
        ></div>
        <div
          *ngIf="filterType === '1'"
          class="pointFilterBorderBlue"
          (click)="setFilter('0', '', '')"
        >
          <div class="pointFilterBlue"></div>
        </div>
        <label class="text_filter">0-100m</label>
      </div>
      <div fxLayout="row" class="container_filter">
        <div
          *ngIf="filterType !== '2'"
          class="pointFilter"
          (click)="setFilter('2', '100', '200')"
        ></div>
        <div
          *ngIf="filterType === '2'"
          class="pointFilterBorderBlue"
          (click)="setFilter('0', '', '')"
        >
          <div class="pointFilterBlue"></div>
        </div>
        <label class="text_filter">100-200m</label>
      </div>
      <div fxLayout="row" class="container_filter">
        <div
          *ngIf="filterType !== '3'"
          class="pointFilter"
          (click)="setFilter('3', '200', '300')"
        ></div>
        <div
          *ngIf="filterType === '3'"
          class="pointFilterBorderBlue"
          (click)="setFilter('0', '', '')"
        >
          <div class="pointFilterBlue"></div>
        </div>
        <label class="text_filter">200-300m</label>
      </div>
      <div fxLayout="row" class="container_filter">
        <div
          *ngIf="filterType !== '4'"
          class="pointFilter"
          (click)="setFilter('4', '300', '500')"
        ></div>
        <div
          *ngIf="filterType === '4'"
          class="pointFilterBorderBlue"
          (click)="setFilter('0', '', '')"
        >
          <div class="pointFilterBlue"></div>
        </div>
        <label class="text_filter">300-500m</label>
      </div>
      <div fxLayout="row" class="container_filter">
        <div
          *ngIf="filterType !== '5'"
          class="pointFilter"
          (click)="setFilter('5', '500', '')"
        ></div>
        <div
          *ngIf="filterType === '5'"
          class="pointFilterBorderBlue"
          (click)="setFilter('0', '', '')"
        >
          <div class="pointFilterBlue"></div>
        </div>
        <label class="text_filter">+500m</label>
      </div>
    </div>
    <div class="navigation" fxLayout="column" fxLayoutGap="5px">
      <span
        (click)="openFilter2()"
        class="container_link"
        fxLayout="row"
        fxLayoutAlign="space-between"
        fxLayoutGap="10px"
      >
        <div class="container_text">
          <label class="title">Prezzo</label>
        </div>
        <div class="container_text">
          <label *ngIf="filter2" class="title">+</label>
          <label *ngIf="!filter2" class="title">-</label>
        </div>
      </span>
    </div>
    <div *ngIf="filter2">
      <div fxLayout="row" class="container_filter">
        <div
          *ngIf="filterType2 !== '6'"
          class="pointFilter"
          (click)="setFilter2('6', '0', '100000')"
        ></div>
        <div
          *ngIf="filterType2 === '6'"
          class="pointFilterBorderBlue"
          (click)="setFilter2('0', '', '')"
        >
          <div class="pointFilterBlue"></div>
        </div>
        <label class="text_filter">0 - 100,000€</label>
      </div>
      <div fxLayout="row" class="container_filter">
        <div
          *ngIf="filterType2 !== '7'"
          class="pointFilter"
          (click)="setFilter2('7', '100000', '500000')"
        ></div>
        <div
          *ngIf="filterType2 === '7'"
          class="pointFilterBorderBlue"
          (click)="setFilter2('0', '', '')"
        >
          <div class="pointFilterBlue"></div>
        </div>
        <label class="text_filter"> 100,000€ - 500,000€</label>
      </div>
      <div fxLayout="row" class="container_filter">
        <div
          *ngIf="filterType2 !== '8'"
          class="pointFilter"
          (click)="setFilter2('8', '500000', '1000000')"
        ></div>
        <div
          *ngIf="filterType2 === '8'"
          class="pointFilterBorderBlue"
          (click)="setFilter2('0', '', '')"
        >
          <div class="pointFilterBlue"></div>
        </div>
        <label class="text_filter">500,000€ - 1,000,000€</label>
      </div>
      <div fxLayout="row" class="container_filter">
        <div
          *ngIf="filterType2 !== '9'"
          class="pointFilter"
          (click)="setFilter2('9', '1000000', '')"
        ></div>
        <div
          *ngIf="filterType2 === '9'"
          class="pointFilterBorderBlue"
          (click)="setFilter2('0', '', '')"
        >
          <div class="pointFilterBlue"></div>
        </div>
        <label class="text_filter">+1,000,000€</label>
      </div>
    </div>
    <div class="navigation" fxLayout="column" fxLayoutGap="5px">
      <span
        (click)="openFilter3()"
        class="container_link"
        fxLayout="row"
        fxLayoutAlign="space-between"
        fxLayoutGap="10px"
      >
        <div class="container_text">
          <label class="title">Piano</label>
        </div>
        <div class="container_text">
          <label *ngIf="filter3" class="title">+</label>
          <label *ngIf="!filter3" class="title">-</label>
        </div>
      </span>
    </div>
    <div *ngIf="filter3">
      <div fxLayout="row" class="container_filter">
        <div
          *ngIf="filterType3 !== '10'"
          class="pointFilter"
          (click)="setFilter3('10', '0', '5')"
        ></div>
        <div
          *ngIf="filterType3 === '10'"
          class="pointFilterBorderBlue"
          (click)="setFilter3('0', '', '')"
        >
          <div class="pointFilterBlue"></div>
        </div>
        <label class="text_filter">0 - 5</label>
      </div>
      <div fxLayout="row" class="container_filter">
        <div
          *ngIf="filterType3 !== '11'"
          class="pointFilter"
          (click)="setFilter3('11', '5', '10')"
        ></div>
        <div
          *ngIf="filterType3 === '11'"
          class="pointFilterBorderBlue"
          (click)="setFilter3('0', '', '')"
        >
          <div class="pointFilterBlue"></div>
        </div>
        <label class="text_filter"> 5 - 10</label>
      </div>
      <div fxLayout="row" class="container_filter">
        <div
          *ngIf="filterType3 !== '12'"
          class="pointFilter"
          (click)="setFilter3('12', '10', '15')"
        ></div>
        <div
          *ngIf="filterType3 === '12'"
          class="pointFilterBorderBlue"
          (click)="setFilter3('0', '', '')"
        >
          <div class="pointFilterBlue"></div>
        </div>
        <label class="text_filter">10 - 15</label>
      </div>
      <div fxLayout="row" class="container_filter">
        <div
          *ngIf="filterType3 !== '13'"
          class="pointFilter"
          (click)="setFilter3('13', '15', '')"
        ></div>
        <div
          *ngIf="filterType3 === '13'"
          class="pointFilterBorderBlue"
          (click)="setFilter3('0', '', '')"
        >
          <div class="pointFilterBlue"></div>
        </div>
        <label class="text_filter">+15</label>
      </div>
    </div>
    <div class="navigation" fxLayout="column" fxLayoutGap="5px">
      <span
        (click)="openFilter4()"
        class="container_link"
        fxLayout="row"
        fxLayoutAlign="space-between"
        fxLayoutGap="10px"
      >
        <div class="container_text">
          <label class="title">Tipologia</label>
        </div>
        <div class="container_text">
          <label *ngIf="filter4" class="title">+</label>
          <label *ngIf="!filter4" class="title">-</label>
        </div>
      </span>
    </div>
    <div *ngIf="filter4">
      <div fxLayout="row" class="container_filter">
        <div
          *ngIf="filterType4 !== '14'"
          class="pointFilter"
          (click)="setFilter4('14', 'Monolocale')"
        ></div>
        <div
          *ngIf="filterType4 === '14'"
          class="pointFilterBorderBlue"
          (click)="setFilter4('0', '')"
        >
          <div class="pointFilterBlue"></div>
        </div>
        <label class="text_filter">Monolocale</label>
      </div>
      <div fxLayout="row" class="container_filter">
        <div
          *ngIf="filterType4 !== '15'"
          class="pointFilter"
          (click)="setFilter4('15', 'Bilocale')"
        ></div>
        <div
          *ngIf="filterType4 === '15'"
          class="pointFilterBorderBlue"
          (click)="setFilter4('0', '')"
        >
          <div class="pointFilterBlue"></div>
        </div>
        <label class="text_filter">Bilocale</label>
      </div>
      <div fxLayout="row" class="container_filter">
        <div
          *ngIf="filterType4 !== '16'"
          class="pointFilter"
          (click)="setFilter4('16', 'Trilocale')"
        ></div>
        <div
          *ngIf="filterType4 === '16'"
          class="pointFilterBorderBlue"
          (click)="setFilter4('0', '')"
        >
          <div class="pointFilterBlue"></div>
        </div>
        <label class="text_filter">Trilocale</label>
      </div>
      <div fxLayout="row" class="container_filter">
        <div
          *ngIf="filterType4 !== '17'"
          class="pointFilter"
          (click)="setFilter4('17', 'Qadrilocale')"
        ></div>
        <div
          *ngIf="filterType4 === '17'"
          class="pointFilterBorderBlue"
          (click)="setFilter4('0', '')"
        >
          <div class="pointFilterBlue"></div>
        </div>
        <label class="text_filter">Quadrilocale</label>
      </div>
      <div fxLayout="row" class="container_filter">
        <div
          *ngIf="filterType4 !== '18'"
          class="pointFilter"
          (click)="setFilter4('18', 'Plurilocale')"
        ></div>
        <div
          *ngIf="filterType4 === '18'"
          class="pointFilterBorderBlue"
          (click)="setFilter4('0', '')"
        >
          <div class="pointFilterBlue"></div>
        </div>
        <label class="text_filter">Plurilocale</label>
      </div>
    </div>
    <div class="navigation" fxLayout="column" fxLayoutGap="5px">
      <span
        (click)="openFilter5()"
        class="container_link"
        fxLayout="row"
        fxLayoutAlign="space-between"
        fxLayoutGap="10px"
      >
        <div class="container_text">
          <label class="title">Nome</label>
        </div>
        <div class="container_text">
          <label *ngIf="filter5" class="title">+</label>
          <label *ngIf="!filter5" class="title">-</label>
        </div>
      </span>
    </div>
    <div *ngIf="filter5">
      <form [formGroup]="searchForm" fxLayout="row" class="container_filter">
        <input
          class="search"
          placeholder="Cerca per nome"
          formControlName="search"
        />
      </form>
    </div>
    <div class="sidenav_container_bottom" fxLayout="row">
      <button class="button_reset" (click)="Reset()">Reset</button>
      <button class="button" (click)="confirmFilter()">Applica filtri</button>
    </div>
  </div>
</div>
