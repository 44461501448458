<div>
  <mat-grid-list
    cols="4"
    rowHeight="105px"
    [gutterSize]="'10px'"
    fxLayoutGap="30px"
    style="margin: 30px 0"
  >
    <mat-grid-tile
      [colspan]="innerWidth > 1000 ? 2 : 4"
      [rowspan]="innerWidth > 500 ? 6 : 3"
      class="card"
    >
      <div class="container_card">
        <div
          class="container_header_card"
          fxLayout="row"
          fxLayoutAlign="space-between"
        >
          <label class="title_card">Progetti</label>
          <div fxLayout="column">
            <div class="container_show_all">
              <label
                class="text_show_all"
                [routerLink]="'/dashboard/invest/project'"
                >vedi tutti <mat-icon>keyboard_arrow_right</mat-icon></label
              >
            </div>
          </div>
        </div>
        <div
          *ngIf="allComplexToInvest && allComplexToInvest.length > 0"
          class="container_table"
        >
          <table class="table" mat-table [dataSource]="allComplexToInvest">
            <ng-container matColumnDef="project">
              <th mat-header-cell *matHeaderCellDef>
                <label class="label_header_table">Progetto</label>
              </th>
              <td mat-cell *matCellDef="let element" data-label="Progetto">
                <label class="label_desc_table">{{ element.name }}</label>
              </td>
            </ng-container>

            <ng-container matColumnDef="user">
              <th mat-header-cell *matHeaderCellDef>
                <label class="label_header_table">Utente</label>
              </th>
              <td mat-cell *matCellDef="let element" data-label="Utente">
                <label class="label_desc_table"
                  >{{ element.proposer.firstName }}
                  {{ element.proposer.lastName }}</label
                >
              </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>
                <label class="label_header_table">Stato lavori</label>
              </th>
              <td mat-cell *matCellDef="let element" data-label="Stato lavori">
                <div class="center-status">
                  <div
                    *ngIf="element.workStatus === 'Da avviare'"
                    class="point-orange"
                  ></div>
                  <div
                    *ngIf="element.workStatus === 'Avviati'"
                    class="point-blue"
                  ></div>
                  <div
                    *ngIf="element.workStatus === 'Conclusi'"
                    class="point-green"
                  ></div>
                  <label
                    class="label_desc_table_cursor"
                    [routerLink]="'/dashboard/invest/project/' + element.id"
                    >{{ element.workStatus }}</label
                  >
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="data">
              <th mat-header-cell *matHeaderCellDef>
                <label class="label_header_table">Inizio lavori</label>
              </th>
              <td mat-cell *matCellDef="let element" data-label="Inizio lavori">
                <app-start-date-w3 [investment]="element"></app-start-date-w3>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
        <div
          *ngIf="allComplexToInvest && allComplexToInvest.length <= 0"
          class="container_no_apartments"
        >
          <label class="no_apartments">Non ci sono ancora investimenti</label>
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile
      [colspan]="innerWidth > 1000 ? 2 : 4"
      [rowspan]="innerWidth > 500 ? 6 : 3"
      class="card"
    >
      <div class="container_card">
        <div
          class="container_header_card"
          fxLayout="row"
          fxLayoutAlign="space-between"
        >
          <label class="title_card">Richieste prelievo</label>
          <div fxLayout="column">
            <div class="container_show_all">
              <label
                class="text_show_all"
                [routerLink]="'/dashboard/invest/wallet'"
                >vedi tutti <mat-icon>keyboard_arrow_right</mat-icon></label
              >
            </div>
          </div>
        </div>
        <div
          *ngIf="allWithdraw && allWithdraw.length > 0"
          class="container_table"
        >
          <table class="table" mat-table [dataSource]="allWithdraw">
            <ng-container matColumnDef="user_id">
              <th mat-header-cell *matHeaderCellDef>
                <label class="label_header_table">Id Utente</label>
              </th>
              <td mat-cell *matCellDef="let element" data-label="Id Utente">
                <label class="label_desc_table"
                  >{{ element.wallet.user.firstName }}
                  {{ element.wallet.user.lastName }} ({{
                    element.wallet.user.id
                  }})</label
                >
              </td>
            </ng-container>

            <ng-container matColumnDef="data">
              <th mat-header-cell *matHeaderCellDef>
                <label class="label_header_table">Data richiesta</label>
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                data-label="Data richiesta"
              >
                <label class="label_desc_table">{{
                  element.wallet.date * 1000
                    | date: "dd/MM/YYYY":"":"it"
                    | titlecase
                }}</label>
              </td>
            </ng-container>

            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef>
                <label class="label_header_table">Importo</label>
              </th>
              <td mat-cell *matCellDef="let element" data-label="Importo">
                <label class="label_desc_table"
                  >{{ element.amount | number: "1.2-2":"it" }} €</label
                >
              </td>
            </ng-container>

            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>
                <label class="label_header_table">Intestatario</label>
              </th>
              <td mat-cell *matCellDef="let element" data-label="Intestatario">
                <label class="label_desc_table">{{
                  element.transfer.receiverName
                }}</label>
              </td>
            </ng-container>

            <ng-container matColumnDef="wallet">
              <th mat-header-cell *matHeaderCellDef>
                <label class="label_header_table">Banca</label>
              </th>
              <td mat-cell *matCellDef="let element" data-label="Banca">
                <label class="label_desc_table">{{
                  element.transfer.bankName
                }}</label>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns1"></tr>
          </table>
        </div>
        <div
          *ngIf="allWithdraw && allWithdraw.length <= 0"
          class="container_no_apartments"
        >
          <label class="no_apartments"
            >Non ci sono ancora richieste di prelievo</label
          >
        </div>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>
