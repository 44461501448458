import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InvestSuperAdminHomeComponent } from './children/invest-dashboard/components/home/invest-dashboard-home/components/invest-superadmin-home/invest-super-admin-home.component';

const routes: Routes = [
  {
    path: 'buy',
    canActivate: [],
    loadChildren: () =>
      import('./children/buy-dashboard/buy-dashboard.module').then(
        (m) => m.BuyDashboardModule
      ),
  },
  {
    path: 'build',
    canActivate: [],
    loadChildren: () =>
      import('./children/build-dashboard/build-dashboard.module').then(
        (m) => m.BuildDashboardModule
      ),
  },
  {
    path: 'invest',
    canActivate: [],
    loadChildren: () =>
      import('./children/invest-dashboard/invest-dashboard.module').then(
        (m) => m.InvestDashboardModule
      ),
  },
  {
    path: 'propose',
    component: InvestSuperAdminHomeComponent,
  },
  {
    path: '',
    redirectTo: 'buy',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
