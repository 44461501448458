<div fxLayout="row" fxLayoutAlign="space-between">
  <label class="title">Cruscotto</label>
  <img class="image_close" src="assets/image/dashboard/back.svg" alt="" (click)="dialog.closeAll()">
</div>
<div class="container">
  <mat-grid-list
    cols="12"
    rowHeight="90px"
    [gutterSize]="'10px'"
    class="container_grid">
    <mat-grid-tile [colspan]="this.innerWidth > 1000 ? 9 : 12" [rowspan]="6" class="card">
      <div class="container_card" fxLayout="column" fxLayoutAlign="flex-start" fxLayoutGap="20px">
        <label class="title">Ultima Pratica</label>
        <div *ngIf="!loadLatestPractices && this.practices" class="container_practices" fxLayout="column" fxLayoutAlign="flex-start" fxLayoutGap="20px">
          <mat-grid-list
            cols="12"
            rowHeight="90px"
            [gutterSize]="'10px'">
            <mat-grid-tile
              [colspan]="innerWidth > 1000 ? 12 : 12"
              [rowspan]="2"
              class="card_practices"
            >
              <div class="container_card_practices">
                <div [ngClass]="'container_header_card_details'"
                     fxLayout="row wrap"
                     fxLayoutAlign="flex-start"
                     fxLayoutGap="10px"
                >
                  <div fxLayout="column" class="container_data_practices" fxLayoutGap="10px">
                    <label class="subtitle_desc_gray">Lavoro:</label>
                    <label class="subtitle_desc">{{ this.practices.name }}</label>
                  </div>
                  <div fxLayout="column" class="container_data_practices" fxLayoutGap="10px">
                    <label class="subtitle_desc_gray">Indirizzo:</label>
                    <label class="subtitle_desc">{{ this.practices.address }}</label>
                  </div>
                  <div fxLayout="column" class="container_data_practices" fxLayoutGap="10px">
                    <label class="subtitle_desc_gray">Tipologia:</label>
                    <label class="subtitle_desc">{{
                      this.practices.type === "renovate" ? "Ristruttura" : "Costruisci"
                      }}</label>
                  </div>
                  <div fxLayout="column" class="container_data_practices" fxLayoutGap="10px">
                    <label class="subtitle_desc_gray">Stato pratica:</label>
                    <label *ngIf="practices.status === 'created'" class="status-gray"
                    >Creata</label
                    >
                    <label *ngIf="practices.status === 'onHold'" class="status-orange"
                    >In attesa</label
                    >
                    <label *ngIf="practices.status === 'approvedAll'" class="status-blue"
                    >Approvata</label
                    >
                    <label *ngIf="practices.status === 'completed'" class="status-green"
                    >Completata</label
                    >
                    <label *ngIf="practices.status === 'finished'" class="status-red"
                    >Chiusa</label
                    >
                    <label *ngIf="practices.status === 'resold'" class="status-blue"
                    >Rivenduta</label
                    >
                  </div>
                  <div fxLayout="column" class="container_data_practices" fxLayoutGap="10px">
                    <label class="subtitle_desc_gray">Contratti:</label>
                    <label
                      *ngIf="allContractLink && allContractLink.length > 0"
                      [ngClass]="'no_apartments_underline'"
                      (click)="downloadAllContract()"
                    >Scarica tutti</label
                    >
                    <label
                      *ngIf="allContractLink && allContractLink.length === 0"
                      [ngClass]="'subtitle_desc'"
                    >Nessun contratto</label
                    >
                  </div>
                  <div fxLayout="column" class="container_data_practices" fxLayoutGap="10px">
                    <label class="subtitle_desc_gray">Committente:</label>
                    <label class="subtitle_desc"
                    >{{ this.practices.user.firstName }}
                      {{ this.practices.user.lastName }}</label
                    >
                  </div>
                  <div fxLayout="column" class="container_data_practices" fxLayoutGap="10px">
                    <label class="subtitle_desc_gray">Budget:</label>
                    <label class="subtitle_desc"
                    >{{ this.practices.budget | number: "1.2-2":"it" }}€</label
                    >
                  </div>
                  <div fxLayout="column" class="container_data_practices" fxLayoutGap="10px">
                    <label class="subtitle_desc_gray">Inizio lavori:</label>
                    <label class="subtitle_desc">{{
                      this.practices.dateScheduledStart * 1000
                        | date: "dd/MM/YYYY":"":"it"
                        | titlecase
                      }}</label>
                  </div>
                  <div fxLayout="column" class="container_data_practices" fxLayoutGap="10px">
                    <label class="subtitle_desc_gray">Data creazione:</label>
                    <label class="subtitle_desc">{{
                      this.practices.dateCreation * 1000
                        | date: "dd/MM/YYYY":"":"it"
                        | titlecase
                      }}</label>
                  </div>
                  <div
                    *ngIf="this.practices.type !== 'build'"
                    fxLayout="column"
                    class="container_data_practices"
                    fxLayoutGap="10px"
                  >
                    <label class="subtitle_desc_gray">Form caricato:</label>
                    <label
                      *ngIf="this.practices.renovateForm === null"
                      class="subtitle_desc"
                    >Nessun form</label
                    >
                    <label
                      *ngIf="this.practices.renovateForm !== null"
                      class="no_apartments_pointer"
                      fxLayout="row"
                      (click)="goToPage('/dashboard/build/consultant/my_consulting/renovate/' +this.practices.id +'/edit')"
                    >Visualizza<img
                      class="open_cme"
                      src="assets/image/dashboard/open-blue.svg"
                      alt=""
                    /></label>
                  </div>
                  <div
                    *ngIf="this.practices.type !== 'build'"
                    fxLayout="column"
                    class="container_data_practices"
                    fxLayoutGap="10px"
                  >
                    <label class="subtitle_desc_gray">Stato form:</label>
                    <label *ngIf="practices.renovateForm === null" class="subtitle_desc"
                    >Nessuna Form trovata</label
                    >
                    <label
                      fxLayout="row"
                      *ngIf="
                practices.renovateForm !== null &&
                practices.renovateForm.status === 'submitted'
              "
                      class="point-blue"
                    >Inviata</label
                    >
                    <label
                      fxLayout="row"
                      *ngIf="
                practices.renovateForm !== null &&
                practices.renovateForm.status === 'rejected'
              "
                      class="point-red"
                      (click)="openViewMessage(practices.renovateForm.message)"
                    >Rifiutata <mat-icon class="open">chat</mat-icon></label
                    >
                    <label
                      fxLayout="row"
                      *ngIf="
                practices.renovateForm !== null &&
                practices.renovateForm.status === 'approved'
              "
                      class="point-green"
                      (click)="openViewMessage(practices.renovateForm.message)"
                    >Confermata <mat-icon class="open">chat</mat-icon></label
                    >
                  </div>
                  <div fxLayout="column" class="container_data_practices" fxLayoutGap="10px">
                    <label class="subtitle_desc_gray">Vedi CME:</label>
                    <label *ngIf="this.practices.CME === null" class="subtitle_desc"
                    >Nessun CME</label
                    >
                    <label
                      *ngIf="this.practices.CME !== null"
                      class="no_apartments_pointer"
                      fxLayout="row"
                      (click)="openPopupCME()"
                    >{{ this.practices.id
                      }}<img
                        class="open_cme"
                        src="assets/image/dashboard/open-blue.svg"
                        alt=""
                      /></label>
                  </div>
                  <div fxLayout="column" class="container_data_practices" fxLayoutGap="10px">
                    <label class="subtitle_desc_gray">Stato CME:</label>
                    <label *ngIf="practices.CME === null" class="subtitle_desc"
                    >Nessun CME trovato</label
                    >
                    <label
                      fxLayout="row"
                      *ngIf="
                practices.CME !== null && practices.CME.status === 'submitted'
              "
                      class="status-blue"
                    >Inviato</label
                    >
                    <label
                      fxLayout="row"
                      *ngIf="
                practices.CME !== null && practices.CME.status === 'rejected'
              "
                      class="status-red"
                      (click)="openViewMessage(practices.CME.message)"
                    >Rifiutata <mat-icon class="open">chat</mat-icon></label
                    >
                    <label
                      fxLayout="row"
                      *ngIf="
                practices.CME !== null && practices.CME.status === 'approved'
              "
                      class="status-green"
                      (click)="openViewMessage(practices.CME.message)"
                    >Confermato <mat-icon class="open">chat</mat-icon></label
                    >
                  </div>
                  <div fxLayout="column" class="container_data_practices" fxLayoutGap="10px">
                    <label class="subtitle_desc_gray">Timestamp:</label>
                    <label
                      *ngIf="
                this.practices.timestamps &&
                this.practices.timestamps.length > 0 &&
                practices.timestamps[practices.timestamps.length - 1].status !==
                  'confirmed'
              "
                      class="no_apartments_pointer"
                      fxLayout="row"
                      (click)="
                openUrl(
                  practices.timestamps[practices.timestamps.length - 1]
                    .explorerURL
                )
              "
                    >{{ practices.timestamps[practices.timestamps.length - 1].status
                      }}<img
                        class="open_cme"
                        src="assets/image/dashboard/certi_icon.svg"
                        (click)="
                  openUrl(
                    practices.timestamps[practices.timestamps.length - 1]
                      .explorerURL
                  )
                "
                        alt=""
                      /></label>
                    <label
                      *ngIf="
                this.practices.timestamps &&
                this.practices.timestamps.length > 0 &&
                practices.timestamps[practices.timestamps.length - 1].status ===
                  'confirmed'
              "
                      class="no_apartments_pointer"
                      fxLayout="row"
                      (click)="
                openUrl(
                  practices.timestamps[practices.timestamps.length - 1]
                    .explorerURL
                )
              "
                    >{{
                      practices.timestamps[practices.timestamps.length - 1]
                        .timestamp * 1000
                        | date: "dd/MM/YYYY HH:mm":"":"it"
                        | titlecase
                      }}<img
                        class="open_cme"
                        src="assets/image/dashboard/certi_icon.svg"
                        (click)="
                  openUrl(
                    practices.timestamps[practices.timestamps.length - 1]
                      .explorerURL
                  )
                "
                        alt=""
                      /></label>
                  </div>
                </div>
              </div>
            </mat-grid-tile>
            <mat-grid-tile
              [colspan]="innerWidth > 1000 ? 3 : 12"
              [rowspan]="3"
              class="card_practices"
            >
              <div class="container_card_practices">
                <div
                  class="container_header_card"
                  fxLayout="row"
                  fxLayoutAlign="space-between"
                >
                  <label class="title_card">Offerte contrattualizzate</label>
                </div>
                <div
                  *ngIf="this.confirmedWork && this.confirmedWork.length > 0"
                  fxLayout="column"
                  class="container_Macrocategories"
                >
                  <div
                    *ngFor="let channel of this.confirmedWork"
                    class="container_card_proposal"
                    fxLayoutAlign="space-between"
                    fxLayoutGap="5px"
                  >
                    <div>
                      <label class="subtitle_desc_gray"
                      >{{ channel["channelTo"]["firstName"] }}
                        {{ channel["channelTo"]["lastName"] }}</label
                      >
                    </div>
                    <div>
                      <label
                        class="no_apartments_underline"
                        (click)="goToPage( '/dashboard/build/consultant/proposal/' + channel.id)"
                      >Visualizza</label
                      >
                    </div>
                  </div>
                </div>
                <div
                  *ngIf="this.confirmedWork && this.confirmedWork.length <= 0"
                  class="container_no_apartments"
                >
                  <label class="no_apartments">Non sono presenti offerte</label>
                </div>
              </div>
            </mat-grid-tile>
            <mat-grid-tile
              [colspan]="innerWidth > 1000 ? 3 : 12"
              [rowspan]="3"
              class="card_practices">
              <div class="container_card_practices">
                <div
                  class="container_header_card"
                  fxLayout="row"
                  fxLayoutAlign="space-between"
                >
                  <label class="title_card">Offerte in negoziazione</label>
                </div>
                <div
                  *ngIf="waitingWork && waitingWork.length > 0"
                  fxLayout="column"
                  class="container_Macrocategories"
                >
                  <div
                    *ngFor="let channel of waitingWork"
                    class="container_card_proposal"
                    fxLayoutAlign="space-between"
                    fxLayoutGap="5px"
                  >
                    <div>
                      <label class="subtitle_desc_gray"
                      >{{ channel["channelTo"]["firstName"] }}
                        {{ channel["channelTo"]["lastName"] }}</label
                      >
                    </div>
                    <div>
                      <label
                        class="no_apartments_underline"
                        (click)="goToPage( '/dashboard/build/consultant/proposal/' + channel.id)"
                      >Visualizza</label
                      >
                    </div>
                  </div>
                </div>
                <div
                  *ngIf="waitingWork && waitingWork.length <= 0"
                  class="container_no_apartments"
                >
                  <label class="no_apartments">Non sono presenti offerte</label>
                </div>
              </div>
            </mat-grid-tile>
            <mat-grid-tile
              [colspan]="innerWidth > 1000 ? 3 : 12"
              [rowspan]="3"
              class="card_practices">
              <div class="container_card_practices">
                <div
                  class="container_header_card"
                  fxLayout="row"
                  fxLayoutAlign="space-between"
                >
                  <label class="title_card">Macrocategorie</label>
                </div>
                <div
                  *ngIf="
            this.practices.macroCategories &&
            this.practices.macroCategories.length > 0
          "
                  fxLayout="column"
                  class="container_Macrocategories"
                >
                  <div
                    *ngFor="let channel of this.practices.macroCategories"
                    class="container_card_macrocategories"
                    fxLayoutAlign="space-between"
                  >
                    <div fxLayout="row" fxLayoutAlign="flex-start" fxLayoutGap="10px">
                      <div>
                        <div *ngIf="channel.isCompleted" class="circle-green"></div>
                        <div *ngIf="!channel.isCompleted" class="circle-orange"></div>
                      </div>
                      <label class="subtitle_desc_gray">{{
                        channel["macroCategory"]
                        }}</label>
                    </div>
                  </div>
                </div>
                <div
                  *ngIf="
            (this.practices.macroCategories &&
              this.practices.macroCategories.length <= 0) ||
            this.practices.macroCategories === null
          "
                  class="container_no_apartments"
                >
                  <label class="no_apartments">Non sono presenti macrocategorie</label>
                </div>
              </div>
            </mat-grid-tile>
            <mat-grid-tile
              [colspan]="innerWidth > 1000 ? 3 : 12"
              [rowspan]="3"
              class="card_practices">
              <div class="container_card_practices">
                <div
                  class="container_header_card"
                  fxLayout="row"
                  fxLayoutAlign="space-between"
                >
                  <label class="title_card">Aggiornamenti lavori</label>
                </div>
                <div
                  *ngIf="this.updates && this.updates.length > 0"
                  fxLayout="column"
                  class="container_updates"
                  fxLayoutGap="20px"
                >
                  <div
                    *ngFor="let update of this.updates"
                    fxLayout="column"
                    fxLayoutGap="10px"
                  >
                    <div fxLayout="row" fxLayoutAlign="space-between">
                      <label class="subtitle_desc">{{ update.title }}</label>
                      <label class="subtitle_desc_gray">{{
                        update.date * 1000 | date: "dd MMMM YYYY":"":"it" | titlecase
                        }}</label>
                    </div>
                    <label class="desc">{{ update.description }}</label>
                    <div class="line"></div>
                  </div>
                </div>
                <div
                  *ngIf="this.updates && this.updates.length <= 0"
                  class="container_no_apartments"
                >
                  <label class="no_apartments">Non sono presenti aggiornamenti</label>
                </div>
              </div>
            </mat-grid-tile>
          </mat-grid-list>
        </div>
        <div *ngIf="!loadLatestPractices && this.practices === null" class="container_spinner">
          <label class="subtitle_blue">Non hai pratiche</label>
        </div>
        <div *ngIf="loadLatestPractices" class="container_spinner" fxLayout="column" fxLayoutGap="10px">
          <mat-spinner [diameter]="20"></mat-spinner>
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="this.innerWidth > 1000 ? 3 : 12" [rowspan]="2" class="card">
      <div class="container_card" fxLayout="column" fxLayoutAlign="flex-start" fxLayoutGap="40px">
        <label class="title">Pratiche attive</label>
        <div fxLayout="row" fxLayoutAlign="space-between">
          <label class="number" >{{this.enabledPractices ? this.enabledPractices : 0}}</label>
          <img class="goToComplexDetails" src="assets/image/dashboard/arrow-open-complex.svg" alt="" (click)="goToPage('/dashboard/build/consultant/my_consulting')">
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="this.innerWidth > 1000 ? 3 : 12" [rowspan]="2" class="card">
      <div class="container_card" fxLayout="column" fxLayoutAlign="flex-start" fxLayoutGap="40px">
        <label class="title">Richieste consulenza</label>
        <div fxLayout="row" fxLayoutAlign="space-between">
          <label class="number" >{{this.requests ? this.requests : 0}}</label>
          <img class="goToComplexDetails" src="assets/image/dashboard/arrow-open-complex.svg" alt="" (click)="goToPage('/dashboard/build/consultant/consulting_requests')">
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="this.innerWidth > 1000 ? 3 : 12" [rowspan]="2" class="card">
      <div class="container_card" fxLayout="column" fxLayoutAlign="flex-start" fxLayoutGap="40px">
        <label class="title">Pratiche terminate</label>
        <div fxLayout="row" fxLayoutAlign="space-between">
          <label class="number" >{{this.finishPractices ? this.finishPractices.length : 0}}</label>
          <img class="goToComplexDetails" src="assets/image/dashboard/arrow-open-complex.svg" alt="" (click)="goToPage('/dashboard/build/consultant/my_consulting')">
        </div>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>
