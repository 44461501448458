import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  ChainId,
  ContractService,
  GlobalVariables,
  NETWORK_INFO,
  NetworkService,
  WalletService,
} from '@scalingparrots/dapp-angular-lib';
import { environment } from '../../../environments/environment';
import { PRIMARY_NETWORK } from '../card-connect-wallet/card-connect-wallet.component';
import { HomeService } from '../../core/service/home.service';
import { Web3Service } from '../../core/service/web3.service';
import { getBestQuoteSwapEncodedAuto } from '@scalingparrots/uniswap-smart-routing';
import { BigNumber } from '@ethersproject/bignumber';
import { HttpClient } from '@angular/common/http';
import { ethers } from 'ethers';
import { NotifierService } from '../../core/service/notifier.service';
import { PriceService } from '../../core/service/price.service';
import {MatDialog} from "@angular/material/dialog";
import {
  DialogInfoSwapComponent
} from "../../children/main/children/home/component/dialog/dialog-info-swap/dialog-info-swap.component";
import {Router} from "@angular/router";

const tokenHTABI = require('src/app/core/abi/tokenABI.json');
const swapHTABI = require('src/app/core/abi/swapABI.json');
const erc20 = require('src/app/core/abi/erc20ABI.json');

@Component({
  selector: 'app-invest-user-crypto-wallet',
  templateUrl: './swap-crypto-wallet.component.html',
  styleUrls: ['./swap-crypto-wallet.component.scss'],
})
export class SwapCryptoWalletComponent implements OnInit {
  selectForm: FormGroup = new FormGroup({});
  swapTokenToTokenForm: FormGroup = new FormGroup({});
  swapEuroToTokenForm: FormGroup = new FormGroup({});
  swapTokenToEuroForm: FormGroup = new FormGroup({});
  loadTransfer: boolean = false;
  loadSwapTKToHT: boolean = false;
  loadSwapEuroToHT: boolean = false;
  loadSwapHTToEuro: boolean = false;
  selectDisabled: boolean = false;
  listTransfer: any[] = [];
  win: any;
  connectionW3: any;
  primary_network =
    environment.primary_network === 'POLYGON'
      ? NETWORK_INFO[ChainId.Polygon]
      : PRIMARY_NETWORK;
  wallet: any;
  w3Address: any;
  decimals: any;
  innerWidth: any;
  quote: any;
  slippage: any = 0.5;
  valuePrice: any = 0;
  HTBalance: number = 0.0;
  myBalance: number = 0;
  balance: string = '0';
  type: string = 'wallet';
  valueSelected: string = 'WBTC';
  arrayToken = ['WBTC', 'WETH', 'MATIC', 'USDC', 'USDT', 'SUSHI', 'DAI', 'FRAX',];
  selectedToken: string = '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6';
  statusSwap: string = '';

  constructor(
    private _formBuilder: FormBuilder,
    private _contractService: ContractService,
    private _networkService: NetworkService,
    private _walletService: WalletService,
    private homeService: HomeService,
    private web3Service: Web3Service,
    private _http: HttpClient,
    private _notifierService: NotifierService,
    private _priceService: PriceService,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.win = window as any;
  }

  ngOnInit(): void {
    this.selectDisabled = true;
    this.innerWidth = innerWidth;
    if (this.homeService.profile.w3Addresses.length > 0) {
      const w3Addresses = this.homeService.profile.w3Addresses.filter(
        (address: any) => address.isMain === true
      );
      this.w3Address = w3Addresses[0].address;
      this.connectionW3 = this.isConnected(this.w3Address);
      this.wallet = this.homeService.profile.wallet;
      this.balance = this.homeService.profile.wallet.balance;
      this._contractService
        .readContract(
          environment.TOKEN_HT,
          environment.RPC,
          tokenHTABI,
          'decimals',
          []
        )
        .then(
          (res) => {
            this.decimals = res;
            this.getHTBalance();
            this.getListTransfer();
            this.getSwapToken();
            this.convertTokenFirstTime();
          },
          () => {}
        );
    } else {
      this.w3Address = '';
    }
    this.selectForm = this._formBuilder.group({
      select: ['wallet'],
    });
    this.swapTokenToTokenForm = this._formBuilder.group({
      amount: [''],
      HT: [''],
    });
    this.swapEuroToTokenForm = this._formBuilder.group({
      euro: [''],
      HT: [''],
    });
    this.swapTokenToEuroForm = this._formBuilder.group({
      HT: [''],
      euro: [''],
    });
  }

  getHTBalance(): void {
    this._contractService.readContract(environment.TOKEN_HT, environment.RPC, tokenHTABI, 'balanceOf', [this.w3Address])
      .then((res) => {
        this.HTBalance = +res._hex / 10 ** Number(this.decimals);
      })
      .catch((error: any) => console.error('balanceOf', error.message));
  }

  setSelect() {
    this.type = this.selectForm.get('select')?.value;
  }

  async selectValue(value: any) {
    this.valueSelected = value;
    if (value === 'WBTC') {
      this.selectedToken = '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6';
      this.selectDisabled = true;
      this.convertTokenFirstTime();
    } else if (value === 'WETH') {
      this.selectedToken = '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619';
      this.selectDisabled = true;
      this.convertTokenFirstTime();
    } else if (value === 'MATIC') {
      this.selectedToken = '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270';
      this.selectDisabled = true;
      this.convertTokenFirstTime();
    } else if (value === 'USDC') {
      this.selectedToken = '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174';
      this.selectDisabled = true;
      this.convertTokenFirstTime();
    } else if (value === 'Tether usd') {
      this.selectedToken = '0xc2132D05D31c914a87C6611C10748AEb04B58e8F';
      this.selectDisabled = true;
      this.convertTokenFirstTime();
    } else if (value === 'SUSHI') {
      this.selectedToken = '0x0b3F868E0BE5597D5DB7fEB59E1CADBb0fdDa50a';
      this.selectDisabled = true;
      this.convertTokenFirstTime();
    } else if (value === 'DAI') {
      this.selectedToken = '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063';
      this.selectDisabled = true;
      this.convertTokenFirstTime();
    } else if (value === 'FRAX') {
      this.selectedToken = '0x45c32fA6DF82ead1e2EF74d17b76547EDdFaFF89';
      this.selectDisabled = true;
      this.convertTokenFirstTime();
    }
  }
  confirmBuyHT() {
    this.loadSwapEuroToHT = true;
    const data = {
      amount: this.swapEuroToTokenForm.get('euro')?.value,
    };
    this.web3Service.w3SwapEuroToHT(data).subscribe(
      () => {
        this.homeService
          .getProfile(localStorage.getItem('token'))
          .subscribe((res) => {
            this.homeService.profile = res;
            const w3Addresses = res.w3Addresses.filter(
              (address: any) => address.isMain === true
            );
            this.w3Address = w3Addresses[0].address;
            this.connectionW3 = this.isConnected(this.w3Address);
            this.wallet = res.wallet;
            this.balance = res.wallet.balance;
            this.loadSwapEuroToHT = false;
            this.getHTBalance();
            this.getListTransfer();
            this.swapEuroToTokenForm.get('HT').setValue('0.00');
            this._notifierService.showNotificationSuccess('Swap completato');
          },
            ()=>{
              localStorage.removeItem('token')
              this.router.navigate(['/home'])
            });
      },
      () => {
        this.loadSwapEuroToHT = false;
        this._notifierService.showNotification('Errore nello swap');
      }
    );
  }
  async convertTokenFirstTime() {
    this.swapTokenToTokenForm.get('amount')?.setValue(0)
    this.swapTokenToTokenForm.get('HT')?.setValue(0)
    this.valuePrice = 0;
    const amount = 1;
    const provider = new ethers.providers.JsonRpcProvider(environment.RPC_MAINNET);
    await getBestQuoteSwapEncodedAuto(provider, this.selectedToken, '0xc2132D05D31c914a87C6611C10748AEb04B58e8F', Number(amount)).then(
      async (res: any) => {
        this.quote = res;
        const decimalsUSDT = await this._contractService.readContract('0xc2132D05D31c914a87C6611C10748AEb04B58e8F', environment.RPC, tokenHTABI, 'decimals', [])
        if(this.valueSelected !== 'MATIC'){
          let decimals = await this._contractService.readContract(this.selectedToken, environment.RPC, tokenHTABI, 'decimals', [])
          let myBalance = await this._contractService.readContract(this.selectedToken, environment.RPC, tokenHTABI, 'balanceOf', [this.w3Address])
          if(+myBalance >0 ){
            this.myBalance = +myBalance / 10 ** Number(decimals);
          } else {
            this.myBalance = 0;
          }
        } else {
          const provider = new ethers.providers.JsonRpcProvider(environment.RPC);
          const ethBalancePromise = provider.getBalance(this.w3Address);
          Promise.all([ethBalancePromise])
            .then(([myBalance]) => {
              if(+myBalance >0 ){
                this.myBalance = +myBalance / 10 ** 18;
              } else {
                this.myBalance = 0;
              }
            })
            .catch((error) => {
              console.error(error);
            });
        }
        const amountToken = Number(this.swapTokenToTokenForm.get('amount')?.value);
        this.valuePrice = ((+res.rawQuote / (1)) / (10 ** decimalsUSDT) / this._priceService.USDPrice)
        if(amountToken > 0){
          this.swapTokenToTokenForm.get('HT')?.setValue(((Number(amountToken > 0 ? amountToken : 1) * this.valuePrice) / this._priceService.USDPrice).toFixed(2))
        } else {
          this.swapTokenToTokenForm.get('HT')?.setValue(0)
        }
        this.selectDisabled = false;
      }
    )
  }

  async confirmSwapTKBuyHT() {
    if (!this.getGlobalVariables().wallet.address) {
      this._notifierService.showNotification(
        'Connetti un wallet per poter acquistare in HT'
      );
      return;
    } else if (
      this.getGlobalVariables().wallet.address.toLowerCase() !==
      this.w3Address.toLowerCase()
    ) {
      this._notifierService.showNotification(
        'Il wallet collegato è diverso da quello collegato alla tua utenza'
      );
      return;
    }
    this.loadSwapTKToHT = true;
    const amount = this.swapTokenToTokenForm.get('amount').value;
    let token = String(Number(this.swapTokenToTokenForm.get('HT').value) - (Number(this.swapTokenToTokenForm.get('HT').value) * (this.slippage/100)))
    if(+this.primary_network.chainId === 9684){
      token = '0'
    }
    const allowance = await this._contractService.writeContract(
      this.selectedToken,
      erc20,
      'allowance',
      [this.w3Address, environment.SWAP_HT]
    );
    if (this.quote.versionProtocol === 'V3') {
      if (this.valueSelected === 'MATIC') {
        const decimals = await this._contractService.readContract(
          this.selectedToken,
          environment.RPC,
          tokenHTABI,
          'decimals',
          []
        );
        this.swapWMATIC(this.quote, amount, decimals,token);
      } else {
        const decimals = await this._contractService.readContract(
          this.selectedToken,
          environment.RPC,
          tokenHTABI,
          'decimals',
          []
        );
        if (+allowance / 10 ** decimals > amount) {
          this.swapWBTC(this.quote, amount, decimals,token);
        } else {
          const approve = BigNumber.from(
            String(Number(amount) * 10 ** decimals)
          );
          this._contractService
            .writeContract(this.selectedToken, erc20, 'approve', [
              environment.SWAP_HT,
              approve,
            ])
            .then(async (respWBTC) => {
              const status = await respWBTC.wait();
              if (status) {
                this.swapWBTC(this.quote, amount, decimals,token);
              }
            })
            .catch(() => {
              this.loadSwapTKToHT = false;
              this._notifierService.showNotification("Errore nell'approve");
              setTimeout(() => {
                this.statusSwap = '';
              }, 3000);
            });
        }
      }
    } else {
      if (this.valueSelected === 'MATIC') {
        const decimals = await this._contractService.readContract(
          this.selectedToken,
          environment.RPC,
          tokenHTABI,
          'decimals',
          []
        );
        this.swapV2WMATIC(this.quote, amount, decimals,token);
      } else {
        const decimals = await this._contractService.readContract(
          this.selectedToken,
          environment.RPC,
          tokenHTABI,
          'decimals',
          []
        );
        if (+allowance / 10 ** decimals > amount) {
          this.swapV2WBTC(this.quote, amount, decimals,token);
        } else {
          const approve = BigNumber.from(
            String(Number(amount) * 10 ** decimals)
          );
          this._contractService
            .writeContract(this.selectedToken, erc20, 'approve', [
              environment.SWAP_HT,
              approve,
            ])
            .then(async (respWBTC) => {
              const status = await respWBTC.wait();
              if (status) {
                this.swapV2WBTC(this.quote, amount, decimals,token);
              }
            })
            .catch(() => {
              this.loadSwapTKToHT = false;
              this._notifierService.showNotification("Errore nell'approve");
              setTimeout(() => {
                this.statusSwap = '';
              }, 3000);
            });
        }
      }
    }
  }

  swapWMATIC(res: any, amount: any, decimals: any, token: any) {
    if (!this.getGlobalVariables().wallet.address) {
      this._notifierService.showNotification(
        'Connetti un wallet per poter acquistare in HT'
      );
      return;
    } else if (
      this.getGlobalVariables().wallet.address.toLowerCase() !==
      this.w3Address.toLowerCase()
    ) {
      this._notifierService.showNotification(
        'Il wallet collegato è diverso da quello collegato alla tua utenza'
      );
      return;
    }
    this._contractService
      .writeContract(environment.SWAP_HT, swapHTABI, 'buyHausesTokenV3', [
        this.w3Address,
        environment.UNISWAPV3,
        ethers.constants.AddressZero,
        '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
        res.pathEncoded,
        ethers.utils.parseUnits(amount, decimals),
        ethers.utils.parseUnits(token,18),
        { value: ethers.utils.parseUnits(amount, decimals) },
      ])
      .then(async (res) => {
        const status = await res.wait();
        for (const event of status.events) {
          if (event.event == 'BuyToken') {
            this._notifierService.showNotificationSuccess('Swap completato');
            this.loadSwapTKToHT = false;
            this.getListTransfer();
          }
        }
      })
      .catch(() => {
        this.loadSwapTKToHT = false;
        this._notifierService.showNotification('Errore nello swap');
        setTimeout(() => {
        }, 3000);
      });
  }

  swapWBTC(res: any, amount: any, decimals: any, token: any) {
    if (!this.getGlobalVariables().wallet.address) {
      this._notifierService.showNotification(
        'Connetti un wallet per poter acquistare in HT'
      );
      return;
    } else if (
      this.getGlobalVariables().wallet.address.toLowerCase() !==
      this.w3Address.toLowerCase()
    ) {
      this._notifierService.showNotification(
        'Il wallet collegato è diverso da quello collegato alla tua utenza'
      );
      return;
    }
    this._contractService
      .writeContract(environment.SWAP_HT, swapHTABI, 'buyHausesTokenV3', [
        this.w3Address,
        environment.UNISWAPV3,
        this.selectedToken,
        '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
        res.pathEncoded,
        ethers.utils.parseUnits(amount, decimals),
        ethers.utils.parseUnits(token),
      ])
      .then(async (res) => {
        const status = await res.wait();
        for (const event of status.events) {
          if (event.event == 'BuyToken') {
            this._notifierService.showNotificationSuccess('Swap completato');
            this.loadSwapTKToHT = false;
            this.getListTransfer();
          }
        }
      })
      .catch(() => {
        this.loadSwapTKToHT = false;
        this._notifierService.showNotification('Errore nello swap');
        setTimeout(() => {
          this.statusSwap = '';
        }, 3000);
      });
  }

  swapV2WMATIC(res: any, amount: any, decimals: any, token: any) {
    if (!this.getGlobalVariables().wallet.address) {
      this._notifierService.showNotification(
        'Connetti un wallet per poter acquistare in HT'
      );
      return;
    } else if (
      this.getGlobalVariables().wallet.address.toLowerCase() !==
      this.w3Address.toLowerCase()
    ) {
      this._notifierService.showNotification(
        'Il wallet collegato è diverso da quello collegato alla tua utenza'
      );
      return;
    }
    this._contractService
      .writeContract(environment.SWAP_HT, swapHTABI, 'buyHausesTokenV2', [
        this.w3Address,
        environment.UNISWAPV2,
        res.coinPath,
        ethers.utils.parseUnits(amount, decimals),
        ethers.utils.parseUnits(token,18),
        { value: ethers.utils.parseUnits(amount, decimals) },
      ])
      .then(async (res) => {
        const status = await res.wait();
        for (const event of status.events) {
          if (event.event == 'BuyToken') {
            this._notifierService.showNotificationSuccess('Swap completato');
            this.loadSwapTKToHT = false;
            this.getListTransfer();
          }
        }
      })
      .catch(() => {
        this.loadSwapTKToHT = false;
        this._notifierService.showNotification('Errore nello swap');
        setTimeout(() => {
          this.statusSwap = '';
        }, 3000);
      });
  }

  swapV2WBTC(res: any, amount: any, decimals: any, token: any) {
    if (!this.getGlobalVariables().wallet.address) {
      this._notifierService.showNotification(
        'Connetti un wallet per poter acquistare in HT'
      );
      return;
    } else if (
      this.getGlobalVariables().wallet.address.toLowerCase() !==
      this.w3Address.toLowerCase()
    ) {
      this._notifierService.showNotification(
        'Il wallet collegato è diverso da quello collegato alla tua utenza'
      );
      return;
    }
    //todo to test it
    this._contractService
      .writeContract(environment.SWAP_HT, swapHTABI, 'buyHausesTokenV2', [
        this.w3Address,
        environment.UNISWAPV2,
        res.coinPath,
        ethers.utils.parseUnits(amount, decimals),
        ethers.utils.parseUnits(token,18),
        { value: ethers.utils.parseUnits(amount, decimals) },
      ])
      .then(async (res) => {
        const status = await res.wait();
        for (const event of status.events) {
          if (event.event == 'BuyToken') {
            this._notifierService.showNotificationSuccess('Swap completato');
            this.loadSwapTKToHT = false;
            this.getListTransfer();
          }
        }
      })
      .catch(() => {
        this.loadSwapTKToHT = false;
        this._notifierService.showNotification('Errore nello swap');
        setTimeout(() => {
          this.statusSwap = '';
        }, 3000);
      });
  }

  confirmSellHT() {
    this.loadSwapHTToEuro = true;
    const data = {
      amount: this.swapTokenToEuroForm.get('HT').value,
    };
    this.web3Service.w3SwapHTToEuro(data).subscribe(
      () => {
        this.homeService
          .getProfile(localStorage.getItem('token'))
          .subscribe((res) => {
            this.homeService.profile = res;
            const w3Addresses = res.w3Addresses.filter(
              (address: any) => address.isMain === true
            );
            this.w3Address = w3Addresses[0].address;
            this.connectionW3 = this.isConnected(this.w3Address);
            this.wallet = res.wallet;
            this.balance = res.wallet.balance;
            this.loadSwapHTToEuro = false;
            this.getHTBalance();
            this.getListTransfer();
            this.swapTokenToEuroForm.get('HT').setValue('0.00');
            this._notifierService.showNotificationSuccess('Swap completato');
          },
            ()=>{
              localStorage.removeItem('token')
              this.router.navigate(['/home'])
            });
      },
      () => {
        this.loadSwapHTToEuro = false;
        this._notifierService.showNotification('Errore nello swap');
      }
    );
  }

  openTransaction(hash: any) {
    window.open('https://polygonscan.com/tx/' + hash);
  }
  setHT(e: Event) {
    if(String(e).includes(',')){
      const amount = e.toString().replace(',', '.')
      this.swapTokenToTokenForm.get('amount').setValue(amount);
    }
    this.swapTokenToTokenForm.get('HT').setValue((Number(e) * this.valuePrice) * this._priceService.USDPrice);
  }
  setEuroToHT(e: Event) {
    this.swapEuroToTokenForm
      .get('HT').setValue(String((Number(e) * this._priceService.USDPrice).toFixed(2)));
  }
  setMaxEur() {
    this.swapEuroToTokenForm.get('euro').setValue(this.balance);
    this.swapEuroToTokenForm
      .get('HT').setValue(
        String((Number(this.balance) * this._priceService.USDPrice).toFixed(2))
      );
  }

  setMaxToken(){
    this.swapTokenToTokenForm.get('amount').setValue(this.myBalance);
    this.swapTokenToTokenForm.get('HT')?.setValue(((Number(this.myBalance > 0 ? this.myBalance : 1) * this.valuePrice) / this._priceService.USDPrice).toFixed(2))
  }
  setEuro(e: Event) {
    this.swapTokenToEuroForm
      .get('euro').setValue(String((Number(e) / this._priceService.USDPrice).toFixed(2)));
  }

  setMaxHT() {
    this.swapTokenToEuroForm.get('HT').setValue(this.HTBalance);
    this.swapTokenToEuroForm
      .get('euro').setValue(
        String(
          (Number(this.HTBalance) / this._priceService.USDPrice).toFixed(2)
        )
      );
  }

  getSwapToken() {
    this.web3Service.getSwapToken(this.w3Address).subscribe(
      () => {},
      () => {}
    );
  }
  changeValue(event) {
    this.slippage = event.value;
  }
  async isConnected(address: string) {
    if (
      address &&
      (await this.getGlobalVariables().wallet.address) === address.toLowerCase()
    ) {
      return true;
    } else if (
      (address &&
        (await this.getGlobalVariables().wallet.address) ===
          address.toLowerCase()) === undefined
    ) {
      return null;
    } else {
      return false;
    }
  }

  getGlobalVariables(): GlobalVariables {
    return this._walletService.getGlobalVariables();
  }

  async getListTransfer(): Promise<void> {
    this.loadTransfer = true;
    let transferList: any[] = [];
    const provider = new ethers.providers.JsonRpcProvider(environment.RPC);
    const abi = ["event Transfer(address indexed src, address indexed dst, uint val)"];
    let contract = new ethers.Contract(environment.TOKEN_HT, abi, provider);
    let transferIn :any;
    let transferOut :any;
    const promise1 = await Promise.all([contract.filters['Transfer'](null,  this.w3Address, null),contract.filters['Transfer'](this.w3Address, null, null)])
    const promise = await Promise.all([contract.queryFilter(promise1[0],-2000),contract.queryFilter(promise1[1],-2000)])
    transferIn = promise[0]
    transferOut = promise[1]
    if (transferIn && transferIn.length > 0) {
      let arrayList = []
      for (let i = 0; i < transferIn.length; i++) {
        arrayList.push(provider.getBlock(transferIn[i].blockNumber))
      }
      const result = await Promise.all(arrayList)
      for (let i = 0; i < transferIn.length; i++) {
        transferList.push({
          from: transferIn[i].args?.[0],
          to: transferIn[i].args?.[1],
          amount: ethers.utils.formatUnits(
            transferIn[i].args?.[2],
            this.decimals
          ),
          hash: transferIn[i].transactionHash,
          blockNumber: transferIn[i].blockNumber,
          date: new Date(result[i].timestamp * 1000),
          type: 'in',
        });
        transferList = transferList.sort(function (
          a: { date: number },
          b: { date: number }
        ) {
          return b.date - a.date;
        });
      }
    }
    if (transferOut && transferOut.length > 0) {
      let arrayList1 = []
      for (let i = 0; i < transferOut.length; i++) {
        arrayList1.push(provider.getBlock(transferOut[i].blockNumber))
      }
      const result1 = await Promise.all(arrayList1)
      for (let i = 0; i < transferOut.length; i++) {
        transferList.push({
          from: transferOut[i].args?.[0],
          to: transferOut[i].args?.[1],
          amount: ethers.utils.formatUnits(
            transferOut[i].args?.[2],
            this.decimals
          ),
          hash: transferOut[i].transactionHash,
          blockNumber: transferOut[i].blockNumber,
          date: new Date(result1[i].timestamp * 1000),
          type: 'out',
        });
        transferList = transferList.sort(function (
          a: { date: number },
          b: { date: number }
        ) {
          return b.date - a.date;
        });
      }
    }
    this.listTransfer = transferList.sort(function (
      a: { date: number },
      b: { date: number }
    ) {
      return b.date - a.date;
    });
    this.loadTransfer = false;
  }
  infoSwap() {
    this.dialog.open(DialogInfoSwapComponent, {
      width: '90%',
      maxWidth: '700px',
    });
  }
}
