import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { NotifierService } from '../../../../../../../core/service/notifier.service';
import { BuildRenovateService } from '../../../../../../../core/service/buildRenovate.service';
@Component({
  selector: 'app-dialog-reserved-content',
  templateUrl: './dialog-info-swap.component.html',
  styleUrls: ['./dialog-info-swap.component.scss'],
})
export class DialogInfoSwapComponent implements OnInit {
  info: any;

  constructor(
    public dialogRef: MatDialogRef<DialogInfoSwapComponent>,
    public dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private buildRenovateService: BuildRenovateService,
    private _notifierService: NotifierService,
    @Inject(MAT_DIALOG_DATA) private data: DialogInfoSwapComponent
  ) {}

  ngOnInit(): void {}
  confirmChange() {
    this.dialogRef.close({ data: 'conferma' });
  }
}
