import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { FilterService } from '../../core/service/filter.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-sidenav-right',
  templateUrl: './sidenav-right.component.html',
  styleUrls: ['./sidenav-right.component.scss'],
})
export class SidenavRightComponent implements OnInit, DoCheck {
  searchForm: FormGroup = new FormGroup({});
  @Input() drawerRight: any;
  filter1: boolean = false;
  filter2: boolean = false;
  filter3: boolean = false;
  filter4: boolean = false;
  filter5: boolean = false;
  path: string | undefined;
  token: any;
  filterType: string | undefined;
  filterType2: string | undefined;
  filterType3: string | undefined;
  filterType4: string | undefined;
  filterType5: string | undefined;
  objectForFilter: any;
  arrayToFilter: any;
  arrayFiltered: any;

  constructor(
    private _formBuilder: FormBuilder,
    private _location: Location,
    private router: Router,
    private filterService: FilterService
  ) {}

  ngOnInit(): void {
    this.objectForFilter = {
      Dimensione: { value1: '', value2: '' },
      Prezzo: { value1: '', value2: '' },
      Piano: { value1: '', value2: '' },
      Tipologia: { value1: '' },
      Luogo: { value1: '' },
    };
    this.searchForm = this._formBuilder.group({
      search: [null],
    });
    this.filterService.setDrawer(this.drawerRight);
  }

  ngDoCheck(): void {
    this.arrayToFilter = this.filterService.getFilter();
    this.token = localStorage.getItem('token');
    const path = this.router.url.split('/');
    this.path = path[1];
  }

  openFilter1() {
    this.filter1 = !this.filter1;
    this.filter2 = false;
    this.filter3 = false;
    this.filter4 = false;
    this.filter5 = false;
  }

  openFilter2() {
    this.filter1 = false;
    this.filter2 = !this.filter2;
    this.filter3 = false;
    this.filter4 = false;
    this.filter5 = false;
  }

  openFilter3() {
    this.filter1 = false;
    this.filter2 = false;
    this.filter3 = !this.filter3;
    this.filter4 = false;
    this.filter5 = false;
  }

  openFilter4() {
    this.filter1 = false;
    this.filter2 = false;
    this.filter3 = false;
    this.filter4 = !this.filter4;
    this.filter5 = false;
  }

  openFilter5() {
    this.filter1 = false;
    this.filter2 = false;
    this.filter3 = false;
    this.filter4 = false;
    this.filter5 = !this.filter5;
  }

  setFilter(type: string, filter1: string, filter2: string) {
    this.filterType = type;
    if (this.objectForFilter.Dimensione) {
      this.objectForFilter.Dimensione.value1 = filter1;
      this.objectForFilter.Dimensione.value2 = filter2;
    }
  }
  setFilter2(type: string, filter1: string, filter2: string) {
    this.filterType2 = type;
    if (this.objectForFilter.Prezzo) {
      this.objectForFilter.Prezzo.value1 = filter1;
      this.objectForFilter.Prezzo.value2 = filter2;
    }
  }
  setFilter3(type: string, filter1: string, filter2: string) {
    this.filterType3 = type;
    if (this.objectForFilter.Piano) {
      this.objectForFilter.Piano.value1 = filter1;
      this.objectForFilter.Piano.value2 = filter2;
    }
  }
  setFilter4(type: string, filter1: string) {
    this.filterType4 = type;
    if (this.objectForFilter.Tipologia) {
      this.objectForFilter.Tipologia.value1 = filter1;
    }
  }

  confirmFilter() {
    let filterArray = this.arrayToFilter.apartments.filter(
      (b: any) => b.isActive === true && b.isSold === false
    );
    if (this.objectForFilter) {
      if (this.objectForFilter.Dimensione.value1 !== '') {
        const val1 = this.objectForFilter.Dimensione.value1;
        const val2 = this.objectForFilter.Dimensione.value2;
        filterArray = filterArray.filter(
          (v: any) => v.m2 > val1 && v.m2 <= val2
        );
      }
      if (this.objectForFilter.Prezzo.value1 !== '') {
        const val1 = this.objectForFilter.Prezzo.value1;
        const val2 = this.objectForFilter.Prezzo.value2;
        filterArray = filterArray.filter(
          (v: any) => v.price > val1 && v.price <= val2
        );
      }
      if (this.objectForFilter.Piano.value1 !== '') {
        const val1 = this.objectForFilter.Piano.value1;
        const val2 = this.objectForFilter.Piano.value2;
        filterArray = filterArray.filter(
          (v: any) => v.floor > val1 && v.floor <= val2
        );
      }
      if (this.objectForFilter.Tipologia.value1 !== '') {
        const val1 = this.objectForFilter.Tipologia.value1;
        filterArray = filterArray.filter((v: any) => v.type === val1);
      }
      if (this.searchForm.get('search').value) {
        const val1 = this.searchForm.get('search').value;
        filterArray = filterArray.filter((v: any) =>
          v.name.toLocaleLowerCase().includes(val1.toLocaleLowerCase())
        );
      }
      this.arrayFiltered = filterArray;
      this.filterService.setArrayFiltered(this.arrayFiltered);
    }
  }
  Reset() {
    this.objectForFilter = {
      Dimensione: { value1: '', value2: '' },
      Prezzo: { value1: '', value2: '' },
      Piano: { value1: '', value2: '' },
      Tipologia: { value1: '' },
      Luogo: { value1: '' },
    };
    this.filter1 = false;
    this.filter2 = false;
    this.filter3 = false;
    this.filter4 = false;
    this.filter5 = false;
    this.filterType = '';
    this.filterType2 = '';
    this.filterType3 = '';
    this.filterType5 = '';
    this.filterType4 = '';
    this.arrayToFilter = [];
    this.arrayFiltered = [];
    this.searchForm.reset();
    this.arrayFiltered = this.arrayToFilter.apartments;
    this.filterService.setArrayFiltered(this.arrayFiltered);
  }
  closeSidenav() {
    this.objectForFilter = {
      Dimensione: { value1: '', value2: '' },
      Prezzo: { value1: '', value2: '' },
      Piano: { value1: '', value2: '' },
      Tipologia: { value1: '' },
      Luogo: { value1: '' },
    };
    this.filter1 = false;
    this.filter2 = false;
    this.filter3 = false;
    this.filter4 = false;
    this.filter5 = false;
    this.filterType = '';
    this.filterType2 = '';
    this.filterType3 = '';
    this.filterType5 = '';
    this.filterType4 = '';
    this.arrayToFilter = [];
    this.arrayFiltered = [];
    this.searchForm.reset();
    this.drawerRight.close();
  }
}
