import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import {HomeService} from "../../../core/service/home.service";
import {environment} from "../../../../environments/environment";
import {ContractService} from "@scalingparrots/dapp-angular-lib";
import {AdminService} from "../../../core/service/admin.service";
const tokenHTABI = require('src/app/core/abi/tokenABI.json');
import {BuildRenovateService} from "../../../core/service/buildRenovate.service";
import {
  DialogViewMessageComponent
} from "../../../children/main/children/home/component/dialog/dialog-view-message/dialog-view-message.component";
import {Router} from "@angular/router";
@Component({
  selector: 'app-dialog-reserved-content',
  templateUrl: './dialog-dashboard-contractor.component.html',
  styleUrls: ['./dialog-dashboard-contractor.component.scss'],
})
export class DialogDashboardContractorComponent implements OnInit {
  innerWidth:any;
  profile:any;
  decimals:any;
  w3Address:any;
  HTBalance:any;
  myNFT:any[]=[];
  walletEuro:any;
  walletCrypto:any;
  loadLatestInvestment:boolean=false;
  loadWalletCrypto:boolean=false;
  loadMyProposal:boolean=false;
  deposit:any;
  investments:any;
  proposal:any;
  practicesLength:any;
  proposalLength:any;
  proposalVerified:any;
  labelUser:any;
  user:any;
  round:any;
  updates:any;
  waitingWork: any[] = [];
  confirmedWork: any[] = [];
  allContractLink: any[] = [];
  constructor(
    public dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private _homeService: HomeService,
    private _adminService: AdminService,
    private _contractService: ContractService,
    private _buildRenovateService: BuildRenovateService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) private data: DialogDashboardContractorComponent
  ) {}

  ngOnInit(): void {
    this.loadWalletCrypto = true;
    this.loadLatestInvestment = true;
    this.loadMyProposal = true;
    this.innerWidth = window.innerWidth
    this.profile = this._homeService.profile
    this.deposit = this._homeService.profile.historyBuy.filter((data)=> !data.isRevoke)
    this.practicesLength = this._homeService.profile.historyBuildRenovate;
    this.proposalLength = this._homeService.profile.historyObjBuildRenovate;
    this.proposalVerified = this._homeService.profile.historyObjBuildRenovate.filter((data)=> data.status === 'verifiedSignatures')
    this.user = this._homeService.profile;
    const role = this._homeService.getRole(localStorage.getItem('token'));
    this.getRoleLabel(role[0].type);
    if(this._homeService.profile.w3Addresses.length >0){
      const w3Addresses = this._homeService.profile.w3Addresses.filter(
        (address: any) => address.isMain === true
      );
      this.w3Address = w3Addresses[0].address;
      this.getWalletCrypto(w3Addresses[0].address)
      this.getLatestProposal()
      this.getWalletEuro()
    } else {
      this.getWalletCrypto('')
      this.getLatestProposal()
      this.getWalletEuro()
    }
  }

  getWalletEuro(){
    let sumInvestmentEuro = 0;
    let historyInvestmentEuro = 0;
    let historyBuyEuro = 0;
    let historyBuildEuro = 0;
    const channelsBuild = []
    if(this._homeService.profile.historyBuildRenovate.length > 0){
      for(let i = 0; i < this._homeService.profile.historyBuildRenovate.length; i ++){
        channelsBuild.push(...this._homeService.profile.historyBuildRenovate[i].channels)
      }
    }
    if(channelsBuild){
      const historyBuildE = channelsBuild.filter((data)=>data.paymentType ==='W2')
      for(let i = 0; i < historyBuildE.length; i ++){
        for(let j = 0; j < historyBuildE[i].tranches.length; j ++){
          historyBuildEuro = historyBuildEuro + Number(historyBuildE[i].tranches[j].amount / 100)
        }
      }
    }
   let build = 0;
    sumInvestmentEuro = historyInvestmentEuro + historyBuyEuro + historyBuildEuro
    if(historyBuildEuro > 0){
      build = Number((historyBuildEuro * 100 / sumInvestmentEuro).toFixed(2))
    }
    this.walletEuro = {
      balance : this._homeService.profile.wallet.balance,
      balanceUsed : Number((sumInvestmentEuro).toFixed(2)),
      build : build,
    }
  }

  async getWalletCrypto(w3Address:any) {
    if (this._homeService.profile.w3Addresses && this._homeService.profile.w3Addresses.length > 0) {
      if (w3Address) {
        this.decimals = await this._contractService.readContract(environment.TOKEN_HT, environment.RPC, tokenHTABI, 'decimals', []);
        this._contractService.readContract(environment.TOKEN_HT, environment.RPC, tokenHTABI, 'balanceOf', [this.w3Address])
          .then((res) => {
            this.HTBalance = +res._hex / 10 ** Number(this.decimals);
            const channelsBuild = []
            if(this._homeService.profile.historyBuildRenovate.length > 0){
              for(let i = 0; i < this._homeService.profile.historyBuildRenovate.length; i ++){
                channelsBuild.push(...this._homeService.profile.historyBuildRenovate[i].channels)
              }
            }
            let historyBuildW3 = 0;
            const historyBuildW = channelsBuild.filter((data)=>data.paymentType ==='W3')
            for(let i = 0; i < historyBuildW.length; i ++){
              for(let j = 0; j < historyBuildW[i].tranches.length; j ++){
                historyBuildW3 = historyBuildW3 + Number(historyBuildW[i].tranches[j].amount / 100)
              }
            }
            const build = historyBuildW3
            this.walletCrypto = {
              balance: this.HTBalance,
              balanceUsed: historyBuildW3,
              build: build,
            }
            const investments = this._homeService.profile.historyInvestment.filter((data)=> !data.isEarnDone && !data.isRevokeDone).length
            this.investments = investments + this.myNFT.length
            this.loadWalletCrypto = false;
          })
          .catch((error: any) => console.error('balanceOf', error.message));
      }
    } else {
      this.investments = this._homeService.profile.historyBuildRenovate.length
      this.loadWalletCrypto = false;
      this.walletCrypto = {
        balance: 0,
        balanceUsed: 0,
        investment: 0,
        buy: 0,
        build: 0,
      }
    }
  }

  async getLatestProposal() {
    if(this._homeService.profile.historyObjBuildRenovate && this._homeService.profile.historyObjBuildRenovate.length >0){
      const latestPractices = this._homeService.profile.historyObjBuildRenovate.sort(function (a: { buildRenovate: { dateCreation: number } }, b: { buildRenovate: { dateCreation: number } }) {
        return b.buildRenovate.dateCreation - a.buildRenovate.dateCreation;
      });
      this.getBuildRenovate(latestPractices[0].id)
    } else {
      this.proposal = null
      this.loadLatestInvestment = false;
    }
  }
  getBuildRenovate(id: any) {
    this._buildRenovateService.getObjBuildRenovate(id).subscribe(
      async (res) => {
        this.proposal = res;
        this.round = res.tranches;
        this.loadLatestInvestment = false;
      },
      () => {}
    );
  }

  openViewMessage(message: string) {
    this.dialog.open(DialogViewMessageComponent, {
      width: '90%',
      maxWidth: '500px',
      data: message,
    });
  }

  downloadAllContract() {
    for (let i = 0; i < this.allContractLink.length; i++) {
      setTimeout(
        function (path) {
          window.location = path;
        },
        200 + i * 200,
        this.allContractLink[i]
      );
    }
  }

  openUrl(url: string) {
    window.open(url);
  }
  downloadPDF(pdf: string) {
    window.open(pdf);
  }
  openSite(site: any) {
    window.open(site);
  }
  goToPage(path:string){
    this.router.navigate([path])
    this.dialog.closeAll()
  }
  getRoleLabel(role: string) {
    if (role === 'buildRenovateSupplier') {
      this.labelUser = 'Supplier';
    } else if (role === 'buildRenovateCompany') {
      this.labelUser = 'Company';
    }
  }
}
