<div fxLayout="row" fxLayoutAlign="space-between">
  <label class="title">Cruscotto</label>
  <img class="image_close" src="assets/image/dashboard/back.svg" alt="" (click)="dialog.closeAll()">
</div>
<div class="container">
  <mat-grid-list
    cols="12"
    rowHeight="90px"
    [gutterSize]="'10px'"
    class="container_grid">
    <mat-grid-tile [colspan]="this.innerWidth > 1000 ? 3 : 12" [rowspan]="2" class="card">
      <div class="container_card" fxLayout="column" fxLayoutAlign="flex-start" fxLayoutGap="10px">
        <div fxLayout="row" fxLayoutAlign="flex-start" fxLayoutGap="10px">
          <label class="title">Wallet</label>
          <img class="image_wallet" src="assets/image/dashboard/euro.svg" alt="">
        </div>
        <div class="container_data" fxLayout="column" fxLayoutAlign="flex-start" fxLayoutGap="20px">
          <div *ngIf="walletEuro" fxLayout="column" fxLayoutGap="5px">
            <label class="subtitle_gray">Bilancio</label>
            <label class="subtitle">€ {{this.walletEuro.balance | number: "1.2-2":"it" }}</label>
          </div>
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="this.innerWidth > 1000 ? 9 : 12" [rowspan]="6" class="card">
      <div class="container_card" fxLayout="column" fxLayoutAlign="flex-start" fxLayoutGap="20px">
        <label class="title">Ultima Offerta</label>
        <div *ngIf="!loadLatestInvestment && this.proposal" class="container_practices" fxLayout="column" fxLayoutAlign="flex-start" fxLayoutGap="20px">
          <mat-grid-list
            cols="12"
            rowHeight="90px"
            [gutterSize]="'10px'">
            <mat-grid-tile
              [colspan]="innerWidth > 1000 ? 12 : 12"
              [rowspan]="2"
              class="card_practices"
            >
              <div class="container_card_practices">
                <div [ngClass]="'container_header_card_details'"
                     fxLayout="row wrap"
                     fxLayoutAlign="flex-start"
                     fxLayoutGap="10px"
                >
                  <div fxLayout="column" fxLayoutGap="10px">
                    <img
                      class="image_technician"
                      [src]="this.proposal.channelTo.profileImage"
                      alt=""
                    />
                  </div>
                  <div fxLayout="column" fxLayoutGap="10px">
                    <label class="subtitle_desc_gray">Offerta da:</label>
                    <label class="subtitle_desc"
                    >{{ this.proposal.channelTo.firstName }}
                      {{ this.proposal.channelTo.lastName }}</label
                    >
                  </div>
                  <div fxLayout="column"  fxLayoutGap="10px">
                    <label class="subtitle_desc_gray">Stato:</label>
                    <label
                      *ngIf="this.proposal.status === 'waitUser'"
                      class="status-orange"
                    >Attesa Utente</label
                    >
                    <label
                      *ngIf="this.proposal.status === 'waitCompany'"
                      class="status-orange"
                    >Attesa Azienda</label
                    >
                    <label
                      *ngIf="this.proposal.status === 'rejected'"
                      class="status-red"
                      (click)="openViewMessage(this.proposal.channelCME.message)"
                    >Rifiutata</label
                    >
                    <label
                      *ngIf="this.proposal.status === 'accepted'"
                      class="status-green"
                      (click)="openViewMessage(this.proposal.channelCME.message)"
                    >Accettata</label
                    >
                    <label
                      *ngIf="this.proposal.status === 'onHoldSign'"
                      class="status-orange"
                    >Attesa firme</label
                    >
                    <label
                      *ngIf="this.proposal.status === 'signedUser'"
                      class="status-blue"
                    >Firmato dall'utente</label
                    >
                    <label
                      *ngIf="this.proposal.status === 'signedCompany'"
                      class="status-blue"
                    >Firmato dall'azienda</label
                    >
                    <label
                      *ngIf="this.proposal.status === 'signedBoth'"
                      class="status-blue"
                    >Firmato da entrambi</label
                    >
                    <label
                      *ngIf="this.proposal.status === 'verifiedSignatures'"
                      class="status-green"
                    >Contratto validato</label
                    >
                  </div>
                  <div fxLayout="column"  fxLayoutGap="10px">
                    <label class="subtitle_desc_gray">Scadenza offerta:</label>
                    <label class="subtitle_desc">{{
                      this.proposal.offerExpiration * 1000
                        | date: "dd/MM/YYYY":"":"it"
                        | titlecase
                      }}</label>
                  </div>
                  <div fxLayout="column"  fxLayoutGap="10px">
                    <label class="subtitle_desc_gray">Vedi proposta:</label>
                    <label
                      class="no_apartments_pointer"
                      fxLayout="row"
                      (click)="goToPage('/dashboard/build/'+(labelUser === 'Company' ? 'company' :'supplier') +'/proposal/' + this.proposal.id + '/channel')"
                      >{{ this.proposal.channelTo.id
                      }}<img
                        class="open_cme"
                        src="assets/image/dashboard/open-blue.svg"
                        alt=""
                      /></label>
                  </div>
                  <div
                    *ngIf="this.proposal.channelTo.company"
                    fxLayout="column"
                    fxLayoutGap="10px"
                  >
                    <label class="subtitle_desc_gray">Nome Azienda:</label>
                    <label class="subtitle_desc">{{
                      this.proposal.channelTo.company.companyName
                      }}</label>
                  </div>
                  <div
                    *ngIf="this.proposal.channelTo.company"
                    fxLayout="column"
                    fxLayoutGap="10px"
                  >
                    <label class="subtitle_desc_gray">Indirizzo:</label>
                    <label class="subtitle_desc">{{
                      this.proposal.channelTo.company.companyAddress
                      }}</label>
                  </div>
                  <div
                    *ngIf="this.proposal.channelTo.company"
                    fxLayout="column"
                    fxLayoutGap="10px"
                  >
                    <label class="subtitle_desc_gray">PIVA:</label>
                    <label class="subtitle_desc">{{
                      this.proposal.channelTo.company.companyPIVA
                      }}</label>
                  </div>
                  <div
                    *ngIf="this.proposal.channelTo.company"
                    fxLayout="column"
                    fxLayoutGap="10px"
                  >
                    <label class="subtitle_desc_gray">Numero:</label>
                    <label class="subtitle_desc">{{
                      this.proposal.channelTo.company.companyPhone
                      }}</label>
                  </div>
                  <div
                    *ngIf="this.proposal.channelTo.company"
                    fxLayout="column"
                    fxLayoutGap="10px"
                  >
                    <label class="subtitle_desc_gray">Sito:</label>
                    <img
                      class="open_cme"
                      src="assets/image/dashboard/open-blue.svg"
                      alt=""
                      (click)="openSite(this.proposal.channelTo.company.companySite)"
                    />
                  </div>
                  <div
                    *ngIf="this.proposal.channelTo.company"
                    fxLayout="column"
                    fxLayoutGap="10px"
                  >
                    <label class="subtitle_desc_gray">Email:</label>
                    <a
                      [href]="'mailto:' + this.proposal.channelTo.company.companyEmail"
                      target="_top"
                    ><mat-icon class="open">mail</mat-icon></a
                    >
                  </div>
                  <div fxLayout="column" fxLayoutGap="10px">
                    <label class="subtitle_desc_gray">Timestamp:</label>
                    <img
                      *ngIf="
                this.proposal.timestamps &&
                this.proposal.timestamps.length > 0 &&
                proposal.timestamps[proposal.timestamps.length - 1].status !==
                  'confirmed'
              "
                      [matTooltipClass]="{ 'tool-tip': true }"
                      [matTooltipPosition]="'above'"
                      matTooltip="{{
                proposal.timestamps[proposal.timestamps.length - 1].status
              }}"
                      class="open_cme"
                      src="assets/image/dashboard/certi_icon.svg"
                      (click)="
                openSite(
                  proposal.timestamps[proposal.timestamps.length - 1]
                    .explorerURL
                )
              "
                      alt=""
                    />
                    <img
                      *ngIf="
                this.proposal.timestamps &&
                this.proposal.timestamps.length > 0 &&
                proposal.timestamps[proposal.timestamps.length - 1].status ===
                  'confirmed'
              "
                      [matTooltipClass]="{ 'tool-tip': true }"
                      [matTooltipPosition]="'above'"
                      matTooltip="{{
                proposal.timestamps[proposal.timestamps.length - 1].timestamp *
                  1000
                  | date: 'dd/MM/YYYY HH:mm':'':'it'
                  | titlecase
              }}"
                      class="open_cme"
                      src="assets/image/dashboard/certi_icon.svg"
                      (click)="
                openSite(
                  proposal.timestamps[proposal.timestamps.length - 1]
                    .explorerURL
                )
              "
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </mat-grid-tile>
            <mat-grid-tile
              [colspan]="innerWidth > 1000 ? 4 : 12"
              [rowspan]="3"
              class="card_practices"
            >
              <div class="container_card_practices">
                <div
                  class="container_header_card"
                  fxLayout="row"
                  fxLayoutAlign="flex-start"
                  fxLayoutGap="10px"
                >
                  <label class="title_card">Contratti</label>
                  <img
                    [matTooltipClass]="{ 'tool-tip': true }"
                    [matTooltipPosition]="'above'"
                    matTooltip="Download Contratto"
                    class="download"
                    src="assets/image/dashboard/download.svg"
                    alt=""
                    (click)="downloadPDF(this.proposal.channelContract.contract)"
                  />
                </div>
                <div
                  *ngIf="
            this.proposal.status === 'waitUser' ||
            this.proposal.status === 'waitCompany' ||
            this.proposal.status === 'rejected'
          "
                  class="container_card_macrocategories"
                  fxLayout="column"
                  fxLayoutGap="15px"
                >
                  <label class="subtitle_desc_gray"
                  >Prima di passare alla parte di contrattualizzazione, l'offerta deve
                    essere accettata da entrambe le parti
                  </label>
                </div>
                <div
                  *ngIf="this.proposal.status === 'accepted'"
                  class="container_card_macrocategories"
                  fxLayout="column"
                  fxLayoutGap="15px"
                >
                  <label class="subtitle_desc_gray"
                  >L'offerta è stata accettata da entrambe le parti, il tecnico Hauses
                    sta preparando il contratto da firmare. Vi arriverà una notifica una
                    volta che il tecnico avrà completato l'operazione.</label
                  >
                </div>
                <div
                  *ngIf="this.proposal.status === 'onHoldSign'"
                  class="container_card_macrocategories"
                  fxLayout="column"
                  fxLayoutGap="15px"
                >
                  <label class="subtitle_desc_gray"
                  >Scarica il contratto dal bottone sopra e ricaricalo firmato</label
                  >
                </div>
                <div
                  *ngIf="
            (this.proposal.user.id === this.user.id &&
              this.proposal.status === 'signedCompany') ||
            (this.proposal.consultant &&
              this.proposal.consultant.id === this.user.id &&
              this.proposal.status === 'signedCompany')
          "
                  class="container_card_macrocategories"
                  fxLayout="column"
                  fxLayoutGap="15px"
                >
                  <label class="subtitle_desc_gray"
                  >Scarica il contratto dal bottone sopra e ricaricalo firmato</label
                  >
                </div>
                <div
                  *ngIf="
            this.proposal.channelTo.id === this.user.id &&
            this.proposal.status === 'signedUser'
          "
                  class="container_card_macrocategories"
                  fxLayout="column"
                  fxLayoutGap="15px"
                >
                <div
                  *ngIf="
            this.proposal.user.id === this.user.id &&
            this.proposal.status === 'signedUser'
          "
                  class="container_card_macrocategories"
                  fxLayout="column"
                  fxLayoutGap="15px"
                >
                  <label class="subtitle_desc_gray"
                  >Rimani in attesa che l'azienda firmi il contratto</label
                  >
                </div>
                <div
                  *ngIf="
            this.proposal.channelTo.id === this.user.id &&
            this.proposal.status === 'signedCompany'
          "
                  class="container_card_macrocategories"
                  fxLayout="column"
                  fxLayoutGap="15px"
                >
                  <label class="subtitle_desc_gray"
                  >Rimani in attesa che l'utente firmi il contratto</label
                  >
                </div>
                <div
                  *ngIf="this.proposal.status === 'signedBoth'"
                  class="container_card_macrocategories"
                  fxLayout="column"
                  fxLayoutGap="15px"
                >
                  <label class="subtitle_desc_gray"
                  >Il contratto è stato firmato da entrambe le parti. Verrete
                    notificati quando il tecnico Hauses avrà verificato la validità del
                    contratto e delle firme.</label
                  >
                </div>
                <div
                  *ngIf="this.proposal.status === 'verifiedSignatures'"
                  class="container_card_macrocategories"
                  fxLayout="column"
                  fxLayoutGap="15px"
                >
                  <label class="subtitle_desc_gray"
                  >Il contratto è stato validato e l'offerta verrà spostata
                    nell'elenco delle offerte contrattualizzate.</label
                  >
                </div>
              </div>
              </div>
            </mat-grid-tile>
            <mat-grid-tile
              [colspan]="innerWidth > 1000 ? 4 : 12"
              [rowspan]="3"
              class="card_practices">
              <div class="container_card_practices">
                <div
                  class="container_header_card"
                  fxLayout="row"
                  fxLayoutAlign="space-between"
                >
                  <label class="title_card">Macrocategorie trattate</label>
                </div>
                <div
                  *ngIf="
            this.proposal.channelMacroCategories &&
            this.proposal.channelMacroCategories.length > 0
          "
                  class="container_card_macrocategories"
                  fxLayout="column"
                  fxLayoutGap="15px"
                >
                  <div
                    *ngFor="
              let category of this.proposal.channelMacroCategories;
              let id = index
            "
                    fxLayout="row"
                    fxLayoutGap="20px"
                    class="container_card_macrocategories_details"
                    fxLayoutAlign="flex-start"
                  >
                    <div fxLayout="column" fxLayoutGap="5px">
                      <label class="subtitle_desc">{{
                        category.bigMacroCategory
                        }}</label>
                      <label class="subtitle_desc_gray">{{
                        category.macroCategory
                        }}</label>
                    </div>
                  </div>
                </div>
                <div
                  *ngIf="
            (this.proposal.channelMacroCategories &&
              this.proposal.channelMacroCategories.length <= 0) ||
            this.proposal.channelMacroCategories === null
          "
                  class="container_no_apartments"
                >
                  <label class="no_apartments">Non sono presenti Macrocategorie</label>
                </div>
              </div>
            </mat-grid-tile>
            <mat-grid-tile
              [colspan]="innerWidth > 1000 ? 4 : 12"
              [rowspan]="3"
              class="card_practices">
              <div class="container_card_practices">
                <div
                  class="container_header_card"
                  fxLayout="row"
                  fxLayoutAlign="space-between"
                >
                  <label class="title_card">Tranches di pagamento</label>
                </div>
                <div
                  *ngIf="round && round.length > 0"
                  class="container_card_macrocategories"
                  fxLayout="column"
                  fxLayoutGap="15px"
                >
                  <div
                    *ngFor="let tranche of this.round; let i = index"
                    fxLayout="row"
                    fxLayoutGap="20px"
                    class="container_card_macrocategories_details"
                    fxLayoutAlign="space-between"
                  >
                    <div fxLayout="column" fxLayoutGap="5px">
                      <label class="subtitle_desc">{{ tranche.title }}</label>
                      <label class="subtitle_desc_gray">{{
                        tranche.description.length > 30
                          ? tranche.description.slice(0, 30) + "..."
                          : tranche.description
                        }}</label>
                    </div>
                    <div fxLayout="column" fxLayoutGap="5px">
                      <div fxLayout="row" fxLayoutAlign="flex-start" fxLayoutGap="10px">
                        <label
                          *ngIf="this.proposal.paymentType === 'W3'"
                          class="label_header_table"
                        >{{ tranche.amount / 100 | number: "1.2-2":"it"
                          }}<img
                            src="assets/image/dashboard/HT.svg"
                            alt=""
                            class="icon_tranches_disabled"
                          />
                        </label>
                        <label
                          *ngIf="this.proposal.paymentType !== 'W3'"
                          class="label_header_table"
                        >{{ tranche.amount / 100 | number: "1.2-2":"it"
                          }}<img
                            src="assets/image/dashboard/euro.svg"
                            alt=""
                            class="icon_tranches_disabled"
                          /></label>
                    </div>
                  </div>
                </div>
                <div *ngIf="round && round.length <= 0" class="container_card_no_tranches">
                  <label class="no_apartments"
                  >Le tranches di pagamento verranno mostrate una volta che la
                    proposta verrà accettata</label
                  >
                </div>
              </div>
              </div>
            </mat-grid-tile>
          </mat-grid-list>
        </div>
        <div *ngIf="!loadLatestInvestment && this.proposal === null" class="container_spinner">
          <label class="subtitle_blue">Non hai proposte</label>
        </div>
        <div *ngIf="loadLatestInvestment" class="container_spinner" fxLayout="column" fxLayoutGap="10px">
          <mat-spinner [diameter]="20"></mat-spinner>
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="this.innerWidth > 1000 ? 3 : 12" [rowspan]="2" class="card">
      <div class="container_card" fxLayout="column" fxLayoutAlign="flex-start" fxLayoutGap="10px">
        <div fxLayout="row" fxLayoutAlign="flex-start" fxLayoutGap="10px">
          <label class="title">Wallet</label>
          <img class="image_wallet" src="assets/image/dashboard/HT.svg" alt="">
        </div>
        <div class="container_data" fxLayout="column" fxLayoutAlign="flex-start" fxLayoutGap="20px">
          <div *ngIf="!loadWalletCrypto" fxLayout="column" fxLayoutGap="5px">
            <label class="subtitle_gray">Bilancio</label>
            <label class="subtitle">HT {{this.walletCrypto.balance | number: "1.2-2":"it" }}</label>
          </div>
          <div *ngIf="loadWalletCrypto" class="container_spinner" fxLayout="column" fxLayoutGap="10px">
            <mat-spinner [diameter]="20"></mat-spinner>
          </div>
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="this.innerWidth > 1000 ? 3 : 12" [rowspan]="1" class="card">
      <div class="container_card" fxLayout="column" fxLayoutAlign="flex-start" fxLayoutGap="5px">
        <label class="title">Offerte</label>
        <div fxLayout="row" fxLayoutAlign="space-between">
          <label class="number" >{{this.proposalLength ? this.proposalLength.length : 0}}</label>
          <img class="goToComplexDetails" src="assets/image/dashboard/arrow-open-complex.svg" alt="" (click)="goToPage('/dashboard/build/'+(labelUser === 'Company' ? 'company' :'supplier') +'/proposal')">
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile [colspan]="this.innerWidth > 1000 ? 3 : 12" [rowspan]="1" class="card">
      <div class="container_card" fxLayout="column" fxLayoutAlign="flex-start" fxLayoutGap="5px">
        <label class="title">Contratti firmati</label>
        <div fxLayout="row" fxLayoutAlign="space-between">
          <label class="number" >{{this.proposalVerified ? this.proposalVerified.length : 0}}</label>
        </div>
      </div>
    </mat-grid-tile>
  </mat-grid-list>

</div>
