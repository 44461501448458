import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { HomeService } from '../../core/service/home.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import {
  ChainId,
  GlobalVariables,
  NETWORK_INFO,
  NetworkService,
  WalletService,
} from '@scalingparrots/dapp-angular-lib';
import { Clipboard } from '@angular/cdk/clipboard';
import { NotifierService } from '../../core/service/notifier.service';
import { environment } from '../../../environments/environment';
import { PRIMARY_NETWORK } from '../card-connect-wallet/card-connect-wallet.component';
import {DialogDashboardComponent} from "../dashboard/dialog-dashboard-user/dialog-dashboard.component";
import {MatDialog} from "@angular/material/dialog";
import {DialogDashboardACComponent} from "../dashboard/dialog-dashboard-ac/dialog-dashboard-a-c.component";
import {
  DialogDashboardCompanyComponent
} from "../dashboard/dialog-dashboard-company/dialog-dashboard-company.component";
import {
  DialogDashboardContractorComponent
} from "../dashboard/dialog-dashboard-contractor/dialog-dashboard-contractor.component";
import {DialogDashboardAdminComponent} from "../dashboard/dialog-dashboard-admin/dialog-dashboard-admin.component";
import {
  DialogDashboardConsultantComponent
} from "../dashboard/dialog-dashboard-consultant/dialog-dashboard-consultant.component";
import {
  DialogDashboardTechnicianComponent
} from "../dashboard/dialog-dashboard-technician/dialog-dashboard-technician.component";
@Component({
  selector: 'app-sidenav-dashboard',
  templateUrl: './sidenav-dashboard.component.html',
  styleUrls: ['./sidenav-dashboard.component.scss'],
})
export class SidenavDashboardComponent implements OnInit, DoCheck {
  @Input() typeRouter: any;
  @Input() drawer: any;
  isMobile: boolean = false;
  selectForm: FormGroup = new FormGroup({});
  typeUser = '';
  labelUser = '';
  page = '';
  sidenav: boolean = true;
  image: any;
  name: any;
  isOpen: any;
  option: any;
  innerWidth: any;
  type: any;
  routing: any;
  win: any;
  primary_network =
    environment.primary_network === 'POLYGON'
      ? NETWORK_INFO[ChainId.Polygon]
      : PRIMARY_NETWORK;
  routerLink = 'home';
  balance: any;
  w3Address: string = '';
  newAddress: string = '';
  walletConnected: any;
  constructor(
    private _location: Location,
    private router: Router,
    private homeService: HomeService,
    private _formBuilder: FormBuilder,
    private _walletService: WalletService,
    private clipboard: Clipboard,
    private notifierService: NotifierService,
    private _networkService: NetworkService,
    private dialog: MatDialog
  ) {
    this.win = window as any;
    // check account
    setInterval(() => {
      this.getProvider();
    }, 500);
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.image = this.homeService.profile.profileImage;
    this.name =
      this.homeService.profile.firstName +
      ' ' +
      this.homeService.profile.lastName;
    setInterval(() => {
      if (
        this.homeService.profile &&
        this.homeService.profile.w3Addresses &&
        this.homeService.profile.w3Addresses.length > 0
      ) {
        const w3Addresses = this.homeService.profile.w3Addresses.filter(
          (address: any) => address.isMain === true
        );
        if (this.w3Address !== w3Addresses[0].address) {
          this.w3Address = w3Addresses[0].address;
          if (
            this.homeService.profile.w3Addresses[
              this.homeService.profile.w3Addresses.length - 1
            ].isMain === false
          ) {
            this.newAddress =
              this.homeService.profile.w3Addresses[
                this.homeService.profile.w3Addresses.length - 1
              ].address;
          }
        }
      } else {
        this.w3Address = '';
      }
    }, 1000);
    const role = this.homeService.getRole(localStorage.getItem('token'));
    if (role && role.length > 0) {
      this.option = role[0].routing;
    }
    moment.locale('it');
    this.selectForm = this._formBuilder.group({
      select: [''],
    });
    this.typeUser = role[0].type;
    this.getRoleLabel(role[0].type);
    const path = this.router.url.split('/');
    if (this.typeRouter !== 'build') {
      this.page = path[3];
    } else {
      this.page = path[4];
    }
    this.getWallet();
  }

  ngDoCheck() {
    this.innerWidth = window.innerWidth;
    this.isMobile = window.innerWidth < 1000;
    const path = this.router.url.split('/');
    this.type = path[2];
    this.selectForm.get('select').setValue(path[2]);
    if (path[2] !== 'build') {
      this.page = path[3];
    } else {
      this.page = path[4];
    }
  }

  async getWallet() {
    this.walletConnected = await this.getGlobalVariables().wallet.address;
  }
  async getProvider(): Promise<void> {
    await this._walletService.getWebProvider();
  }
  goToPage(page: string) {
    this.page = page;
    if (
      document.getElementById('select-open').classList.toggle('enabled') ===
      true
    ) {
      document.getElementById('select-open').classList.toggle('enabled');
    }
    if (this.isMobile) {
      this.drawer.close();
    }
  }

  setSelect() {
    this.type = this.selectForm.get('select').value;
    this.routing = this.homeService.getRouting(localStorage.getItem('token'));
    if (this.type === 'build') {
      this.router.navigate([
        '/dashboard/' + this.type + '/' + this.routing[0].type + '/',
      ]);
    } else {
      this.router.navigate(['/dashboard/' + this.type]);
    }
    this.drawer.close();
  }

  openSelect() {
    document.getElementById('select-open').classList.toggle('enabled');
  }

  closeDialog(width: any) {
    const path = this.router.url.split('/');
    if (this.typeRouter !== 'build') {
      this.router.navigate(['/dashboard/' + this.typeRouter + '/profile']);
    } else {
      this.router.navigate([
        '/dashboard/' + this.typeRouter + '/' + path[3] + '/profile',
      ]);
    }
    if (width > 1000) {
      document.getElementById('select-open').classList.toggle('enabled');
    } else {
      this.drawer.close();
    }
  }

  openDashboard(width: any) {
    if (this.labelUser === 'Amministratore') {
      this.dialog.open(DialogDashboardAdminComponent, {width: '90%',});
    } else if (this.labelUser === 'Tecnico') {
      this.dialog.open(DialogDashboardTechnicianComponent, {width: '90%',});
    } else if (this.labelUser === 'Consulente') {
      this.dialog.open(DialogDashboardConsultantComponent, {width: '90%',});
    } else if (this.labelUser === 'General contractor') {
      this.dialog.open(DialogDashboardContractorComponent, {width: '90%',});
    } else if (this.labelUser === 'Azienda' || this.labelUser === 'Fornitore') {
      this.dialog.open(DialogDashboardCompanyComponent, {width: '90%',});
    } else if (this.labelUser === 'Amministratore condominio') {
      this.dialog.open(DialogDashboardACComponent, {width: '90%',});
    } else {
      this.dialog.open(DialogDashboardComponent, {width: '90%',});
    }
    if (width > 1000) {
      document.getElementById('select-open').classList.toggle('enabled');
    } else {
      this.drawer.close();
    }
  }
  openSite(site: any) {
    window.open(site);
  }
  geToProfile() {
    const path = this.router.url.split('/');
    if (this.typeRouter !== 'build') {
      this.router.navigate(['/dashboard/' + this.typeRouter + '/profile']);
    } else {
      this.router.navigate([
        '/dashboard/' + this.typeRouter + '/' + path[3] + '/profile',
      ]);
    }
  }

  Logout() {
    this.page = 'logout';
    localStorage.removeItem('token');
    this.homeService.profile = null;
    this.router.navigate(['/home']);
  }

  getRoleLabel(role: string) {
    if (role === 'admin') {
      this.labelUser = 'Amministratore';
    } else if (role === 'technicianHauses') {
      this.labelUser = 'Tecnico';
    } else if (role === 'buildRenovateConsultant') {
      this.labelUser = 'Consulente';
    } else if (role === 'buildRenovateGeneralContractor') {
      this.labelUser = 'General contractor';
    } else if (role === 'buildRenovateSupplier') {
      this.labelUser = 'Fornitore';
    } else if (role === 'buildRenovateCompany') {
      this.labelUser = 'Azienda';
    } else if (role === 'buildRenovateCondominium') {
      this.labelUser = 'Amministratore condominio';
    } else {
      this.labelUser = 'Utente';
    }
  }

  getGlobalVariables(): GlobalVariables {
    return this._walletService.getGlobalVariables();
  }

  Copy(address: any) {
    this.clipboard.copy(address);
    this.notifierService.showNotificationSuccess('Address copied');
  }
}
