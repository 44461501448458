<mat-grid-list
  cols="12"
  rowHeight="105px"
  [gutterSize]="'10px'"
  fxLayoutGap="30px"
  style="margin: 30px 0"
>
  <mat-grid-tile
    [colspan]="innerWidth > 1000 ? 12 : 12"
    [rowspan]="innerWidth > 1000 ? 6 : 6"
    class="card"
  >
    <div class="container_card" fxLayout="column">
      <label class="token">Hauses Web3</label>
      <label class="title_card"
        >Per poter usufruire di questo servizio devi connettere il tuo wallet
        Web3 oppure verifica di aver connesso il wallet corretto</label
      >
      <button [class]="'button_change_wallet'" (click)="goToProfile()">
        Vai al profilo
      </button>
    </div>
  </mat-grid-tile>
</mat-grid-list>
