import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { UserLoginRequest } from '../model/user.model';
import jwt_decode from 'jwt-decode';
import { MatDrawer } from '@angular/material/sidenav';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  private baseUrl = environment.baseUrl;
  decodedJWT: any;
  profile: any;

  constructor(private _http: HttpClient, private drawer: MatDrawer) {}

  public Login(user: UserLoginRequest): Observable<any> {
    return this._http.post<any>(`${this.baseUrl}/user/login`, user);
  }

  public forgotPassword(email: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrl}/user/forgotPassword`, email);
  }

  public resetPassword(data: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrl}/user/resetPassword`, data);
  }

  toggle(): void {
    this.drawer.toggle();
  }

  get token(): string | null {
    return localStorage.getItem('token');
  }

  public resendEmail(email: string): Observable<any> {
    return this._http.post<any>(`${this.baseUrl}/user/resendVerifyEmail`, {
      email,
    });
  }

  public sendContactEmail(
    name: string,
    email: string,
    phone: string,
    city: string,
    message: string,
    fromPage: string,
    fromId: string,
    isMarketing: boolean
  ): Observable<any> {
    return this._http.post<any>(`${this.baseUrl}/contact/add`, {
      name,
      email,
      phone,
      city,
      message,
      fromPage,
      fromId,
      isMarketing,
    });
  }

  public getProfile(token: string | null | undefined): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    };
    return this._http.get<any>(`${this.baseUrl}/user/profile`, { headers });
  }

  Notification(res: any) {
    if (localStorage.getItem('token')) {
      let index = 0;
      if (res.notifications) {
        for (let i = 0; i < res.notifications.length; i++) {
          if (!res.notifications[i].isRead) {
            index = index + 1;
          }
        }
        if (index > 0) {
          localStorage.setItem('notification', String(index));
        } else {
          localStorage.removeItem('notification');
        }
      }
    } else {
      localStorage.removeItem('notification');
    }
  }

  updateNotification(res: any) {
    let index = 0;
    this.profile = res;
    for (let i = 0; i < res.notifications.length; i++) {
      if (!this.profile.notifications[i].isRead) {
        index = index + 1;
      }
    }
    if (index > 0) {
      localStorage.setItem('notification', String(index));
    } else {
      localStorage.removeItem('notification');
    }
  }

  public getBuilding(buildingId: any): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };
    return this._http.get<any>(`${this.baseUrl}/building/getBuilding`, {
      headers,
      params: new HttpParams().set('buildingId', buildingId),
    });
  }

  public getApartmentsBuilding(
    buildingId: any,
    from: string,
    to: string
  ): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };
    return this._http.get<any>(`${this.baseUrl}/apartment/getApartments`, {
      headers,
      params: new HttpParams()
        .set('buildingId', buildingId)
        .set('from', from)
        .set('to', to),
    });
  }

  public getApartment(apartmentId: any): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };
    return this._http.get<any>(`${this.baseUrl}/apartment/getApartment`, {
      headers,
      params: new HttpParams().set('apartmentId', apartmentId),
    });
  }

  public getBuildings(): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };
    return this._http.get<any>(`${this.baseUrl}/building/getBuildings`, {
      headers,
    });
  }

  public getInvestment(investmentId: any): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };
    return this._http.get<any>(`${this.baseUrl}/investment/getInvestment`, {
      headers,
      params: new HttpParams().set('investmentId', investmentId),
    });
  }

  public openPDF(url: string): Observable<Blob> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this._http.get<any>(url, {
      headers: headers,
      responseType: 'blob' as 'json',
    });
  }

  public getInvestments(): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };
    return this._http.get<any>(`${this.baseUrl}/investment/getInvestments`, {
      headers,
    });
  }

  public verifyEmail(token: string | null): Observable<any> {
    return this._http.post<any>(`${this.baseUrl}/user/verifyEmail`, { token });
  }

  public addRole(
    token: string | null | undefined,
    roles: any
  ): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    };
    return this._http.patch<any>(
      `${this.baseUrl}/user/addRoles`,
      { roles: roles },
      { headers }
    );
  }

  public buyTransfer(amount: any, apartmentId: any): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.post<any>(
      `${this.baseUrl}/apartment/buyTransfer`,
      { amount },
      { headers, params: new HttpParams().set('apartmentId', apartmentId) }
    );
  }

  public requestTransfer(apartmentId: any): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.post<any>(
      `${this.baseUrl}/apartment/requestTransfer`,
      null,
      { headers, params: new HttpParams().set('apartmentId', apartmentId) }
    );
  }

  public buyStore(amount: any, apartmentId: any): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.post<any>(
      `${this.baseUrl}/apartment/buyStore`,
      { amount },
      { headers, params: new HttpParams().set('apartmentId', apartmentId) }
    );
  }

  public setIsDepositFromW3(apartmentId: any): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.post<any>(
      `${this.baseUrl}/apartment/setIsDepositFromW3`,
      null,
      { headers, params: new HttpParams().set('apartmentId', apartmentId) }
    );
  }

  public invest(amount: any, investmentId: any): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.post<any>(
      `${this.baseUrl}/investment/investW2`,
      { amount },
      { headers, params: new HttpParams().set('investmentId', investmentId) }
    );
  }

  public investPropose(proposalInvestments: any): Observable<any> {
    const formData = new FormData();
    proposalInvestments.forEach((proposalInvestment: any) =>
      formData.append('proposalInvestment', proposalInvestment)
    );
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.post<any>(
      `${this.baseUrl}/investment/propose`,
      formData,
      { headers }
    );
  }

  public requestTechnicianHauses(
    email: string,
    phone: string,
    name: any,
    CF: string,
    city: any,
    message: any,
    images: any
  ) {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('name', name);
    formData.append('CF', CF);
    formData.append('city', city);
    formData.append('message', message);
    images.forEach((curriculumLetter: any) =>
      formData.append('curriculumLetter', curriculumLetter)
    );
    return this._http.post(
      `${this.baseUrl}/admin/requestTechnicianHauses`,
      formData
    );
  }

  dataURItoBlob(dataURI: string | ArrayBuffer) {
    const byteString = atob(dataURI.toString().split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/png' });
  }

  imageTo64(
    url: string,
    callback: (path64: string | ArrayBuffer) => void
  ): void {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();

    xhr.onload = (): void => {
      const reader = new FileReader();
      reader.readAsDataURL(xhr.response);
      reader.onloadend = (): void =>
        callback(reader.result === null ? '' : reader.result);
    };
  }

  public register(
    email: string,
    phone: string,
    label: string,
    roles: any,
    password: any,
    firstName: any,
    lastName: any,
    isMarketing: any,
    CF: string,
    image: any,
    language: string
  ) {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('label', label);
    formData.append('roles', roles);
    formData.append('password', password);
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('isMarketing', isMarketing);
    formData.append('CF', CF);
    formData.append('language', language);
    image.forEach((images: any) => formData.append('profileImage', images));
    return this._http.post(`${this.baseUrl}/user/register`, formData);
  }

  public registerCompany(
    email: string,
    phone: string,
    label: string,
    roles: any,
    password: any,
    firstName: any,
    lastName: any,
    isMarketing: any,
    CF: string,
    image: any,
    companyName: string,
    companyAddress: string,
    companyPECorSDI: string,
    companyPIVA: string,
    companySite: string,
    companyEmail: string,
    companyPhone: string,
    companyBIO: string,
    language: string
  ) {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('label', label);
    formData.append('roles', roles);
    formData.append('password', password);
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('isMarketing', isMarketing);
    formData.append('CF', CF);
    formData.append('language', language);
    image.forEach((images: any) => formData.append('profileImage', images));
    formData.append('companyName', companyName);
    formData.append('companyAddress', companyAddress);
    formData.append('companyPECorSDI', companyPECorSDI);
    formData.append('companyPIVA', companyPIVA);
    formData.append('companySite', companySite);
    formData.append('companyEmail', companyEmail);
    formData.append('companyPhone', companyPhone);
    formData.append('companyBIO', companyBIO);
    return this._http.post(`${this.baseUrl}/user/register`, formData);
  }

  public registerConsultant(
    email: string,
    phone: string,
    label: string,
    roles: any,
    password: any,
    firstName: any,
    lastName: any,
    isMarketing: any,
    CF: string,
    image: any,
    consultantRole: string,
    consultantBio: string,
    consultantPIVA: string,
    language: string
  ) {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('label', label);
    formData.append('roles', roles);
    formData.append('password', password);
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('isMarketing', isMarketing);
    formData.append('CF', CF);
    formData.append('language', language);
    image.forEach((images: any) => formData.append('profileImage', images));
    formData.append('consultantRole', consultantRole);
    formData.append('consultantBIO', consultantBio);
    formData.append('consultantPIVA', consultantPIVA);
    return this._http.post(`${this.baseUrl}/user/register`, formData);
  }

  public getRole(token: string | null) {
    let type: { type: string; routing: string[] }[] = [];
    if (token) {
      this.decodedJWT = jwt_decode(token);
      if (this.decodedJWT && this.decodedJWT.scopes) {
        if (this.decodedJWT.scopes.includes('admin')) {
          type = [
            {
              type: 'admin',
              routing: ['Acquista', 'Investi', 'Costruisci/Ristruttura'],
            },
          ];
        } else if (this.decodedJWT.scopes.includes('technicianHauses')) {
          type = [
            { type: 'technicianHauses', routing: ['Costruisci/Ristruttura'] },
          ];
        } else if (this.decodedJWT.scopes.includes('buildRenovateConsultant')) {
          type = [
            {
              type: 'buildRenovateConsultant',
              routing: ['Costruisci/Ristruttura'],
            },
          ];
        } else if (
          this.decodedJWT.scopes.includes('buildRenovateGeneralContractor')
        ) {
          type = [
            {
              type: 'buildRenovateGeneralContractor',
              routing: ['Costruisci/Ristruttura'],
            },
          ];
        } else if (this.decodedJWT.scopes.includes('buildRenovateSupplier')) {
          type = [
            {
              type: 'buildRenovateSupplier',
              routing: ['Costruisci/Ristruttura'],
            },
          ];
        } else if (this.decodedJWT.scopes.includes('buildRenovateCompany')) {
          type = [
            {
              type: 'buildRenovateCompany',
              routing: ['Costruisci/Ristruttura'],
            },
          ];
        } else if (
          this.decodedJWT.scopes.includes('buildRenovateCondominium')
        ) {
          type = [
            {
              type: 'buildRenovateCondominium',
              routing: ['Costruisci/Ristruttura'],
            },
          ];
        } else {
          type = [{ type: 'user', routing: [] }];
          if (this.decodedJWT.scopes.indexOf('buyer') >= 0) {
            type[0].routing.push('Acquista');
          }
          if (
            this.decodedJWT.scopes.indexOf('investorNormal') >= 0 ||
            this.decodedJWT.scopes.indexOf('investorBlack') >= 0 ||
            this.decodedJWT.scopes.indexOf('investorBlue') >= 0
          ) {
            type[0].routing.push('Investi');
          }
          if (this.decodedJWT.scopes.indexOf('buildRenovateUser') >= 0) {
            type[0].routing.push('Costruisci/Ristruttura');
          }
        }
      }
    }
    return type;
  }

  public getRouting(token: string | null) {
    let type: { type: string; routing: string[] }[] = [];
    if (token) {
      this.decodedJWT = jwt_decode(token);
      if (this.decodedJWT && this.decodedJWT.scopes) {
        if (this.decodedJWT.scopes.includes('admin')) {
          type = [
            {
              type: 'admin',
              routing: ['Acquista', 'Investi', 'Costruisci/Ristruttura'],
            },
          ];
        } else if (this.decodedJWT.scopes.includes('technicianHauses')) {
          type = [{ type: 'technician', routing: ['Costruisci/Ristruttura'] }];
        } else if (this.decodedJWT.scopes.includes('buildRenovateConsultant')) {
          type = [{ type: 'consultant', routing: ['Costruisci/Ristruttura'] }];
        } else if (
          this.decodedJWT.scopes.includes('buildRenovateGeneralContractor')
        ) {
          type = [{ type: 'contractor', routing: ['Costruisci/Ristruttura'] }];
        } else if (this.decodedJWT.scopes.includes('buildRenovateSupplier')) {
          type = [{ type: 'supplier', routing: ['Costruisci/Ristruttura'] }];
        } else if (this.decodedJWT.scopes.includes('buildRenovateCompany')) {
          type = [{ type: 'company', routing: ['Costruisci/Ristruttura'] }];
        } else if (
          this.decodedJWT.scopes.includes('buildRenovateCondominium')
        ) {
          type = [{ type: 'condominium', routing: ['Costruisci/Ristruttura'] }];
        } else {
          type = [{ type: 'user', routing: [] }];
          if (this.decodedJWT.scopes.indexOf('buyer') >= 0) {
            type[0].routing.push('Acquista');
          }
          if (
            this.decodedJWT.scopes.indexOf('investorNormal') >= 0 ||
            this.decodedJWT.scopes.indexOf('investorBlack') >= 0 ||
            this.decodedJWT.scopes.indexOf('investorBlue') >= 0
          ) {
            type[0].routing.push('Investi');
          }
          if (this.decodedJWT.scopes.indexOf('buildRenovateUser') >= 0) {
            type[0].routing.push('Costruisci/Ristruttura');
          }
        }
      }
    }
    return type;
  }

  public getRoutingOnLogin(token: string | null) {
    let type: any;
    if (token) {
      this.decodedJWT = jwt_decode(token);
      if (this.decodedJWT && this.decodedJWT.scopes) {
        if (this.decodedJWT.scopes.includes('admin')) {
          type = 'buy';
        } else if (this.decodedJWT.scopes.includes('technicianHauses')) {
          type = 'build';
        } else if (this.decodedJWT.scopes.includes('buildRenovateConsultant')) {
          type = 'build';
        } else if (
          this.decodedJWT.scopes.includes('buildRenovateGeneralContractor')
        ) {
          type = 'build';
        } else if (this.decodedJWT.scopes.includes('buildRenovateSupplier')) {
          type = 'build';
        } else if (this.decodedJWT.scopes.includes('buildRenovateCompany')) {
          type = 'build';
        } else if (
          this.decodedJWT.scopes.includes('buildRenovateCondominium')
        ) {
          type = 'build';
        } else {
          if (this.decodedJWT.scopes.indexOf('buyer') >= 0) {
            type = 'buy';
          } else if (
            this.decodedJWT.scopes.indexOf('investorNormal') >= 0 ||
            this.decodedJWT.scopes.indexOf('investorBlack') >= 0 ||
            this.decodedJWT.scopes.indexOf('investorBlue') >= 0
          ) {
            type = 'invest';
          } else if (this.decodedJWT.scopes.indexOf('buildRenovateUser') >= 0) {
            type = 'build';
          }
        }
      }
    }
    return type;
  }
}
