<div class="sidenav-container">
  <div class="sidenav-container_top" fxLayout="column">
    <div
      class="container_logo"
      fxLayout="row"
      fxLayoutAlign="space-between"
      fxLayoutGap="10px"
    >
      <div
        class="container_link"
        fxLayout="row"
        fxLayoutAlign="space-between"
        fxLayoutGap="10px"
      >
        <div class="container_text">
          <img
            src="assets/image/logo/complete_logo.svg"
            alt=""
            class="image"
          />
        </div>
        <div class="container_text">
          <img
            src="assets/image/home/close.svg"
            alt=""
            class="image_close"
            (click)="drawer.close()"
          />
        </div>
      </div>
    </div>
    <div class="navigation" fxLayout="column" fxLayoutGap="5px">
      <span
        [routerLink]="'/home'"
        (click)="drawer.close()"
        class="container_link_2"
        fxLayout="row"
        fxLayoutAlign="space-between"
        fxLayoutGap="10px"
      >
        <div class="container_text">
          <img
            src="assets/image/home/sidenav/home.svg"
            alt=""
            class="image_button"
          />
          <label class="title">Home</label>
        </div>
        <div class="container_text">
          <img src="assets/image/home/arrow_sidenav.svg" alt="" />
        </div>
      </span>
      <div class="container_link_1">
        <span
          (click)="goToPage('/buy')"
          class="container_link_sub"
          fxLayout="row"
          fxLayoutAlign="space-between"
          fxLayoutGap="10px"
        >
          <div class="container_text">
            <img
              src="assets/image/dashboard/sub.svg"
              alt=""
              class="image_button_sub"
            />
            <label class="title_sub">Acquista</label>
          </div>
          <div class="container_text"></div>
        </span>
        <span
          (click)="goToPage('/invest')"
          class="container_link_sub"
          fxLayout="row"
          fxLayoutAlign="space-between"
          fxLayoutGap="10px"
        >
          <div class="container_text">
            <img
              src="assets/image/dashboard/sub.svg"
              alt=""
              class="image_button_sub"
            />
            <label class="title_sub">Investi</label>
          </div>
          <div class="container_text"></div>
        </span>
        <span
          (click)="goToPage('/build')"
          class="container_link_sub"
          fxLayout="row"
          fxLayoutAlign="space-between"
          fxLayoutGap="10px"
        >
          <div class="container_text">
            <img
              src="assets/image/dashboard/sub.svg"
              alt=""
              class="image_button_sub"
            />
            <label class="title_sub">Costruisci/Ristruttura</label>
          </div>
          <div class="container_text"></div>
        </span>
      </div>
    </div>
    <div *ngIf="token" class="navigation" fxLayout="column" fxLayoutGap="5px">
      <span
        *ngIf="token"
        (click)="goToDashboard()"
        class="container_link"
        fxLayout="row"
        fxLayoutAlign="space-between"
        fxLayoutGap="10px"
      >
        <div class="container_text">
          <img
            src="assets/image/home/sidenav/dashboard.svg"
            alt=""
            class="image_button"
          />
          <label class="title">Dashboard</label>
        </div>
        <div class="container_text">
          <img src="assets/image/home/arrow_sidenav.svg" alt="" />
        </div>
      </span>
    </div>
    <div *ngIf="!token" class="navigation" fxLayout="column" fxLayoutGap="5px">
      <span
        (click)="goToLogin()"
        class="container_link"
        fxLayout="row"
        fxLayoutAlign="space-between"
        fxLayoutGap="10px"
      >
        <div class="container_text">
          <img
            [src]="'./../../assets/image/home/sidenav/dashboard.svg'"
            alt=""
            class="image_button"
          />
          <label class="title">Accedi</label>
        </div>
        <div class="container_text">
          <img src="assets/image/home/arrow_sidenav.svg" alt="" />
        </div>
      </span>
    </div>
    <div class="navigation" fxLayout="column" fxLayoutGap="5px">
      <span
        [routerLink]="'/who-we-are'"
        (click)="drawer.close()"
        class="container_link"
        fxLayout="row"
        fxLayoutAlign="space-between"
        fxLayoutGap="10px"
      >
        <div class="container_text">
          <img
            src="assets/image/home/sidenav/Chisiamo.svg"
            alt=""
            class="image_button"
          />
          <label class="title">Chi siamo</label>
        </div>
        <div class="container_text">
          <img src="assets/image/home/arrow_sidenav.svg" alt="" />
        </div>
      </span>
    </div>
    <div class="navigation" fxLayout="column" fxLayoutGap="5px">
      <span
        [routerLink]="'/career'"
        (click)="drawer.close()"
        class="container_link"
        fxLayout="row"
        fxLayoutAlign="space-between"
        fxLayoutGap="10px"
      >
        <div class="container_text">
          <img
            src="assets/image/home/sidenav/Carriere.svg"
            alt=""
            class="image_button"
          />
          <label class="title">Carriere</label>
        </div>
        <div class="container_text">
          <img src="assets/image/home/arrow_sidenav.svg" alt="" />
        </div>
      </span>
    </div>
    <div class="navigation" fxLayout="column" fxLayoutGap="5px">
      <span
        (click)="openBlog()"
        class="container_link"
        fxLayout="row"
        fxLayoutAlign="space-between"
        fxLayoutGap="10px"
      >
        <div class="container_text">
          <img
            src="assets/image/home/sidenav/blog.svg"
            alt=""
            class="image_button"
          />
          <label class="title">Blog</label>
        </div>
        <div class="container_text">
          <img src="assets/image/home/arrow_sidenav.svg" alt="" />
        </div>
      </span>
    </div>
    <div *ngIf="token" class="navigation" fxLayout="column" fxLayoutGap="5px">
      <span
        (click)="Logout()"
        class="container_link"
        fxLayout="row"
        fxLayoutAlign="space-between"
        fxLayoutGap="10px"
      >
        <div class="container_text">
          <img
            [src]="'../../../assets/image/dashboard/logout.svg'"
            alt=""
            class="image_button"
          />
          <label class="title">Logout</label>
        </div>
        <div class="container_text">
          <img src="assets/image/home/arrow_sidenav.svg" alt="" />
        </div>
      </span>
    </div>
  </div>
</div>
