import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BuildRenovateService {
  private baseUrl = environment.baseUrl;

  constructor(private _http: HttpClient) {}

  get token(): string | null {
    return localStorage.getItem('token');
  }

  public getTechnicianHauses(from: string, to: string): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.get<any>(
      `${this.baseUrl}/buildRenovate/getTechnicianHauses`,
      { headers, params: new HttpParams().set('from', from).set('to', to) }
    );
  }
  public getBuildRenovates(from: string, to: string): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.get<any>(
      `${this.baseUrl}/buildRenovate/getBuildRenovates`,
      { headers, params: new HttpParams().set('from', from).set('to', to) }
    );
  }

  public getBuildRenovate(buildRenovateId: string): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.get<any>(
      `${this.baseUrl}/buildRenovate/getBuildRenovate`,
      {
        headers,
        params: new HttpParams().set('buildRenovateId', buildRenovateId),
      }
    );
  }

  public confirmAddTechnician(
    technicianID: any,
    buildRenovateId: string
  ): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.patch<any>(
      `${this.baseUrl}/buildRenovate/addTechnicianHauses`,
      technicianID,
      {
        headers,
        params: new HttpParams().set('buildRenovateId', buildRenovateId),
      }
    );
  }

  //ritorna tutte le pratiche di un tecnico
  public getBuildRenovatesTechnicianHauses(
    userId: any,
    from: string,
    to: string
  ): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.get<any>(
      `${this.baseUrl}/buildRenovate/getBuildRenovatesTechnicianHauses`,
      {
        headers,
        params: new HttpParams()
          .set('userId', userId)
          .set('from', from)
          .set('to', to),
      }
    );
  }

  public getBuildRenovatesConsultant(
    userId: any,
    from: string,
    to: string,
    statusConsultant: string
  ): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.get<any>(
      `${this.baseUrl}/buildRenovate/getBuildRenovatesConsultant`,
      {
        headers,
        params: new HttpParams()
          .set('userId', userId)
          .set('from', from)
          .set('to', to)
          .set('statusConsultant', statusConsultant),
      }
    );
  }
  public getBuildRenovatesThirdPart(from: string, to: string): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.get<any>(
      `${this.baseUrl}/buildRenovate/getBuildRenovatesThirdPart`,
      { headers, params: new HttpParams().set('from', from).set('to', to) }
    );
  }

  public getBuildRenovateThirdPart(buildRenovateId: string): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.get<any>(
      `${this.baseUrl}/buildRenovate/getBuildRenovateThirdPart`,
      {
        headers,
        params: new HttpParams().set('buildRenovateId', buildRenovateId),
      }
    );
  }

  public getConsultants(from: string, to: string): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.get<any>(`${this.baseUrl}/buildRenovate/getConsultants`, {
      headers,
      params: new HttpParams().set('from', from).set('to', to),
    });
  }

  public createBuildRenovate(data: any): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.post<any>(`${this.baseUrl}/buildRenovate/create`, data, {
      headers,
    });
  }

  public updateBuildRenovate(
    data: any,
    buildRenovateId: string
  ): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.patch<any>(`${this.baseUrl}/buildRenovate/update`, data, {
      headers,
      params: new HttpParams().set('buildRenovateId', buildRenovateId),
    });
  }

  public addConsultantToBuildRenovate(
    consultantId: any,
    buildRenovateId: string
  ): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.patch<any>(
      `${this.baseUrl}/buildRenovate/addConsultant`,
      consultantId,
      {
        headers,
        params: new HttpParams().set('buildRenovateId', buildRenovateId),
      }
    );
  }
  public getSearchConsultant(str: string): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.get<any>(
      `${this.baseUrl}/buildRenovate/searchConsultant`,
      { headers, params: new HttpParams().set('str', str) }
    );
  }
  public updateConsultantFiles(consultantFiles: any): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    const formData = new FormData();
    consultantFiles.forEach((files: any) => formData.append('files', files));
    return this._http.patch<any>(
      `${this.baseUrl}/user/updateConsultantFiles`,
      formData,
      { headers }
    );
  }
  public inviteConsultant(data: any): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.post<any>(
      `${this.baseUrl}/buildRenovate/inviteConsultant`,
      data,
      { headers }
    );
  }

  public getCadastralCode(): Observable<any> {
    return this._http.get('assets/csv/ElencoComuniItalianiMod.csv', {
      responseType: 'text',
    });
  }
  public getMacroCategories(): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.get<any>(
      `${this.baseUrl}/buildRenovate/getMacroCategories`,
      { headers }
    );
  }
  public uploadRenovateForm(
    consultantFiles: any,
    buildRenovateId: any
  ): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    const formData = new FormData();
    consultantFiles.forEach((files: any) => formData.append('files', files));
    return this._http.patch<any>(
      `${this.baseUrl}/buildRenovate/uploadRenovateForm`,
      formData,
      {
        headers,
        params: new HttpParams().set('buildRenovateId', buildRenovateId),
      }
    );
  }

  public updateCME(buildRenovateId: any, CME: any): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.patch<any>(
      `${this.baseUrl}/buildRenovate/updateCME`,
      CME,
      {
        headers,
        params: new HttpParams().set('buildRenovateId', buildRenovateId),
      }
    );
  }

  public confirmUpdateCME(
    buildRenovateId: any,
    CME: any,
    submit: boolean
  ): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.patch<any>(
      `${this.baseUrl}/buildRenovate/updateCME`,
      CME,
      {
        headers,
        params: new HttpParams()
          .set('buildRenovateId', buildRenovateId)
          .set('submit', submit),
      }
    );
  }

  public uploadCME(buildRenovateId: any, CME: any): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    const formData = new FormData();
    formData.append('CME', CME);
    return this._http.patch<any>(
      `${this.baseUrl}/buildRenovate/uploadCME`,
      formData,
      {
        headers,
        params: new HttpParams().set('buildRenovateId', buildRenovateId),
      }
    );
  }
  public acceptConsultant(buildRenovateId: any): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.patch<any>(
      `${this.baseUrl}/buildRenovate/acceptConsultant`,
      null,
      {
        headers,
        params: new HttpParams().set('buildRenovateId', buildRenovateId),
      }
    );
  }

  public rejectConsultant(buildRenovateId: any): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.patch<any>(
      `${this.baseUrl}/buildRenovate/rejectConsultant`,
      null,
      {
        headers,
        params: new HttpParams().set('buildRenovateId', buildRenovateId),
      }
    );
  }

  public addUpdate(buildRenovateId: any, data: any): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.patch<any>(
      `${this.baseUrl}/buildRenovate/addUpdate`,
      data,
      {
        headers,
        params: new HttpParams().set('buildRenovateId', buildRenovateId),
      }
    );
  }

  public approveRenovateForm(buildRenovateId: any, data: any): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.patch<any>(
      `${this.baseUrl}/buildRenovate/approveRenovateForm`,
      data,
      {
        headers,
        params: new HttpParams().set('buildRenovateId', buildRenovateId),
      }
    );
  }

  public rejectRenovateForm(buildRenovateId: any, data: any): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.patch<any>(
      `${this.baseUrl}/buildRenovate/rejectRenovateForm`,
      data,
      {
        headers,
        params: new HttpParams().set('buildRenovateId', buildRenovateId),
      }
    );
  }

  public approveCME(buildRenovateId: any, data: any): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.patch<any>(
      `${this.baseUrl}/buildRenovate/approveCME`,
      data,
      {
        headers,
        params: new HttpParams().set('buildRenovateId', buildRenovateId),
      }
    );
  }

  public rejectCME(buildRenovateId: any, data: any): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.patch<any>(
      `${this.baseUrl}/buildRenovate/rejectCME`,
      data,
      {
        headers,
        params: new HttpParams().set('buildRenovateId', buildRenovateId),
      }
    );
  }
  public acceptObjBuildRenovate(
    objBuildRenovateId: any,
    data: any
  ): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.patch<any>(
      `${this.baseUrl}/objBuildRenovate/accept`,
      data,
      {
        headers,
        params: new HttpParams().set('objBuildRenovateId', objBuildRenovateId),
      }
    );
  }
  public rejectObjBuildRenovate(
    objBuildRenovateId: any,
    data: any
  ): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.patch<any>(
      `${this.baseUrl}/objBuildRenovate/reject`,
      data,
      {
        headers,
        params: new HttpParams().set('objBuildRenovateId', objBuildRenovateId),
      }
    );
  }
  public objBuildCreate(data: any): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.post<any>(
      `${this.baseUrl}/objBuildRenovate/create`,
      data,
      { headers }
    );
  }
  public notifyChargeJobW3(
    index: any,
    objBuildRenovateId: any
  ): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.post<any>(
      `${this.baseUrl}/objBuildRenovate/notifyChargeJobW3`,
      index,
      {
        headers,
        params: new HttpParams().set('objBuildRenovateId', objBuildRenovateId),
      }
    );
  }
  public notifyCompleteJobW3(
    index: any,
    objBuildRenovateId: any
  ): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.post<any>(
      `${this.baseUrl}/objBuildRenovate/notifyCompleteJobW3`,
      index,
      {
        headers,
        params: new HttpParams().set('objBuildRenovateId', objBuildRenovateId),
      }
    );
  }
  public notifyConfirmJobW3(
    index: any,
    objBuildRenovateId: any
  ): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.post<any>(
      `${this.baseUrl}/objBuildRenovate/notifyConfirmJobW3`,
      index,
      {
        headers,
        params: new HttpParams().set('objBuildRenovateId', objBuildRenovateId),
      }
    );
  }
  public counterProposal(data: any, objBuildRenovateId: any): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.patch<any>(
      `${this.baseUrl}/objBuildRenovate/counterProposal`,
      data,
      {
        headers,
        params: new HttpParams().set('objBuildRenovateId', objBuildRenovateId),
      }
    );
  }
  public uploadContract(
    objBuildRenovateId: any,
    contract: any
  ): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    const formData = new FormData();
    formData.append('contract', contract);
    return this._http.patch<any>(
      `${this.baseUrl}/objBuildRenovate/uploadContract`,
      formData,
      {
        headers,
        params: new HttpParams().set('objBuildRenovateId', objBuildRenovateId),
      }
    );
  }
  public signContract(objBuildRenovateId: any, contract: any): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    const formData = new FormData();
    formData.append('contract', contract);
    return this._http.patch<any>(
      `${this.baseUrl}/objBuildRenovate/signContract`,
      formData,
      {
        headers,
        params: new HttpParams().set('objBuildRenovateId', objBuildRenovateId),
      }
    );
  }
  public resetContract(objBuildRenovateId: any): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.patch<any>(
      `${this.baseUrl}/objBuildRenovate/resetContract`,
      null,
      {
        headers,
        params: new HttpParams().set('objBuildRenovateId', objBuildRenovateId),
      }
    );
  }
  public verifySignatures(objBuildRenovateId: any): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.patch<any>(
      `${this.baseUrl}/objBuildRenovate/verifySignatures`,
      null,
      {
        headers,
        params: new HttpParams().set('objBuildRenovateId', objBuildRenovateId),
      }
    );
  }
  public requestInspection(buildRenovateId: any): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.patch<any>(
      `${this.baseUrl}/buildRenovate/requestInspection`,
      null,
      {
        headers,
        params: new HttpParams().set('buildRenovateId', buildRenovateId),
      }
    );
  }
  public finish(buildRenovateId: any): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.patch<any>(`${this.baseUrl}/buildRenovate/finish`, null, {
      headers,
      params: new HttpParams().set('buildRenovateId', buildRenovateId),
    });
  }

  public resell(objBuildRenovateId: any): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.patch<any>(
      `${this.baseUrl}/objBuildRenovate/resell`,
      null,
      {
        headers,
        params: new HttpParams().set('objBuildRenovateId', objBuildRenovateId),
      }
    );
  }

  public getObjBuildRenovates(from: string, to: string): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.get<any>(
      `${this.baseUrl}/objBuildRenovate/getObjBuildRenovates`,
      { headers, params: new HttpParams().set('from', from).set('to', to) }
    );
  }
  public getObjBuildRenovate(objBuildRenovateId: string): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.get<any>(
      `${this.baseUrl}/objBuildRenovate/getObjBuildRenovate`,
      {
        headers,
        params: new HttpParams().set('objBuildRenovateId', objBuildRenovateId),
      }
    );
  }
  public getActiveBuildRenovates(
    buildRenovateConsultantId: string
  ): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.get<any>(
      `${this.baseUrl}/buildRenovate/getActiveBuildRenovates`,
      {
        headers,
        params: new HttpParams().set(
          'buildRenovateConsultantId',
          buildRenovateConsultantId
        ),
      }
    );
  }
  public downloadCME(buildRenovateId: string): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.get<any>(`${this.baseUrl}/buildRenovate/downloadCME`, {
      headers,
      params: new HttpParams().set('buildRenovateId', buildRenovateId),
    });
  }

  public downloadChannelCME(objBuildRenovateId: string): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.get<any>(`${this.baseUrl}/objBuildRenovate/downloadCME`, {
      headers,
      params: new HttpParams().set('objBuildRenovateId', objBuildRenovateId),
    });
  }

  public buildRenovateSearch(str: string): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.get<any>(`${this.baseUrl}/buildRenovate/search`, {
      headers,
      params: new HttpParams().set('str', str),
    });
  }

  public objBuildRenovateSearch(str: string): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.get<any>(`${this.baseUrl}/objBuildRenovate/search`, {
      headers,
      params: new HttpParams().set('str', str),
    });
  }

  public buildRenovateThirdPartSearch(str: string): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.get<any>(`${this.baseUrl}/buildRenovate/searchOffers`, {
      headers,
      params: new HttpParams().set('str', str),
    });
  }

  download(res: any, id: any, name: string) {
    const buf = new ArrayBuffer(res.data.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i != res.data.length; ++i) view[i] = res.data[i];
    const blob = new Blob([buf], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const a = window.document.createElement('a');
    a.href = window['URL'].createObjectURL(blob);
    a.download = name + '.xlsx';
    document.body.appendChild(a);
    a.click();
  }

  public createFormCSV(
    category: any,
    step2: any,
    step3: any,
    step4_1: any,
    step4_2: any,
    step5: any,
    step6: any,
    step7: any,
    step8: any
  ): any[] {
    const beneficiaryData: any[] = [];
    if (step4_2) {
      for (let data of step4_2) {
        beneficiaryData.push(
          { name: 'Nome', data: data.name },
          { type: 'Tipologia', data: data.type },
          { fiscal_code: 'Codice fiscale', data: data.fiscal_code },
          { rea: 'Codice REA', data: data.rea },
          { birthday_date: 'Data di nascita', data: data.birthday_date },
          {
            birthday_province: 'Provincia di nascita',
            data: data.birthday_province,
          },
          { birthday_city: 'Città di nascita', data: data.birthday_city },
          { state: 'Stato', data: data.state },
          { country: 'Regione', data: data.country },
          { province: 'Provincia', data: data.province },
          { city: 'Città', data: data.city },
          { address: 'Indirizzo', data: data.address },
          { number: 'Numero', data: data.number },
          { cap: 'CAP', data: data.cap },
          { email: 'Email', data: data.email },
          { pec: 'PEC', data: data.pec },
          { phone: 'Telefono', data: data.phone },
          { mobile: 'Cellulare', data: data.mobile },
          { ownership: '% Proprità', data: data.ownership }
        );
      }
    }
    return [
      { step1: '', data: '' },
      { step1: 'Categoria', data: '' },
      { categoria: 'Categoria', data: category },
      { step2: '', data: '' },
      { step2: 'Beneficiario', data: '' },
      { nome: 'Nome', data: step2.firstName },
      { cognome: 'Cognome', data: step2.lastName },
      { date: 'Data di nasciata', data: step2.business_name },
      { date: 'Data di nasciata', data: step2.birthday },
      { province: 'Provincia di nascita', data: step2.province_birthday },
      { city: 'Comune di nascita', data: step2.city_birthday },
      { cf: 'Codice fiscale', data: step2.fiscal_code },
      { email: 'Email', data: step2.email },
      { phone: 'Telefono', data: step2.phone },
      { rea: 'Codice REA', data: step2.REA },
      { status: 'Stato', data: step2.status },
      { region: 'Regione', data: step2.region },
      { province: 'Provincia', data: step2.province },
      { city: 'città', data: step2.city },
      { CAP: 'CAP', data: step2.CAP },
      { address: 'Indirizzo', data: step2.address },
      { number: 'Numero', data: step2.number },
      {
        quest1: 'É presente un legale rappresentante per il Beneficiario?',
        data: step2.quest1,
      },
      { quest2: 'Qual è il titolo del beneficiario?', data: step2.quest2 },
      { step3: '', data: '' },
      { step3: 'Edificio', data: '' },
      {
        quest1: "Qual è il titolo di proprietà dell'immobile del beneficiario?",
        data: step3.quest1,
      },
      {
        quest2: "A quale tipologia edilizia appartiene l'edificio?",
        data: step3.quest2,
      },
      {
        quest3: "L'edificio ha subito danni dovuti ad un sisma?",
        data: step3.quest3,
      },
      {
        quest4: 'Sono presenti dei vincoli culturali e/o paesaggistici?',
        data: step3.quest4,
      },
      {
        quest5: "Qual è l'anno di costruzione dell'edificio? (anche stimato)",
        data: step3.quest5,
      },
      {
        quest6:
          'Qual è la superficie lorda pavimento (S.L.P.) in metri quadrati? Anche stimata',
        data: step3.quest6,
      },
      {
        quest7: "Di quante unità abitative è composto l'edificio?",
        data: step3.quest7,
      },
      { quest8: 'Data assemblea affidamento', data: step3.quest8 },
      { country: 'Nazione', data: step3.country },
      { province: 'Provincia', data: step3.province },
      { city: 'Città', data: step3.city },
      { cap: 'CAP', data: step3.cap },
      { address: 'Indirizzo', data: step3.address },
      { number: 'Numero', data: step3.number },
      { palace: 'Palazzo', data: step3.palace },
      { ladder: 'Scala', data: step3.ladder },
      { plan: 'Piano', data: step3.plan },
      { int: 'Interno', data: step3.int },
      { email: 'Email amministratore', data: step3.email },
      { step4: '', data: '' },
      { step4: 'Dati catastali', data: '' },
      { code: 'Codice catastali', data: step4_1.cadastral_code },
      { type: 'Tipologia', data: step4_1.type },
      { type: 'Sezione', data: step4_1.section },
      { type: 'Foglio', data: step4_1.paper },
      { type: 'Particella 1', data: step4_1.part1 },
      { type: 'Particella 2', data: step4_1.part2 },
      { type: 'SubaIterno', data: step4_1.sub },
      { type: 'Categoria', data: step4_1.category },
      { type: 'Rendita', data: step4_1.income },
      { type: 'Classe', data: step4_1.class },
      { step4: '', data: '' },
      { step4: 'Dati beneficiari', data: '' },
      ...beneficiaryData,
      { step5: '', data: '' },
      { step5: 'Attestati', data: '' },
      {
        quest1:
          "Esiste l'attestato energetico dell'edificio o dell'unità immobiliare (APE)?",
        data: step5.quest1,
      },
      {
        quest2:
          "Esiste la classe sismica dell'edificio o dell'unità immobiliare?",
        data: step5.quest2,
      },
      {
        quest3: "In quale zona sismica geografica si trova l'edificio?",
        data: step5.quest3,
      },
      { step6: '', data: '' },
      { step6: 'Impianti', data: '' },
      { quest1: "L'impianto termico dell'edificio è:", data: step6.quest1 },
      { quest2: 'La regolazione della temperatura è:', data: step6.quest2 },
      {
        quest3: "Qual è il vettore energetico dell'impianto termico?",
        data: step6.quest3,
      },
      {
        quest4:
          "Nell'edificio c'è un impianto di condizionamento estivo/invernale?",
        data: step6.quest4,
      },
      {
        quest5: "C'è un impianto di ventilazione meccanica controllata (VMC)?",
        data: step6.quest5,
      },
      { step7: '', data: '' },
      { step7: 'Interventi', data: '' },
      {
        quest1: "Per quale ambito il beneficiario richiede l'intervento?",
        data: step7.quest1,
      },
      {
        quest1: "Per quale ambito il beneficiario richiede l'intervento?",
        data: step7.quest2,
      },
      {
        quest1: "Per quale ambito il beneficiario richiede l'intervento?",
        data: step7.quest3,
      },
      {
        quest1: "Per quale ambito il beneficiario richiede l'intervento?",
        data: step7.quest4,
      },
      {
        quest1: "Per quale ambito il beneficiario richiede l'intervento?",
        data: step7.quest5,
      },
      {
        quest1: "Per quale ambito il beneficiario richiede l'intervento?",
        data: step7.quest6,
      },
      {
        quest2: 'Quali interventi richiede il beneficiario?',
        data: step7.quest7,
      },
      {
        quest2: 'Quali interventi richiede il beneficiario?',
        data: step7.quest8,
      },
      {
        quest2: 'Quali interventi richiede il beneficiario?',
        data: step7.quest9,
      },
      {
        quest2: 'Quali interventi richiede il beneficiario?',
        data: step7.quest10,
      },
      {
        quest2: 'Quali interventi richiede il beneficiario?',
        data: step7.quest11,
      },
      {
        quest2: 'Quali interventi richiede il beneficiario?',
        data: step7.quest12,
      },
      {
        quest2: 'Quali interventi richiede il beneficiario?',
        data: step7.quest13,
      },
      {
        quest2: 'Quali interventi richiede il beneficiario?',
        data: step7.quest14,
      },
      {
        quest2: 'Quali interventi richiede il beneficiario?',
        data: step7.quest15,
      },
      {
        quest2: 'Quali interventi richiede il beneficiario?',
        data: step7.quest16,
      },
      {
        quest2: 'Quali interventi richiede il beneficiario?',
        data: step7.quest17,
      },
      {
        quest2: 'Quali interventi richiede il beneficiario?',
        data: step7.quest18,
      },
      {
        quest2: 'Quali interventi richiede il beneficiario?',
        data: step7.quest19,
      },
      {
        quest2: 'Quali interventi richiede il beneficiario?',
        data: step7.quest20,
      },
      {
        quest2: 'Quali interventi richiede il beneficiario?',
        data: step7.quest21,
      },
      {
        quest2: 'Quali interventi richiede il beneficiario?',
        data: step7.quest22,
      },
      {
        quest2: 'Quali interventi richiede il beneficiario?',
        data: step7.quest23,
      },
      {
        quest2: 'Quali interventi richiede il beneficiario?',
        data: step7.quest24,
      },
      {
        quest2: 'Quali interventi richiede il beneficiario?',
        data: step7.quest25,
      },
      {
        quest2: 'Quali interventi richiede il beneficiario?',
        data: step7.quest26,
      },
      {
        quest3:
          'Sono stati effettuati in passato altri interventi di efficientamento energetico?',
        data: step7.quest27,
      },
      { step8: '', data: '' },
      { step8: 'Progetto', data: '' },
      { quest1: "Crea un'utenza per il beneficiario", data: step8.quest1 },
      {
        quest2: 'Il progetto contiene interventi pubblici',
        data: step8.quest2,
      },
      {
        quest3: 'Il beneficiario ha accettato il patto di prelazione',
        data: step8.quest3,
      },
    ];
  }
}
