<div class="card">
  <div fxLayout="row">
    <div class="container_card" fxLayoutGap="30px">
      <div class="invest_form_1">
        <div
          fxLayout="column"
          class="container_card_justify"
          fxLayoutGap="40px"
        >
          <table
            *ngIf="dataFiltered && dataFiltered.length > 0 && type === 'buy'"
            class="table"
            mat-table
            [dataSource]="dataFiltered"
          >
            <ng-container matColumnDef="result">
              <th mat-header-cell *matHeaderCellDef>
                <label class="label_header_table">Risultato</label>
              </th>
              <td mat-cell *matCellDef="let element" data-label="Risultato">
                <label
                  *ngIf="element.user"
                  class="label_desc_table_underline"
                  (click)="
                    setLocalStorageData(
                      '/dashboard/buy/reserved',
                      element.user.firstName,
                      element.user.lastName,
                      element.id
                    )
                  "
                  >{{ element.user.firstName }} {{ element.user.lastName }} ({{
                    element.user.email
                  }})</label
                >
                <label
                  *ngIf="element.firstName && element.lastName"
                  class="label_desc_table_underline"
                  (click)="
                    setLocalStorageData(
                      '/dashboard/buy/user',
                      element.firstName,
                      element.lastName,
                      element.id
                    )
                  "
                  >{{ element.firstName }} {{ element.lastName }} ({{
                    element.email
                  }})</label
                >
                <label
                  *ngIf="element.name && element.m2"
                  class="label_desc_table_underline"
                  [routerLink]="
                    '/buy/complex/' + element.building.id + '/' + element.id
                  "
                  >{{ element.name }}</label
                >
                <label
                  *ngIf="element.name && element.apartments"
                  class="label_desc_table_underline"
                  [routerLink]="'/buy/complex/' + element.id"
                  >{{ element.name }}</label
                >
              </td>
            </ng-container>

            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef>
                <label class="label_header_table">Tipologia</label>
              </th>
              <td mat-cell *matCellDef="let element" data-label="Tipologia">
                <label class="label_desc_table">{{
                  element.m2
                    ? "Appartamento"
                    : element.apartments
                    ? "Complesso"
                    : element.contactDate
                    ? "Scheda visite"
                    : element.dateRegistration
                    ? "Utente"
                    : ""
                }}</label>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
          <table
            *ngIf="dataFiltered && dataFiltered.length > 0 && type === 'invest'"
            class="table"
            mat-table
            [dataSource]="dataFiltered"
          >
            <ng-container matColumnDef="result">
              <th mat-header-cell *matHeaderCellDef>
                <label class="label_header_table">Risultato</label>
              </th>
              <td mat-cell *matCellDef="let element" data-label="Risultato">
                <label
                  *ngIf="element.name"
                  class="label_desc_table_underline"
                  [routerLink]="'/invest/complex/' + element.id"
                  >{{ element.name }}</label
                >
                <label
                  *ngIf="element.firstName"
                  class="label_desc_table_underline"
                  (click)="
                    setLocalStorageData(
                      '/dashboard/invest/user',
                      element.firstName,
                      element.lastName,
                      element.id
                    )
                  "
                  >{{ element.firstName }} {{ element.lastName }} ({{
                    element.email
                  }})</label
                >
              </td>
            </ng-container>

            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef>
                <label class="label_header_table">Tipologia</label>
              </th>
              <td mat-cell *matCellDef="let element" data-label="Tipologia">
                <label class="label_desc_table">{{
                  element.minInvestment ? "Investimento" : "Utente"
                }}</label>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
          <table
            *ngIf="dataFiltered && dataFiltered.length > 0 && type === 'build'"
            class="table"
            mat-table
            [dataSource]="dataFiltered"
          >
            <ng-container matColumnDef="result">
              <th mat-header-cell *matHeaderCellDef>
                <label class="label_header_table">Risultato</label>
              </th>
              <td mat-cell *matCellDef="let element" data-label="Risultato">
                <label
                  class="label_desc_table_underline"
                  *ngIf="element.channelMacroCategories"
                  (click)="
                    openPage('/dashboard/build/admin/proposal/' + element.id)
                  "
                  >{{ element.buildRenovate.name }}</label
                >
                <label
                  class="label_desc_table_underline"
                  *ngIf="!element.channelMacroCategories"
                  (click)="
                    openPage('/dashboard/build/admin/practices/' + element.id)
                  "
                  >{{ element.name }}</label
                >
              </td>
            </ng-container>
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef>
                <label class="label_header_table">Tipologia</label>
              </th>
              <td mat-cell *matCellDef="let element" data-label="Tipologia">
                <label
                  class="label_desc_table"
                  *ngIf="element.channelMacroCategories"
                  >Offerta</label
                >
                <label
                  class="label_desc_table"
                  *ngIf="!element.channelMacroCategories"
                  >Pratica</label
                >
              </td>
            </ng-container>
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef>
                <label class="label_header_table">Id</label>
              </th>
              <td mat-cell *matCellDef="let element" data-label="Id">
                <label class="label_desc_table">{{ element.id }}</label>
              </td>
            </ng-container>
            <ng-container matColumnDef="details">
              <th mat-header-cell *matHeaderCellDef>
                <label class="label_header_table"></label>
              </th>
              <td mat-cell *matCellDef="let element">
                <label
                  class="label_desc_table"
                  *ngIf="element.channelMacroCategories"
                  >{{ element.channelTo.firstName }}
                  {{ element.channelTo.lastName }} ({{
                    getRoleLabel(element.channelTo.label)
                  }})</label
                >
                <label
                  class="label_desc_table"
                  *ngIf="!element.channelMacroCategories"
                  >{{ element.address }}</label
                >
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns1"></tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
