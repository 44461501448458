import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NotifierService } from '../../../core/service/notifier.service';
import { BuildRenovateService } from '../../../core/service/buildRenovate.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-build-condominium-search',
  templateUrl: './build-condominium-search.component.html',
  styleUrls: ['./build-condominium-search.component.scss'],
})
export class BuildCondominiumSearchComponent implements OnInit, DoCheck {
  @Input() value: any;
  @Input() type: any;
  displayedColumns: string[] = ['result', 'type', 'id', 'details'];
  filterValue: string = '';
  buildRenovate: any[] = [];
  objBuildRenovate: any[] = [];
  dataFiltered: any[] = [];
  constructor(
    private _formBuilder: FormBuilder,
    private buildRenovateService: BuildRenovateService,
    private _notifierService: NotifierService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.filterValue = this.value;
    this.getBuildings();
  }

  getBuildings() {
    if (this.type === 'build') {
      this.buildRenovateService.buildRenovateSearch(this.value).subscribe(
        (res) => {
          this.buildRenovate = res;
          this.dataFiltered = res;
        },
        () => {}
      );
      this.buildRenovateService.objBuildRenovateSearch(this.value).subscribe(
        (res) => {
          this.objBuildRenovate = res;
          this.dataFiltered = this.dataFiltered.concat(res);
        },
        () => {}
      );
    }
  }

  ngDoCheck(): void {
    if (
      this.value !== undefined &&
      this.value !== this.filterValue &&
      this.type === 'build'
    ) {
      this.filterValue = this.value;
      this.buildRenovateService.buildRenovateSearch(this.value).subscribe(
        (res) => {
          this.buildRenovate = res;
          this.dataFiltered = res;
        },
        () => {}
      );
      this.buildRenovateService.objBuildRenovateSearch(this.value).subscribe(
        (res) => {
          this.objBuildRenovate = res;
          this.dataFiltered = this.dataFiltered.concat(res);
        },
        () => {}
      );
    }
  }

  openPage(url: any) {
    this.router.navigate([url]);
  }

  getRoleLabel(role: string) {
    let label: string;
    if (role === 'admin') {
      label = 'Amministratore';
    } else if (role === 'technicianHauses') {
      label = 'Tecnico';
    } else if (role === 'buildRenovateConsultant') {
      label = 'Consulente';
    } else if (role === 'buildRenovateGeneralContractor') {
      label = 'General contractor';
    } else if (role === 'buildRenovateSupplier') {
      label = 'Fornitore';
    } else if (role === 'buildRenovateCompany') {
      label = 'Azienda';
    } else if (role === 'buildRenovateCondominium') {
      label = 'Amministratore condominio';
    } else {
      label = 'Utente';
    }
    return label;
  }
}
