<div class="container_dialog" fxLayout="column" (close)="dialog.closeAll()">
  <div>
    <label class="title">Informazioni per associare correttamente un wallet</label>
  </div>
  <label class="subtitle"><a href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn">Aggiungi estensione metamask</a></label>
  <div fxLayout="column" fxLayoutGap="5px">
    <label class="subtitle">Dopo aver installato Metamask sul tuo computer esegui questi step per creare un nuovo wallet:</label>
    <label class="subtitle">1) Clicca sulla favicon (l'immagine circolare dell'account) in alto a destra nel tuo portafoglio se stai usando il plugin Metamask di Google Chrome.</label>
    <label class="subtitle">2) Fai clic su "Crea account".</label>
    <label class="subtitle">3) Inserisci il tuo nome preferito e premi "Crea" per confermare.</label>
  </div>
  <div fxLayout="column" fxLayoutGap="10px">
    <label class="subtitle">Fai riferimento alla documentazione di Metamask per qualsiasi informazione ad esso relativa.</label>
  </div>
  <div fxLayout="column" fxLayoutGap="10px">
    <label class="subtitle"><a href="https://support.metamask.io/hc/en-us/articles/360015289452-How-to-create-an-additional-account-in-your-wallet">Documentazione Metamask</a></label>
  </div>
</div>
