<div class="sidenav-container">
  <div class="sidenav-container_top" fxLayout="column" fxLayoutGap="10px">
    <div class="container_logo" fxLayout="row" fxLayoutGap="10px">
      <img
        src="assets/image/logo/complete_logo.svg"
        alt=""
        class="image"
      />
    </div>
    <div style="padding-right: 20px">
      <div *ngIf="typeUser === 'admin'">
        <div
          *ngIf="typeRouter === 'buy'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('home')"
          [routerLink]="'/dashboard/buy/home'"
        >
          <div
            [ngClass]="
              page === 'home'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'home' ? 'container_text_blue' : 'container_text'
            "
          >
            <img
              [src]="
                page === 'home'
                  ? '../../../assets/image/dashboard/icon-sidenav/home-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/home-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label [ngClass]="page === 'home' ? 'title_blue' : 'title_gray'"
              >Home</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'buy'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('reserved')"
          [routerLink]="'/dashboard/buy/reserved'"
        >
          <div
            [ngClass]="
              page === 'reserved'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'reserved' ? 'container_text_blue' : 'container_text'
            "
          >
            <img
              [src]="
                page === 'reserved'
                  ? '../../../assets/image/dashboard/icon-sidenav/time-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/time-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label [ngClass]="page === 'reserved' ? 'title_blue' : 'title_gray'"
              >Scheda visite</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'buy'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('escrow')"
          [routerLink]="'/dashboard/buy/escrow'"
        >
          <div
            [ngClass]="
              page === 'escrow'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'escrow' ? 'container_text_blue' : 'container_text'
            "
          >
            <img
              [src]="
                page === 'escrow'
                  ? '../../../assets/image/dashboard/icon-sidenav/escrow-paid-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/escrow-paid-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label [ngClass]="page === 'escrow' ? 'title_blue' : 'title_gray'"
              >Caparre</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'buy'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('complex-published')"
          [routerLink]="'/dashboard/buy/complex-published'"
        >
          <div
            [ngClass]="
              page === 'complex-published'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'complex-published'
                ? 'container_text_blue'
                : 'container_text'
            "
          >
            <img
              [src]="
                page === 'complex-published'
                  ? '../../../assets/image/dashboard/icon-sidenav/complex-published-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/complex-published-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label
              [ngClass]="
                page === 'complex-published' ? 'title_blue' : 'title_gray'
              "
              >Complessi</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'buy'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('complex-buy')"
          [routerLink]="'/dashboard/buy/complex-buy'"
        >
          <div
            [ngClass]="
              page === 'complex-buy'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'complex-buy' ? 'container_text_blue' : 'container_text'
            "
          >
            <img
              [src]="
                page === 'complex-buy'
                  ? '../../../assets/image/dashboard/icon-sidenav/local-mall-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/local-mall-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label
              [ngClass]="page === 'complex-buy' ? 'title_blue' : 'title_gray'"
              >Vendute</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'buy'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('user')"
          [routerLink]="'/dashboard/buy/user'"
        >
          <div
            [ngClass]="
              page === 'user'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'user' ? 'container_text_blue' : 'container_text'
            "
          >
            <img
              [src]="
                page === 'user'
                  ? '../../../assets/image/dashboard/icon-sidenav/user-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/user-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label [ngClass]="page === 'user' ? 'title_blue' : 'title_gray'"
              >Utenti</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'buy'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('requests-withdraw')"
          [routerLink]="'/dashboard/buy/requests-withdraw'"
        >
          <div
            [ngClass]="
              page === 'requests-withdraw'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'requests-withdraw'
                ? 'container_text_blue'
                : 'container_text'
            "
          >
            <img
              [src]="
                page === 'requests-withdraw'
                  ? '../../../assets/image/dashboard/icon-sidenav/requests-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/requests-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label
              [ngClass]="
                page === 'requests-withdraw' ? 'title_blue' : 'title_gray'
              "
              >Richieste prelievo</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'buy'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('requests-deposit')"
          [routerLink]="'/dashboard/buy/requests-deposit'"
        >
          <div
            [ngClass]="
              page === 'requests-deposit'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'requests-deposit'
                ? 'container_text_blue'
                : 'container_text'
            "
          >
            <img
              [src]="
                page === 'requests-deposit'
                  ? '../../../assets/image/dashboard/icon-sidenav/requests-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/requests-gray.svg'
              "
              alt=""
              class="icon-reverse"
            />
            <label
              [ngClass]="
                page === 'requests-deposit' ? 'title_blue' : 'title_gray'
              "
              >Richieste deposito</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'buy'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('admin-wallet')"
          [routerLink]="'/dashboard/buy/admin-wallet'"
        >
          <div
            [ngClass]="
              page === 'admin-wallet'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'admin-wallet' ? 'container_text_blue' : 'container_text'
            "
          >
            <img
              [src]="
                page === 'admin-wallet'
                  ? '../../../assets/image/dashboard/icon-sidenav/wallet-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/wallet-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label
              [ngClass]="page === 'admin-wallet' ? 'title_blue' : 'title_gray'"
              >Wallet</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'buy'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('admin-crypto-wallet')"
          [routerLink]="'/dashboard/buy/admin-crypto-wallet'"
        >
          <div
            [ngClass]="
              page === 'admin-crypto-wallet'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'admin-crypto-wallet'
                ? 'container_text_blue'
                : 'container_text'
            "
          >
            <img
              [src]="
                page === 'admin-crypto-wallet'
                  ? '../../../assets/image/dashboard/icon-sidenav/wallet-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/wallet-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label
              [ngClass]="
                page === 'admin-crypto-wallet' ? 'title_blue' : 'title_gray'
              "
              >Crypto wallet</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'invest'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('home')"
          [routerLink]="'/dashboard/invest/home'"
        >
          <div
            [ngClass]="
              page === 'home'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'home' ? 'container_text_blue' : 'container_text'
            "
          >
            <img
              [src]="
                page === 'home'
                  ? '../../../assets/image/dashboard/icon-sidenav/home-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/home-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label [ngClass]="page === 'home' ? 'title_blue' : 'title_gray'"
              >Home</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'invest'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('project')"
          [routerLink]="'/dashboard/invest/project'"
        >
          <div
            [ngClass]="
              page === 'project'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'project' ? 'container_text_blue' : 'container_text'
            "
          >
            <img
              [src]="
                page === 'project'
                  ? '../../../assets/image/dashboard/icon-sidenav/complex-published-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/complex-published-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label [ngClass]="page === 'project' ? 'title_blue' : 'title_gray'"
              >Progetti</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'invest'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('admin-proposals')"
          [routerLink]="'/dashboard/invest/admin-proposals'"
        >
          <div
            [ngClass]="
              page === 'admin-proposals'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'admin-proposals'
                ? 'container_text_blue'
                : 'container_text'
            "
          >
            <img
              [src]="
                page === 'admin-proposals'
                  ? '../../../assets/image/dashboard/icon-sidenav/work-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/work-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label
              [ngClass]="
                page === 'admin-proposals' ? 'title_blue' : 'title_gray'
              "
              >Proposte progetti</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'invest'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('admin-voting-proposals')"
          [routerLink]="'/dashboard/invest/admin-voting-proposals'"
        >
          <div
            [ngClass]="
              page === 'admin-voting-proposals'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'admin-voting-proposals'
                ? 'container_text_blue'
                : 'container_text'
            "
          >
            <img
              [src]="
                page === 'admin-voting-proposals'
                  ? '../../../assets/image/dashboard/icon-sidenav/work-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/work-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label
              [ngClass]="
                page === 'admin-voting-proposals' ? 'title_blue' : 'title_gray'
              "
              >Proposte voting</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'invest'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('requests-withdraw')"
          [routerLink]="'/dashboard/invest/requests-withdraw'"
        >
          <div
            [ngClass]="
              page === 'requests-withdraw'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'requests-withdraw'
                ? 'container_text_blue'
                : 'container_text'
            "
          >
            <img
              [src]="
                page === 'requests-withdraw'
                  ? '../../../assets/image/dashboard/icon-sidenav/requests-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/requests-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label
              [ngClass]="
                page === 'requests-withdraw' ? 'title_blue' : 'title_gray'
              "
              >Richieste prelievo</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'invest'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('requests-deposit')"
          [routerLink]="'/dashboard/invest/requests-deposit'"
        >
          <div
            [ngClass]="
              page === 'requests-deposit'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'requests-deposit'
                ? 'container_text_blue'
                : 'container_text'
            "
          >
            <img
              [src]="
                page === 'requests-deposit'
                  ? '../../../assets/image/dashboard/icon-sidenav/requests-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/requests-gray.svg'
              "
              alt=""
              class="icon-reverse"
            />
            <label
              [ngClass]="
                page === 'requests-deposit' ? 'title_blue' : 'title_gray'
              "
              >Richieste deposito</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'invest'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('user')"
          [routerLink]="'/dashboard/invest/user'"
        >
          <div
            [ngClass]="
              page === 'user'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'user' ? 'container_text_blue' : 'container_text'
            "
          >
            <img
              [src]="
                page === 'user'
                  ? '../../../assets/image/dashboard/icon-sidenav/user-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/user-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label [ngClass]="page === 'user' ? 'title_blue' : 'title_gray'"
              >Utenti</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'invest'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('admin-voting')"
          [routerLink]="'/dashboard/invest/admin-voting'"
        >
          <div
            [ngClass]="
              page === 'admin-voting'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'admin-voting' ? 'container_text_blue' : 'container_text'
            "
          >
            <img
              [src]="
                page === 'admin-voting'
                  ? '../../../assets/image/dashboard/icon-sidenav/azm-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/azm-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label
              [ngClass]="page === 'admin-voting' ? 'title_blue' : 'title_gray'"
              >Voting</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'invest'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('admin-wallet')"
          [routerLink]="'/dashboard/invest/admin-wallet'"
        >
          <div
            [ngClass]="
              page === 'admin-wallet'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'admin-wallet' ? 'container_text_blue' : 'container_text'
            "
          >
            <img
              [src]="
                page === 'admin-wallet'
                  ? '../../../assets/image/dashboard/icon-sidenav/wallet-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/wallet-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label
              [ngClass]="page === 'admin-wallet' ? 'title_blue' : 'title_gray'"
              >Wallet</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'invest'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('admin-crypto-wallet')"
          [routerLink]="'/dashboard/invest/admin-crypto-wallet'"
        >
          <div
            [ngClass]="
              page === 'admin-crypto-wallet'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'admin-crypto-wallet'
                ? 'container_text_blue'
                : 'container_text'
            "
          >
            <img
              [src]="
                page === 'admin-crypto-wallet'
                  ? '../../../assets/image/dashboard/icon-sidenav/wallet-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/wallet-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label
              [ngClass]="
                page === 'admin-crypto-wallet' ? 'title_blue' : 'title_gray'
              "
              >Crypto wallet</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'build'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('home')"
          [routerLink]="'/dashboard/build/admin/home'"
        >
          <div
            [ngClass]="
              page === 'home'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'home' ? 'container_text_blue' : 'container_text'
            "
          >
            <img
              [src]="
                page === 'home'
                  ? '../../../assets/image/dashboard/icon-sidenav/home-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/home-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label [ngClass]="page === 'home' ? 'title_blue' : 'title_gray'"
              >Home</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'build'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('practices')"
          [routerLink]="'/dashboard/build/admin/practices'"
        >
          <div
            [ngClass]="
              page === 'practices'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'practices' ? 'container_text_blue' : 'container_text'
            "
          >
            <img
              [src]="
                page === 'practices'
                  ? '../../../assets/image/dashboard/icon-sidenav/pratiche-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/pratiche-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label
              [ngClass]="page === 'practices' ? 'title_blue' : 'title_gray'"
              >Pratiche</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'build'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('technician')"
          [routerLink]="'/dashboard/build/admin/technician'"
        >
          <div
            [ngClass]="
              page === 'technician'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'technician' ? 'container_text_blue' : 'container_text'
            "
          >
            <img
              [src]="
                page === 'technician'
                  ? '../../../assets/image/dashboard/icon-sidenav/tecnico-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/tecnico-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label
              [ngClass]="page === 'technician' ? 'title_blue' : 'title_gray'"
              >Tecnici Hauses</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'build'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('user')"
          [routerLink]="'/dashboard/build/admin/user'"
        >
          <div
            [ngClass]="
              page === 'user'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'user' ? 'container_text_blue' : 'container_text'
            "
          >
            <img
              [src]="
                page === 'user'
                  ? '../../../assets/image/dashboard/icon-sidenav/user-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/user-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label [ngClass]="page === 'user' ? 'title_blue' : 'title_gray'"
              >Utenti</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'build'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('requests-withdraw')"
          [routerLink]="'/dashboard/build/admin/requests-withdraw'"
        >
          <div
            [ngClass]="
              page === 'requests-withdraw'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'requests-withdraw'
                ? 'container_text_blue'
                : 'container_text'
            "
          >
            <img
              [src]="
                page === 'requests-withdraw'
                  ? '../../../assets/image/dashboard/icon-sidenav/requests-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/requests-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label
              [ngClass]="
                page === 'requests-withdraw' ? 'title_blue' : 'title_gray'
              "
              >Richieste prelievo</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'build'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('requests-deposit')"
          [routerLink]="'/dashboard/build/admin/requests-deposit'"
        >
          <div
            [ngClass]="
              page === 'requests-deposit'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'requests-deposit'
                ? 'container_text_blue'
                : 'container_text'
            "
          >
            <img
              [src]="
                page === 'requests-deposit'
                  ? '../../../assets/image/dashboard/icon-sidenav/requests-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/requests-gray.svg'
              "
              alt=""
              class="icon-reverse"
            />
            <label
              [ngClass]="
                page === 'requests-deposit' ? 'title_blue' : 'title_gray'
              "
              >Richieste deposito</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'build'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('admin-wallet')"
          [routerLink]="'/dashboard/build/admin/admin-wallet'"
        >
          <div
            [ngClass]="
              page === 'admin-wallet'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'admin-wallet' ? 'container_text_blue' : 'container_text'
            "
          >
            <img
              [src]="
                page === 'admin-wallet'
                  ? '../../../assets/image/dashboard/icon-sidenav/wallet-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/wallet-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label
              [ngClass]="page === 'admin-wallet' ? 'title_blue' : 'title_gray'"
              >Wallet</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'build'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('admin-crypto-wallet')"
          [routerLink]="'/dashboard/build/admin/admin-crypto-wallet'"
        >
          <div
            [ngClass]="
              page === 'admin-crypto-wallet'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'admin-crypto-wallet'
                ? 'container_text_blue'
                : 'container_text'
            "
          >
            <img
              [src]="
                page === 'admin-crypto-wallet'
                  ? '../../../assets/image/dashboard/icon-sidenav/wallet-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/wallet-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label
              [ngClass]="
                page === 'admin-crypto-wallet' ? 'title_blue' : 'title_gray'
              "
              >Crypto wallet</label
            >
          </div>
        </div>
      </div>
      <div *ngIf="typeUser === 'user'">
        <div
          *ngIf="typeRouter === 'buy'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('home')"
          [routerLink]="'/dashboard/buy/home'"
        >
          <div
            [ngClass]="
              page === 'home'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'home' ? 'container_text_blue' : 'container_text'
            "
          >
            <img
              [src]="
                page === 'home'
                  ? '../../../assets/image/dashboard/icon-sidenav/home-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/home-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label [ngClass]="page === 'home' ? 'title_blue' : 'title_gray'"
              >Home</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'buy'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('reservation')"
          [routerLink]="'/dashboard/buy/reservation'"
        >
          <div
            [ngClass]="
              page === 'reservation'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'reservation' ? 'container_text_blue' : 'container_text'
            "
          >
            <img
              [src]="
                page === 'reservation'
                  ? '../../../assets/image/dashboard/icon-sidenav/calendar-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/calendar-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label
              [ngClass]="page === 'reservation' ? 'title_blue' : 'title_gray'"
              >Visite prenotate</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'buy'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('deposit-paid')"
          [routerLink]="'/dashboard/buy/deposit-paid'"
        >
          <div
            [ngClass]="
              page === 'deposit-paid'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'deposit-paid' ? 'container_text_blue' : 'container_text'
            "
          >
            <img
              [src]="
                page === 'deposit-paid'
                  ? '../../../assets/image/dashboard/icon-sidenav/local-mall-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/local-mall-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label
              [ngClass]="page === 'deposit-paid' ? 'title_blue' : 'title_gray'"
              >Caparre</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'buy'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('apartment-purchased')"
          [routerLink]="'/dashboard/buy/apartment-purchased'"
        >
          <div
            [ngClass]="
              page === 'apartment-purchased'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'apartment-purchased'
                ? 'container_text_blue'
                : 'container_text'
            "
          >
            <img
              [src]="
                page === 'apartment-purchased'
                  ? '../../../assets/image/dashboard/icon-sidenav/complex-published-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/complex-published-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label
              [ngClass]="
                page === 'apartment-purchased' ? 'title_blue' : 'title_gray'
              "
              >Acquistate</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'buy'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('wallet')"
          [routerLink]="'/dashboard/buy/my-wallet'"
        >
          <div
            [ngClass]="
              page === 'my-wallet'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'my-wallet' ? 'container_text_blue' : 'container_text'
            "
          >
            <img
              [src]="
                page === 'my-wallet'
                  ? '../../../assets/image/dashboard/icon-sidenav/wallet-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/wallet-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label
              [ngClass]="page === 'my-wallet' ? 'title_blue' : 'title_gray'"
              >Wallet</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'buy'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('crypto')"
          [routerLink]="'/dashboard/buy/crypto-wallet'"
        >
          <div
            [ngClass]="
              page === 'crypto-wallet'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'crypto-wallet'
                ? 'container_text_blue'
                : 'container_text'
            "
          >
            <img
              [src]="
                page === 'crypto-wallet'
                  ? '../../../assets/image/dashboard/icon-sidenav/wallet-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/wallet-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label
              [ngClass]="page === 'crypto-wallet' ? 'title_blue' : 'title_gray'"
              >Crypto wallet</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'invest'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('home')"
          [routerLink]="'/dashboard/invest/home'"
        >
          <div
            [ngClass]="
              page === 'home'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'home' ? 'container_text_blue' : 'container_text'
            "
          >
            <img
              [src]="
                page === 'home'
                  ? '../../../assets/image/dashboard/icon-sidenav/home-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/home-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label [ngClass]="page === 'home' ? 'title_blue' : 'title_gray'"
              >Home</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'invest'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('my-investments')"
          [routerLink]="'/dashboard/invest/my-investments'"
        >
          <div
            [ngClass]="
              page === 'my-investments'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'my-investments'
                ? 'container_text_blue'
                : 'container_text'
            "
          >
            <img
              [src]="
                page === 'my-investments'
                  ? '../../../assets/image/dashboard/icon-sidenav/work-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/work-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label
              [ngClass]="
                page === 'my-investments' ? 'title_blue' : 'title_gray'
              "
              >I tuoi investimenti</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'invest'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('my-proposals')"
          [routerLink]="'/dashboard/invest/my-proposals'"
        >
          <div
            [ngClass]="
              page === 'my-proposals'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'my-proposals' ? 'container_text_blue' : 'container_text'
            "
          >
            <img
              [src]="
                page === 'my-proposals'
                  ? '../../../assets/image/dashboard/icon-sidenav/work-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/work-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label
              [ngClass]="page === 'my-proposals' ? 'title_blue' : 'title_gray'"
              >Proposte</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'invest'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('voting')"
          [routerLink]="'/dashboard/invest/voting'"
        >
          <div
            [ngClass]="
              page === 'voting'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'voting' ? 'container_text_blue' : 'container_text'
            "
          >
            <img
              [src]="
                page === 'voting'
                  ? '../../../assets/image/dashboard/icon-sidenav/azm-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/azm-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label [ngClass]="page === 'voting' ? 'title_blue' : 'title_gray'"
              >Voting</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'invest'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('my-wallet')"
          [routerLink]="'/dashboard/invest/my-wallet'"
        >
          <div
            [ngClass]="
              page === 'my-wallet'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'my-wallet' ? 'container_text_blue' : 'container_text'
            "
          >
            <img
              [src]="
                page === 'my-wallet'
                  ? '../../../assets/image/dashboard/icon-sidenav/wallet-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/wallet-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label
              [ngClass]="page === 'my-wallet' ? 'title_blue' : 'title_gray'"
              >Wallet</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'invest'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('crypto')"
          [routerLink]="'/dashboard/invest/crypto-wallet'"
        >
          <div
            [ngClass]="
              page === 'crypto-wallet'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'crypto-wallet'
                ? 'container_text_blue'
                : 'container_text'
            "
          >
            <img
              [src]="
                page === 'crypto-wallet'
                  ? '../../../assets/image/dashboard/icon-sidenav/wallet-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/wallet-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label
              [ngClass]="page === 'crypto-wallet' ? 'title_blue' : 'title_gray'"
              >Crypto wallet</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'invest'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('marketplace')"
          [routerLink]="'/dashboard/invest/marketplace'"
        >
          <div
            [ngClass]="
              page === 'marketplace'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'marketplace' ? 'container_text_blue' : 'container_text'
            "
          >
            <img
              [src]="
                page === 'marketplace'
                  ? '../../../assets/image/dashboard/icon-sidenav/store-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/store-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label
              [ngClass]="page === 'marketplace' ? 'title_blue' : 'title_gray'"
              >Marketplace</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'build'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('home')"
          [routerLink]="'/dashboard/build/user/home'"
        >
          <div
            [ngClass]="
              page === 'home'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'home' ? 'container_text_blue' : 'container_text'
            "
          >
            <img
              [src]="
                page === 'home'
                  ? '../../../assets/image/dashboard/icon-sidenav/home-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/home-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label [ngClass]="page === 'home' ? 'title_blue' : 'title_gray'"
              >Home</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'build'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('my_practices')"
          [routerLink]="'/dashboard/build/user/my_practices'"
        >
          <div
            [ngClass]="
              page === 'my_practices'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'my_practices' ? 'container_text_blue' : 'container_text'
            "
          >
            <img
              [src]="
                page === 'my_practices'
                  ? '../../../assets/image/dashboard/icon-sidenav/work-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/work-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label
              [ngClass]="page === 'my_practices' ? 'title_blue' : 'title_gray'"
              >Pratiche</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'build'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('list_consultant')"
          [routerLink]="'/dashboard/build/user/list_consultant'"
        >
          <div
            [ngClass]="
              page === 'list_consultant'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'list_consultant'
                ? 'container_text_blue'
                : 'container_text'
            "
          >
            <img
              [src]="
                page === 'list_consultant'
                  ? '../../../assets/image/dashboard/icon-sidenav/user-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/user-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label
              [ngClass]="
                page === 'list_consultant' ? 'title_blue' : 'title_gray'
              "
              >Lista consulenti</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'build'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('wallet')"
          [routerLink]="'/dashboard/build/user/my-wallet'"
        >
          <div
            [ngClass]="
              page === 'my-wallet'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'my-wallet' ? 'container_text_blue' : 'container_text'
            "
          >
            <img
              [src]="
                page === 'my-wallet'
                  ? '../../../assets/image/dashboard/icon-sidenav/wallet-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/wallet-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label
              [ngClass]="page === 'my-wallet' ? 'title_blue' : 'title_gray'"
              >Wallet</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'build'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('crypto')"
          [routerLink]="'/dashboard/build/user/crypto-wallet'"
        >
          <div
            [ngClass]="
              page === 'crypto-wallet'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'crypto-wallet'
                ? 'container_text_blue'
                : 'container_text'
            "
          >
            <img
              [src]="
                page === 'crypto-wallet'
                  ? '../../../assets/image/dashboard/icon-sidenav/wallet-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/wallet-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label
              [ngClass]="page === 'crypto-wallet' ? 'title_blue' : 'title_gray'"
              >Crypto wallet</label
            >
          </div>
        </div>
      </div>
      <div *ngIf="typeUser === 'buildRenovateCondominium'">
        <div
          *ngIf="typeRouter === 'build'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('home')"
          [routerLink]="'/dashboard/build/condominium/home'"
        >
          <div
            [ngClass]="
              page === 'home'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'home' ? 'container_text_blue' : 'container_text'
            "
          >
            <img
              [src]="
                page === 'home'
                  ? '../../../assets/image/dashboard/icon-sidenav/home-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/home-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label [ngClass]="page === 'home' ? 'title_blue' : 'title_gray'"
              >Home</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'build'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('my_practices')"
          [routerLink]="'/dashboard/build/condominium/my_practices'"
        >
          <div
            [ngClass]="
              page === 'my_practices'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'my_practices' ? 'container_text_blue' : 'container_text'
            "
          >
            <img
              [src]="
                page === 'my_practices'
                  ? '../../../assets/image/dashboard/icon-sidenav/work-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/work-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label
              [ngClass]="page === 'my_practices' ? 'title_blue' : 'title_gray'"
              >Pratiche</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'build'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('list_consultant')"
          [routerLink]="'/dashboard/build/condominium/list_consultant'"
        >
          <div
            [ngClass]="
              page === 'list_consultant'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'list_consultant'
                ? 'container_text_blue'
                : 'container_text'
            "
          >
            <img
              [src]="
                page === 'list_consultant'
                  ? '../../../assets/image/dashboard/icon-sidenav/user-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/user-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label
              [ngClass]="
                page === 'list_consultant' ? 'title_blue' : 'title_gray'
              "
              >Lista consulenti</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'build'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('wallet')"
          [routerLink]="'/dashboard/build/condominium/my-wallet'"
        >
          <div
            [ngClass]="
              page === 'my-wallet'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'my-wallet' ? 'container_text_blue' : 'container_text'
            "
          >
            <img
              [src]="
                page === 'my-wallet'
                  ? '../../../assets/image/dashboard/icon-sidenav/wallet-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/wallet-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label
              [ngClass]="page === 'my-wallet' ? 'title_blue' : 'title_gray'"
              >Wallet</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'build'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('crypto')"
          [routerLink]="'/dashboard/build/condominium/crypto-wallet'"
        >
          <div
            [ngClass]="
              page === 'crypto-wallet'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'crypto-wallet'
                ? 'container_text_blue'
                : 'container_text'
            "
          >
            <img
              [src]="
                page === 'crypto-wallet'
                  ? '../../../assets/image/dashboard/icon-sidenav/wallet-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/wallet-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label
              [ngClass]="page === 'crypto-wallet' ? 'title_blue' : 'title_gray'"
              >Crypto wallet</label
            >
          </div>
        </div>
      </div>
      <div *ngIf="typeUser === 'technicianHauses'">
        <div
          *ngIf="typeRouter === 'build'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('practices')"
          [routerLink]="'/dashboard/build/technician/practices'"
        >
          <div
            [ngClass]="
              page === 'practices'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'practices' ? 'container_text_blue' : 'container_text'
            "
          >
            <img
              [src]="
                page === 'practices'
                  ? '../../../assets/image/dashboard/icon-sidenav/home-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/home-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label
              [ngClass]="page === 'practices' ? 'title_blue' : 'title_gray'"
              >Pratiche</label
            >
          </div>
        </div>
      </div>
      <div *ngIf="typeUser === 'buildRenovateConsultant'">
        <div
          *ngIf="typeRouter === 'build'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('home')"
          [routerLink]="'/dashboard/build/consultant/home'"
        >
          <div
            [ngClass]="
              page === 'home'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'home' ? 'container_text_blue' : 'container_text'
            "
          >
            <img
              [src]="
                page === 'home'
                  ? '../../../assets/image/dashboard/icon-sidenav/home-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/home-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label [ngClass]="page === 'home' ? 'title_blue' : 'title_gray'"
              >Home</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'build'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('my_consulting')"
          [routerLink]="'/dashboard/build/consultant/my_consulting'"
        >
          <div
            [ngClass]="
              page === 'my_consulting'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'my_consulting'
                ? 'container_text_blue'
                : 'container_text'
            "
          >
            <img
              [src]="
                page === 'my_consulting'
                  ? '../../../assets/image/dashboard/icon-sidenav/work-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/work-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label
              [ngClass]="page === 'my_consulting' ? 'title_blue' : 'title_gray'"
              >Pratiche</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'build'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('consulting_requests')"
          [routerLink]="'/dashboard/build/consultant/consulting_requests'"
        >
          <div
            [ngClass]="
              page === 'consulting_requests'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'consulting_requests'
                ? 'container_text_blue'
                : 'container_text'
            "
          >
            <img
              [src]="
                page === 'consulting_requests'
                  ? '../../../assets/image/dashboard/icon-sidenav/user-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/user-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label
              [ngClass]="
                page === 'consulting_requests' ? 'title_blue' : 'title_gray'
              "
              >Richieste consulenze</label
            >
          </div>
        </div>
      </div>
      <div *ngIf="typeUser === 'buildRenovateCompany'">
        <div
          *ngIf="typeRouter === 'build'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('home')"
          [routerLink]="'/dashboard/build/company/home'"
        >
          <div
            [ngClass]="
              page === 'home'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'home' ? 'container_text_blue' : 'container_text'
            "
          >
            <img
              [src]="
                page === 'home'
                  ? '../../../assets/image/dashboard/icon-sidenav/home-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/home-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label [ngClass]="page === 'home' ? 'title_blue' : 'title_gray'"
              >Home</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'build'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('practices')"
          [routerLink]="'/dashboard/build/company/practices'"
        >
          <div
            [ngClass]="
              page === 'practices'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'practices' ? 'container_text_blue' : 'container_text'
            "
          >
            <img
              [src]="
                page === 'practices'
                  ? '../../../assets/image/dashboard/icon-sidenav/work-published-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/work-published-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label
              [ngClass]="page === 'practices' ? 'title_blue' : 'title_gray'"
              >Lavori disponibili</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'build'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('proposal')"
          [routerLink]="'/dashboard/build/company/proposal'"
        >
          <div
            [ngClass]="
              page === 'proposal'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'proposal' ? 'container_text_blue' : 'container_text'
            "
          >
            <img
              [src]="
                page === 'proposal'
                  ? '../../../assets/image/dashboard/icon-sidenav/user-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/user-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label [ngClass]="page === 'proposal' ? 'title_blue' : 'title_gray'"
              >Offerte</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'build'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('wallet')"
          [routerLink]="'/dashboard/build/company/my-wallet'"
        >
          <div
            [ngClass]="
              page === 'my-wallet'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'my-wallet' ? 'container_text_blue' : 'container_text'
            "
          >
            <img
              [src]="
                page === 'my-wallet'
                  ? '../../../assets/image/dashboard/icon-sidenav/wallet-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/wallet-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label
              [ngClass]="page === 'my-wallet' ? 'title_blue' : 'title_gray'"
              >Wallet</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'build'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('crypto')"
          [routerLink]="'/dashboard/build/company/crypto-wallet'"
        >
          <div
            [ngClass]="
              page === 'crypto-wallet'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'crypto-wallet'
                ? 'container_text_blue'
                : 'container_text'
            "
          >
            <img
              [src]="
                page === 'crypto-wallet'
                  ? '../../../assets/image/dashboard/icon-sidenav/wallet-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/wallet-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label
              [ngClass]="page === 'crypto-wallet' ? 'title_blue' : 'title_gray'"
              >Crypto wallet</label
            >
          </div>
        </div>
      </div>
      <div *ngIf="typeUser === 'buildRenovateSupplier'">
        <div
          *ngIf="typeRouter === 'build'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('home')"
          [routerLink]="'/dashboard/build/supplier/home'"
        >
          <div
            [ngClass]="
              page === 'home'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'home' ? 'container_text_blue' : 'container_text'
            "
          >
            <img
              [src]="
                page === 'home'
                  ? '../../../assets/image/dashboard/icon-sidenav/home-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/home-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label [ngClass]="page === 'home' ? 'title_blue' : 'title_gray'"
              >Home</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'build'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('practices')"
          [routerLink]="'/dashboard/build/supplier/practices'"
        >
          <div
            [ngClass]="
              page === 'practices'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'practices' ? 'container_text_blue' : 'container_text'
            "
          >
            <img
              [src]="
                page === 'practices'
                  ? '../../../assets/image/dashboard/icon-sidenav/work-published-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/work-published-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label
              [ngClass]="page === 'practices' ? 'title_blue' : 'title_gray'"
              >Lavori disponibili</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'build'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('proposal')"
          [routerLink]="'/dashboard/build/supplier/proposal'"
        >
          <div
            [ngClass]="
              page === 'proposal'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'proposal' ? 'container_text_blue' : 'container_text'
            "
          >
            <img
              [src]="
                page === 'proposal'
                  ? '../../../assets/image/dashboard/icon-sidenav/user-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/user-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label [ngClass]="page === 'proposal' ? 'title_blue' : 'title_gray'"
              >Offerte</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'build'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('wallet')"
          [routerLink]="'/dashboard/build/supplier/my-wallet'"
        >
          <div
            [ngClass]="
              page === 'my-wallet'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'my-wallet' ? 'container_text_blue' : 'container_text'
            "
          >
            <img
              [src]="
                page === 'my-wallet'
                  ? '../../../assets/image/dashboard/icon-sidenav/wallet-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/wallet-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label
              [ngClass]="page === 'my-wallet' ? 'title_blue' : 'title_gray'"
              >Wallet</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'build'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('crypto')"
          [routerLink]="'/dashboard/build/supplier/crypto-wallet'"
        >
          <div
            [ngClass]="
              page === 'crypto-wallet'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'crypto-wallet'
                ? 'container_text_blue'
                : 'container_text'
            "
          >
            <img
              [src]="
                page === 'crypto-wallet'
                  ? '../../../assets/image/dashboard/icon-sidenav/wallet-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/wallet-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label
              [ngClass]="page === 'crypto-wallet' ? 'title_blue' : 'title_gray'"
              >Crypto wallet</label
            >
          </div>
        </div>
      </div>
      <div *ngIf="typeUser === 'buildRenovateGeneralContractor'">
        <div
          *ngIf="typeRouter === 'build'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('home')"
          [routerLink]="'/dashboard/build/contractor/home'"
        >
          <div
            [ngClass]="
              page === 'home'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'home' ? 'container_text_blue' : 'container_text'
            "
          >
            <img
              [src]="
                page === 'home'
                  ? '../../../assets/image/dashboard/icon-sidenav/home-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/home-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label [ngClass]="page === 'home' ? 'title_blue' : 'title_gray'"
              >Home</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'build'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('practices')"
          [routerLink]="'/dashboard/build/contractor/practices'"
        >
          <div
            [ngClass]="
              page === 'practices'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'practices' ? 'container_text_blue' : 'container_text'
            "
          >
            <img
              [src]="
                page === 'practices'
                  ? '../../../assets/image/dashboard/icon-sidenav/work-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/work-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label
              [ngClass]="page === 'practices' ? 'title_blue' : 'title_gray'"
              >Pratiche</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'build'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('published')"
          [routerLink]="'/dashboard/build/contractor/published'"
        >
          <div
            [ngClass]="
              page === 'published'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'published' ? 'container_text_blue' : 'container_text'
            "
          >
            <img
              [src]="
                page === 'published'
                  ? '../../../assets/image/dashboard/icon-sidenav/work-published-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/work-published-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label
              [ngClass]="page === 'published' ? 'title_blue' : 'title_gray'"
              >Lavori disponibili</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'build'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('proposal')"
          [routerLink]="'/dashboard/build/contractor/proposal'"
        >
          <div
            [ngClass]="
              page === 'proposal'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'proposal' ? 'container_text_blue' : 'container_text'
            "
          >
            <img
              [src]="
                page === 'proposal'
                  ? '../../../assets/image/dashboard/icon-sidenav/user-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/user-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label [ngClass]="page === 'proposal' ? 'title_blue' : 'title_gray'"
              >Offerte</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'build'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('wallet')"
          [routerLink]="'/dashboard/build/contractor/my-wallet'"
        >
          <div
            [ngClass]="
              page === 'my-wallet'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'my-wallet' ? 'container_text_blue' : 'container_text'
            "
          >
            <img
              [src]="
                page === 'my-wallet'
                  ? '../../../assets/image/dashboard/icon-sidenav/wallet-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/wallet-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label
              [ngClass]="page === 'my-wallet' ? 'title_blue' : 'title_gray'"
              >Wallet</label
            >
          </div>
        </div>
        <div
          *ngIf="typeRouter === 'build'"
          class="container_link"
          fxLayout="row"
          fxLayoutGap="10px"
          (click)="goToPage('crypto')"
          [routerLink]="'/dashboard/build/contractor/crypto-wallet'"
        >
          <div
            [ngClass]="
              page === 'crypto-wallet'
                ? 'rectangle_left_link_selected'
                : 'rectangle_left_link'
            "
          ></div>
          <div
            [ngClass]="
              page === 'crypto-wallet'
                ? 'container_text_blue'
                : 'container_text'
            "
          >
            <img
              [src]="
                page === 'crypto-wallet'
                  ? '../../../assets/image/dashboard/icon-sidenav/wallet-blue.svg'
                  : '../../../assets/image/dashboard/icon-sidenav/wallet-gray.svg'
              "
              alt=""
              class="icon"
            />
            <label
              [ngClass]="page === 'crypto-wallet' ? 'title_blue' : 'title_gray'"
              >Crypto wallet</label
            >
          </div>
        </div>
      </div>
      <div
        class="container_link"
        fxLayout="row"
        fxLayoutGap="10px"
        (click)="goToPage('site')"
      >
        <div
          [ngClass]="
            page === 'site'
              ? 'rectangle_left_link_selected'
              : 'rectangle_left_link'
          "
        ></div>
        <div
          [ngClass]="page === 'site' ? 'container_text_blue' : 'container_text'"
        >
          <img
            [src]="
              page === 'site'
                ? '../../../assets/image/dashboard/icon-sidenav/earth-blue.svg'
                : '../../../assets/image/dashboard/icon-sidenav/earth-gray.svg'
            "
            alt=""
            class="icon"
          />
          <label
            [ngClass]="page === 'site' ? 'title_blue' : 'title_gray'"
            [routerLink]="'/home'"
            >Vai al sito</label
          >
        </div>
      </div>
    </div>
    <div
      class="container_services"
      fxLayout="column"
      fxLayoutAlign="space-between"
      fxLayoutGap="10px"
    >
      <div
        *ngIf="
          getGlobalVariables().isWalletConnected &&
          this.w3Address !== '' &&
          typeUser !== 'buildRenovateConsultant'
        "
        class="address_copy"
      >
        <img
          [matTooltipClass]="{ 'tool-tip': true }"
          [matTooltipPosition]="'above'"
          matTooltip="Copia Address"
          (click)="Copy(getGlobalVariables().wallet.address)"
          src="assets/image/dashboard/duplicate.svg"
          alt=""
          class="copy"
        />
        <label>{{ getGlobalVariables().wallet.addressShort }}</label>
        <div
          *ngIf="
            getGlobalVariables().wallet.address.toLowerCase() !==
              this.newAddress.toLowerCase() &&
            getGlobalVariables().wallet.address.toLowerCase() !==
              this.w3Address.toLowerCase()
          "
          class="point-red"
        ></div>
        <div
          *ngIf="
            getGlobalVariables().wallet.address.toLowerCase() ===
            this.newAddress.toLowerCase()
          "
          class="point-orange"
        ></div>
        <div
          *ngIf="
            getGlobalVariables().isWalletConnected &&
            getGlobalVariables().wallet.address === this.w3Address.toLowerCase()
          "
          class="point-green"
        ></div>
      </div>
      <div
        *ngIf="
          (!getGlobalVariables().isWalletConnected || this.w3Address === '') &&
          typeUser !== 'buildRenovateConsultant'
        "
        class="address_copy"
      >
        <label class="text_connect" (click)="geToProfile()"
          >Connetti wallet</label
        >
      </div>
      <form
        *ngIf="innerWidth <= 600"
        [formGroup]="selectForm"
        fxLayout="column"
        fxLayoutGap="20px"
      >
        <select
          id="how"
          class="styling_select"
          (change)="setSelect()"
          formControlName="select"
        >
          <option
            *ngFor="let o of option"
            value="{{
              o === 'Acquista'
                ? 'buy'
                : o === 'Costruisci/Ristruttura'
                ? 'build'
                : o === 'Investi'
                ? 'invest'
                : null
            }}"
            class="option"
          >
            {{ o }}
          </option>
        </select>
      </form>
      <div class="line"></div>
      <div id="select-open" fxLayout="column">
        <label class="text-select" (click)="openDashboard(innerWidth)">Cruscotto</label>
        <label class="text-select" (click)="closeDialog(innerWidth)">Profilo</label>
        <label class="text-select" (click)="Logout()">Logout</label>
      </div>
      <div *ngIf="this.image && this.name" fxLayout="row">
        <div fxLayout="column">
          <img [src]="image" alt="" class="icon_1" />
        </div>
        <div fxLayout="column" style="width: 60%" fxLayoutGap="5px">
          <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="5px">
            <label class="text_name">{{ name }}</label>
            <mat-icon class="icon_blue" (click)="openSelect()"
              >more_horiz</mat-icon
            >
          </div>
          <label class="text_type">{{ labelUser }}</label>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="center">
        <mat-spinner
          *ngIf="!this.image && !this.name"
          color="accent"
          [diameter]="20"
        ></mat-spinner>
      </div>
    </div>
  </div>
</div>
