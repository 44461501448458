<div class="container_dialog" fxLayout="column" (close)="dialog.closeAll()">
  <div>
    <label class="title">Informazioni per utilizzare correttamente lo Swap</label>
  </div>
  <div fxLayout="column" fxLayoutGap="5px">
    <label class="subtitle">In questa sezione è possibile effettuare lo swap (scambio) per convertire token presenti nel crypto wallet in Hauses Token (HT) oppure convertire Euro presenti nel Portafoglio Euro in Hauses Token (HT). In questo modo l'utente può ottenere i token utili ad operare all'interno della piattaforma. </label>
  </div>
  <div fxLayout="column" fxLayoutGap="5px">
    <label class="subtitle">Per conoscere il corrispettivo di Hauses Token (HT) che si riceverà, è sufficiente inserire un importo di Euro/Token nel box di sinistra per ottenere in tempo reale il controvalore in Hauses Token (HT) nel box di destra. Per procedere con l'operazione è sufficiente cliccare su "converti".</label>
  </div>
  <div fxLayout="column" fxLayoutGap="5px">
    <label class="subtitle">Per questo tipo di operazione è bene tener conto dello "slippage", ovvero la differenza che si registra tra il prezzo di acquisto o vendita di un asset all'immissione dell'ordine, e quello effettivo di esecuzione sul mercato. Attraverso l'apposita barra è possibile configurare il valore della tolleranza dello slippage che un utente vuole impostare per l'operazione di scambio.</label>
  </div>
  <div fxLayout="column" fxLayoutGap="5px">
    <label class="subtitle">In questa sezione è possibile effettuare lo swap (scambio) per convertire Hauses Token (HT) in Euro. Per conoscere il corrispettivo in Euro che si riceverà, è sufficiente inserire un importo di Hauses Token (HT) nel box di sinistra per ottenere in tempo reale il controvalore in Euro nel box di destra. Per procedere con l'operazione è sufficiente cliccare su "converti".</label>
  </div>
</div>
