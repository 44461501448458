import { Component, OnInit } from '@angular/core';
import {
  ChainId,
  GlobalVariables,
  NETWORK_INFO,
  NetworkService,
  WalletService,
} from '@scalingparrots/dapp-angular-lib';
import { PRIMARY_NETWORK } from '../card-connect-wallet/card-connect-wallet.component';
import { HomeService } from '../../core/service/home.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-wallet-not-connected',
  templateUrl: './wallet-not-connected.component.html',
  styleUrls: ['./wallet-not-connected.component.scss'],
})
export class WalletNotConnectedComponent implements OnInit {
  innerWidth: any;
  typeRouter: any;
  w3Address: any;
  primary_network =
    environment.primary_network === 'POLYGON'
      ? NETWORK_INFO[ChainId.Polygon]
      : PRIMARY_NETWORK;

  constructor(
    private _networkService: NetworkService,
    private _walletService: WalletService,
    private homeService: HomeService,
    private router: Router
  ) {
    this.getProvider();
  }

  ngOnInit(): void {
    this.innerWidth = innerWidth;
    const path = this.router.url.split('/');
    this.typeRouter = path[2];
  }

  goToProfile() {
    const path = this.router.url.split('/');
    if (this.typeRouter !== 'build') {
      this.router.navigate(['/dashboard/' + this.typeRouter + '/profile']);
    } else {
      this.router.navigate([
        '/dashboard/' + this.typeRouter + '/' + path[3] + '/profile',
      ]);
    }
  }
  async getProvider(): Promise<void> {
    await this._walletService.getWebProvider();
  }
  getGlobalVariables(): GlobalVariables {
    return this._walletService.getGlobalVariables();
  }
}
