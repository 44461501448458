import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import {HomeService} from "../../../core/service/home.service";
import {environment} from "../../../../environments/environment";
import {ContractService} from "@scalingparrots/dapp-angular-lib";
import {AdminService} from "../../../core/service/admin.service";
const tokenHTABI = require('src/app/core/abi/tokenABI.json');
const investmentABI = require('src/app/core/abi/investmentABI.json');
import Chart from 'chart.js/auto';
import {Router} from "@angular/router";
@Component({
  selector: 'app-dialog-reserved-content',
  templateUrl: './dialog-dashboard.component.html',
  styleUrls: ['./dialog-dashboard.component.scss'],
})
export class DialogDashboardComponent implements OnInit {
  innerWidth:any;
  profile:any;
  decimals:any;
  w3Address:any;
  HTBalance:any;
  arrayOfIndexNft:any[]=[];
  arrayOfMyNft:any[]=[];
  arrayOfMyNftCrypto:any[]=[];
  myNFT:any[]=[];
  walletEuro:any;
  walletCrypto:any;
  latestInvestment:any;
  loadLatestInvestment:boolean=false;
  loadWalletCrypto:boolean=false;
  loadMyProposal:boolean=false;
  deposit:any;
  investments:any;
  practices:any;
  proposals:any;
  apartment_purchased:any;
  booking:any;
  chartCrypto:any;
  chartEuro:any;
  constructor(
    public dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private _homeService: HomeService,
    private _adminService: AdminService,
    private _contractService: ContractService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) private data: DialogDashboardComponent
  ) {}

  ngOnInit(): void {
    this.loadWalletCrypto = true;
    this.loadLatestInvestment = true;
    this.loadMyProposal = true;
    this.innerWidth = window.innerWidth
    this.profile = this._homeService.profile
    this.deposit = this._homeService.profile.historyBuy.filter((data)=> !data.isRevoke)
    this.practices = this._homeService.profile.historyBuildRenovate
    this.apartment_purchased = this._homeService.profile.historyBuy.filter((data)=> !data.isRevoke && data.apartment.isSold)
    this.booking = this._homeService.profile.historyBooking
    if(this._homeService.profile.w3Addresses.length >0){
      const w3Addresses = this._homeService.profile.w3Addresses.filter(
        (address: any) => address.isMain === true
      );
      this.w3Address = w3Addresses[0].address;
      this.getWalletCrypto(w3Addresses[0].address)
      this.getProposerInvestments()
      this.getLatestInvestments(w3Addresses[0].address)
      this.getWalletEuro()
    } else {
      this.getWalletCrypto('')
      this.getProposerInvestments()
      this.getLatestInvestments('')
      this.getWalletEuro()
    }
  }

  createChartCrypto(value1:any,value2:any,value3:any,){
    this.chartCrypto = new Chart("chartCrypto", {
      type: 'pie', //this denotes tha type of chart
      data: {
        datasets: [
          {
            data: [value1,value2,value3],
            backgroundColor: [
              "#189cd8",
              "#ea575a",
              "rgba(10,71,98,0.6)",
            ]
          }]
      },
      options: {
        aspectRatio:2.5,
        responsive: true,
        maintainAspectRatio: false,
      }
    });
  }


  createChartEuro(value1:any,value2:any,value3:any,){
    this.chartEuro = new Chart("chartEuro", {
      type: 'pie', //this denotes tha type of chart
      data: {
        datasets: [
          {
            data: [value1,value2,value3],
            backgroundColor: [
              "#189cd8",
              "#ea575a",
              "rgba(10,71,98,0.6)",
            ]
          }]
      },
      options: {
        aspectRatio:2.5,
        responsive: true,
        maintainAspectRatio: false,
      }

    });
  }

  getWalletEuro(){
    let sumInvestmentEuro = 0;
    let historyInvestmentEuro = 0;
    let historyBuyEuro = 0;
    let historyBuildEuro = 0;
    const channelsBuild = []
    if(this._homeService.profile.historyBuildRenovate.length > 0){
      for(let i = 0; i < this._homeService.profile.historyBuildRenovate.length; i ++){
        channelsBuild.push(...this._homeService.profile.historyBuildRenovate[i].channels)
      }
    }
    if(this._homeService.profile.historyInvestment.length > 0){
      const historyInvestmentE = this._homeService.profile.historyInvestment.filter((data)=> !data.isEarnDone && !data.isRevokeDone)
      for(let i = 0; i < historyInvestmentE.length; i ++){
        historyInvestmentEuro = historyInvestmentEuro + Number(historyInvestmentE[i].amount)
      }
    }
    if(this._homeService.profile.historyBuy.length > 0){
      const historyBuyE = this._homeService.profile.historyBuy.filter((data)=> !data.isRevoke && data.apartment.wType.includes('W2'))
      for(let i = 0; i < historyBuyE.length; i ++){
        historyBuyEuro = historyBuyEuro + Number(historyBuyE[i].amount)
      }
    }
    if(channelsBuild){
      const historyBuildE = channelsBuild.filter((data)=>data.paymentType ==='W2')
      for(let i = 0; i < historyBuildE.length; i ++){
        for(let j = 0; j < historyBuildE[i].tranches.length; j ++){
          historyBuildEuro = historyBuildEuro + Number(historyBuildE[i].tranches[j].amount / 100)
        }
      }
    }
    let inv = 0; let buy = 0; let build = 0;
    sumInvestmentEuro = historyInvestmentEuro + historyBuyEuro + historyBuildEuro
    if(historyInvestmentEuro > 0){
      inv = Number((historyInvestmentEuro * 100 / sumInvestmentEuro).toFixed(2))
    }
    if(historyBuyEuro > 0){
      buy = Number((historyBuyEuro * 100 / sumInvestmentEuro).toFixed(2))
    }
    if(historyBuildEuro > 0){
      build = Number((historyBuildEuro * 100 / sumInvestmentEuro).toFixed(2))
    }
    this.walletEuro = {
      balance : this._homeService.profile.wallet.balance,
      balanceUsed : Number((sumInvestmentEuro).toFixed(2)),
      investment : inv,
      buy : buy,
      build : build,
    }
    if(inv > 0 || buy > 0 || build > 0){
      this.createChartEuro(inv,buy,build)
    } else {
      this.createChartEuro(1,1,1)
    }
  }

  async getWalletCrypto(w3Address:any) {
    if (this._homeService.profile.w3Addresses && this._homeService.profile.w3Addresses.length > 0) {
      if (w3Address) {
        this.decimals = await this._contractService.readContract(environment.TOKEN_HT, environment.RPC, tokenHTABI, 'decimals', []);
        this._contractService.readContract(environment.TOKEN_HT, environment.RPC, tokenHTABI, 'balanceOf', [this.w3Address])
          .then((res) => {
            this.HTBalance = +res._hex / 10 ** Number(this.decimals);
            this.getW3Investment(this.w3Address)
          })
          .catch((error: any) => console.error('balanceOf', error.message));
      }
    } else {
      this.investments = this._homeService.profile.historyBuildRenovate.length
      this.loadWalletCrypto = false;
      this.walletCrypto = {
        balance: 0,
        balanceUsed: 0,
        investment: 0,
        buy: 0,
        build: 0,
      }
      this.createChartCrypto(1,1,1)
    }
  }

  async getW3Investment(account: string) {
    const balanceNFT = await this._contractService.readContract(environment.INVESTMENT_HT, environment.RPC, investmentABI, 'balanceOf', [account]);
    if (+balanceNFT > 0) {
      for (let i = 0; i < +balanceNFT; i++) {
        const myNFT = await this._contractService.readContract(environment.INVESTMENT_HT, environment.RPC, investmentABI, 'tokenOfOwnerByIndex', [account, i]);
        const data = await this._contractService.readContract(environment.INVESTMENT_HT, environment.RPC, investmentABI, 'investmentMap', [myNFT]);
        this.arrayOfIndexNft.push(myNFT);
        this.arrayOfMyNftCrypto.push(String(+data.projectIndex));
        if (i === +balanceNFT - 1) {
          await this._adminService.getInvestmentsByW3Indexes(this.arrayOfMyNftCrypto)
            .subscribe(async (res) => {
              for (let index = 0; index < res.length; index++) {
                const nft = res[index];
                const image = await this._contractService.readContract(environment.INVESTMENT_HT, environment.RPC, investmentABI, 'tokenURI', [this.arrayOfIndexNft[index]]);
                const jsonImage = JSON.parse(image.slice(27, image.length));
                this.myNFT.push({
                  investment: nft,
                  data: jsonImage,
                  indexInvestment: index,
                });
              }
              const channelsBuild = []
              if(this._homeService.profile.historyBuildRenovate.length > 0){
                for(let i = 0; i < this._homeService.profile.historyBuildRenovate.length; i ++){
                  channelsBuild.push(...this._homeService.profile.historyBuildRenovate[i].channels)
                }
              }
              let sumInvestmentW3 = 0;
              let historyInvestmentW3 = 0;
              let historyBuyW3 = 0;
              let historyBuildW3 = 0;
              const historyBuyW = this._homeService.profile.historyBuy.filter((data) => !data.isRevoke && data.apartment.wType.includes('W3'))
              const historyBuildW = channelsBuild.filter((data)=>data.paymentType ==='W3')
              for (let i = 0; i < this.myNFT.length; i++) {
                historyInvestmentW3 = historyInvestmentW3 + Number( this.myNFT[i].data.attributes[0].value)
              }
              for (let i = 0; i < historyBuyW.length; i++) {
                historyBuyW3 = historyBuyW3 + Number(historyBuyW[i].amount)
              }
              for(let i = 0; i < historyBuildW.length; i ++){
                for(let j = 0; j < historyBuildW[i].tranches.length; j ++){
                  historyBuildW3 = historyBuildW3 + Number(historyBuildW[i].tranches[j].amount / 100)
                }
              }
              let inv = 0; let buy = 0; let build = 0;
              sumInvestmentW3 = historyInvestmentW3 + historyBuyW3 + historyBuildW3

              if(historyInvestmentW3 > 0){
                inv = Number((historyInvestmentW3 * 100 / sumInvestmentW3).toFixed(2))
              }
              if(historyBuyW3 > 0){
                buy = Number((historyBuyW3 * 100 / sumInvestmentW3).toFixed(2))
              }
              if(historyBuildW3 > 0){
                build = Number((historyBuildW3 * 100 / sumInvestmentW3).toFixed(2))
              }
              this.walletCrypto = {
                balance: this.HTBalance,
                balanceUsed: Number((sumInvestmentW3).toFixed(2)),
                investment: inv,
                buy: buy,
                build: build,
              }
              if(inv > 0 || buy > 0 || build > 0){
                this.createChartCrypto(inv,buy,build)
              } else {
                this.createChartCrypto(1,1,1)
              }
              const investments = this._homeService.profile.historyInvestment.filter((data)=> !data.isEarnDone && !data.isRevokeDone).length
              this.investments = investments + this.myNFT.length
              this.loadWalletCrypto = false;
            });
        }
      }
    } else {
      this.loadWalletCrypto = false;
      this.walletCrypto = {
        balance: 0,
        balanceUsed: 0,
        investment: 0,
        buy: 0,
        build: 0,
      }
      this.createChartCrypto(1,1,1)
    }
  }
  getProposerInvestments(){
    this._adminService.getProposerInvestments(0,1).subscribe({
      next:async (res) => {
        this.proposals = res.total;
      }
    })
  }

  async getLatestInvestments(w3Address:any) {
    if(this.w3Address){
      this.getW3Data(w3Address);
    } else {
      if(this._homeService.profile.historyInvestment && this._homeService.profile.historyInvestment.length >0){
        this.latestInvestment = this._homeService.profile.historyInvestment.sort(function (a: { dateInvestment: number }, b: { dateInvestment: number }) {
          return b.dateInvestment - a.dateInvestment;
        });
        this.latestInvestment = this.latestInvestment[0]
        this.loadLatestInvestment = false;
      } else {
        this.latestInvestment = null
        this.loadLatestInvestment = false;
      }
    }
  }

  async getW3Data(account: string) {
    const balanceNFT = await this._contractService.readContract(environment.INVESTMENT_HT, environment.RPC, investmentABI, 'balanceOf', [account]);
    if (+balanceNFT > 0) {
      for (let i = 0; i < +balanceNFT; i++) {
        const myNFT = await this._contractService.readContract(
          environment.INVESTMENT_HT,
          environment.RPC,
          investmentABI,
          'tokenOfOwnerByIndex',
          [account, i]
        );
        const data = await this._contractService.readContract(
          environment.INVESTMENT_HT,
          environment.RPC,
          investmentABI,
          'investmentMap',
          [myNFT]
        );
        const w3Data = await this._contractService.readContract(
          environment.INVESTMENT_HT,
          environment.RPC,
          investmentABI,
          'projectMap',
          [data.projectIndex]
        );
        const image = await this._contractService.readContract(
          environment.INVESTMENT_HT,
          environment.RPC,
          investmentABI,
          'tokenURI',
          [myNFT]
        );
        const jsonImage = JSON.parse(image.slice(27, image.length));
        if(String(+data.projectIndex)){
          await this._adminService.getInvestmentByW3Index(String(+data.projectIndex))
            .subscribe(
              (res) => {
                const formatData = {
                  finalized: data.finalized,
                  amount: +data.amount,
                  amountTransfer: +data.amount / 100,
                  cashback: +data.amount / 100 - +data.amountTransfer / 100,
                  cashbackPercentage: +w3Data.cashback / 100,
                  projectIndex: res.id,
                  investment: res,
                  routerIndex: +data.projectIndex,
                  investmentId: i,
                  status: 'In attesa',
                  dateInvestment: +data.createTimestamp * 1000,
                  investmentEndDate: +w3Data.timestampEnd * 1000,
                  name: res.name,
                  description: jsonImage.description,
                  image: jsonImage.image,
                  attributes: jsonImage.attributes,
                  yield: +w3Data.yield / 100,
                  w3Yield: +w3Data.yield / 100,
                  w3cashback: +w3Data.cashback / 100,
                  w3ActualInvestment: +w3Data.capReached / 100,
                  w3TotalInvestment: +w3Data.cap / 100,
                };
                this.arrayOfMyNft.push(formatData);
                if (i === +balanceNFT - 1) {
                  this.arrayOfMyNft = this.arrayOfMyNft.filter((data)=>data.amount)
                  this.arrayOfMyNft.sort(function (a: { dateInvestment: number }, b: { dateInvestment: number }) {
                    return b.dateInvestment - a.dateInvestment;
                  });
                  this.latestInvestment = this.arrayOfMyNft[0]
                  this.loadLatestInvestment = false;
                }
              },
              () => {}
            );
        }
      }
    } else {
      if(this._homeService.profile.historyInvestment && this._homeService.profile.historyInvestment.length >0){
        this.latestInvestment = this._homeService.profile.historyInvestment.sort(function (a: { dateInvestment: number }, b: { dateInvestment: number }) {
          return b.dateInvestment - a.dateInvestment;
        });
        this.latestInvestment = this.latestInvestment[0]
        this.loadLatestInvestment = false;
      } else {
        this.latestInvestment = null
        this.loadLatestInvestment = false;
      }
    }
  }

  goToPage(url){
    this.router.navigate([url])
    this.dialog.closeAll()
  }
}
