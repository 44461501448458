import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  private baseUrl = environment.baseUrl;

  constructor(private _http: HttpClient) {}

  get token(): string | null {
    return localStorage.getItem('token');
  }
  public createNewAdmin(
    email: string,
    phone: string,
    password: string,
    firstName: string,
    lastName: string,
    profileImages: any
  ): Observable<any> {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('password', password);
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('secondName', '');
    profileImages.forEach((profileImage: any) =>
      formData.append('profileImage', profileImage)
    );
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.post<any>(`${this.baseUrl}/admin/createAdmin`, formData, {
      headers,
    });
  }

  public createNewTechnician(
    email: string,
    phone: string,
    password: string,
    firstName: string,
    lastName: string,
    CF: string,
    language: any,
    profileImages: any
  ): Observable<any> {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('password', password);
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('CF', CF);
    formData.append('secondName', '');
    formData.append('language', language);
    profileImages.forEach((profileImage: any) =>
      formData.append('profileImage', profileImage)
    );
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.post<any>(
      `${this.baseUrl}/admin/addTechnicianHauses`,
      formData,
      { headers }
    );
  }

  public getAllUser(
    token: string | null | undefined,
    from: string,
    to: string
  ): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    };
    return this._http.get<any>(`${this.baseUrl}/admin/getAllUsers`, {
      headers,
      params: new HttpParams().set('from', from).set('to', to),
    });
  }

  public getAllUserRoles(
    token: string | null | undefined,
    from: string,
    to: string,
    roles: string
  ): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    };
    return this._http.get<any>(`${this.baseUrl}/admin/getAllUsers`, {
      headers,
      params: new HttpParams()
        .set('from', from)
        .set('to', to)
        .set('roles', roles),
    });
  }

  public enableUser(userId: string): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.patch<any>(`${this.baseUrl}/admin/enableUser`, null, {
      headers,
      params: new HttpParams().set('userId', userId),
    });
  }

  public disableUser(userId: string): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.patch<any>(`${this.baseUrl}/admin/disableUser`, null, {
      headers,
      params: new HttpParams().set('userId', userId),
    });
  }

  public getBuilding(buildingId: string): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };
    return this._http.get<any>(`${this.baseUrl}/building/getBuilding`, {
      headers,
      params: new HttpParams().set('buildingId', buildingId),
    });
  }

  public getBuildingSearch(str: string): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };
    return this._http.get<any>(`${this.baseUrl}/building/search`, {
      headers,
      params: new HttpParams().set('str', str),
    });
  }

  public getApartmentSearch(str: string): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };
    return this._http.get<any>(`${this.baseUrl}/apartment/search`, {
      headers,
      params: new HttpParams().set('str', str),
    });
  }

  public getBookingSearch(str: string): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.get<any>(`${this.baseUrl}/booking/search`, {
      headers,
      params: new HttpParams().set('str', str),
    });
  }
  public getUser(userId: string): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.get<any>(`${this.baseUrl}/admin/getUser`, {
      headers,
      params: new HttpParams().set('userId', userId),
    });
  }
  public getUserSearch(str: string, roles: string): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.get<any>(`${this.baseUrl}/admin/searchUser`, {
      headers,
      params: new HttpParams().set('str', str).set('roles', roles),
    });
  }

  public investmentProposal(str: string): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.get<any>(`${this.baseUrl}/investmentProposal/search`, {
      headers,
      params: new HttpParams().set('str', str),
    });
  }

  public investmentProposalDisable(investmentProposalId: any): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.patch<any>(
      `${this.baseUrl}/investmentProposal/disable`,
      null,
      {
        headers,
        params: new HttpParams().set(
          'investmentProposalId',
          investmentProposalId
        ),
      }
    );
  }
  public getInvestmentSearch(str: string): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.get<any>(`${this.baseUrl}/investment/search`, {
      headers,
      params: new HttpParams().set('str', str),
    });
  }

  public getInvestment(
    token: string | null,
    investmentId: any
  ): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.get<any>(`${this.baseUrl}/investment/getInvestment`, {
      headers,
      params: new HttpParams().set('investmentId', investmentId),
    });
  }

  public createInvestment(data: any, fileContract: any): Observable<any> {
    const formData = new FormData();
    formData.append('fileContract', fileContract);
    formData.append('data', JSON.stringify(data));
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.post<any>(`${this.baseUrl}/investment/create`, formData, {
      headers,
    });
  }
  public createInvestmentProposal(data: any, fileContract: any,votingId:any): Observable<any> {
    const formData = new FormData();
    formData.append('fileContract', fileContract);
    formData.append('data', JSON.stringify(data));
    formData.append('votingId', votingId);
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.post<any>(`${this.baseUrl}/investment/create`, formData, {
      headers,
    });
  }
  public createProposal(data: any, fileContract: any): Observable<any> {
    const formData = new FormData();
    formData.append('fileContract', fileContract);
    formData.append('data', JSON.stringify(data));
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.post<any>(
      `${this.baseUrl}/investmentProposal/create`,
      formData,
      { headers }
    );
  }
  public updateInvestment(data: any, investmentId: any): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.patch<any>(`${this.baseUrl}/investment/update`, data, {
      headers,
      params: new HttpParams().set('investmentId', investmentId),
    });
  }

  public updateInvestImage1(
    images1: any,
    investmentId: string
  ): Observable<any> {
    const formData = new FormData();
    images1.forEach((images: any) => formData.append('images1', images));
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.patch<any>(
      `${this.baseUrl}/investment/updateImages1`,
      formData,
      { headers, params: new HttpParams().set('investmentId', investmentId) }
    );
  }

  public updateProposalImage1(
    images1: any,
    investmentProposalId: string
  ): Observable<any> {
    const formData = new FormData();
    images1.forEach((images: any) => formData.append('images1', images));
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.patch<any>(
      `${this.baseUrl}/investmentProposal/updateImages1`,
      formData,
      {
        headers,
        params: new HttpParams().set(
          'investmentProposalId',
          investmentProposalId
        ),
      }
    );
  }
  public updateInvestImage2(
    images2: any,
    investmentId: string
  ): Observable<any> {
    const formData = new FormData();
    images2.forEach((images: any) => formData.append('images2', images));
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.patch<any>(
      `${this.baseUrl}/investment/updateImages2`,
      formData,
      { headers, params: new HttpParams().set('investmentId', investmentId) }
    );
  }

  public updateProposalImage2(
    images1: any,
    investmentProposalId: string
  ): Observable<any> {
    const formData = new FormData();
    images1.forEach((images: any) => formData.append('images2', images));
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.patch<any>(
      `${this.baseUrl}/investmentProposal/updateImages2`,
      formData,
      {
        headers,
        params: new HttpParams().set(
          'investmentProposalId',
          investmentProposalId
        ),
      }
    );
  }

  public updateInvestorDeck(
    investorDecks: any,
    investmentId: string
  ): Observable<any> {
    const formData = new FormData();
    investorDecks.forEach((investorDeck: any) =>
      formData.append('investorDeck', investorDeck)
    );
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.patch<any>(
      `${this.baseUrl}/investment/updateInvestorDeck`,
      formData,
      { headers, params: new HttpParams().set('investmentId', investmentId) }
    );
  }

  public updateProposalInvestorDeck(
    investorDecks: any,
    investmentProposalId: string
  ): Observable<any> {
    const formData = new FormData();
    investorDecks.forEach((investorDeck: any) =>
      formData.append('investorDeck', investorDeck)
    );
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.patch<any>(
      `${this.baseUrl}/investmentProposal/updateInvestorDeck`,
      formData,
      {
        headers,
        params: new HttpParams().set(
          'investmentProposalId',
          investmentProposalId
        ),
      }
    );
  }

  public createBuildings(data: any): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.post<any>(`${this.baseUrl}/building/create`, data, {
      headers,
    });
  }

  public updateBuildings(data: any, buildingId: string): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.patch<any>(`${this.baseUrl}/building/update`, data, {
      headers,
      params: new HttpParams().set('buildingId', buildingId),
    });
  }

  public updateBuildingImage1(
    images1: any,
    buildingId: string
  ): Observable<any> {
    const formData = new FormData();
    images1.forEach((images: any) => formData.append('images1', images));
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.patch<any>(
      `${this.baseUrl}/building/updateImages1`,
      formData,
      { headers, params: new HttpParams().set('buildingId', buildingId) }
    );
  }

  public updateBuildingImage2(
    images2: any,
    buildingId: string
  ): Observable<any> {
    const formData = new FormData();
    images2.forEach((images: any) => formData.append('images2', images));
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.patch<any>(
      `${this.baseUrl}/building/updateImages2`,
      formData,
      { headers, params: new HttpParams().set('buildingId', buildingId) }
    );
  }

  public createApartment(
    data: any,
    fileContract: any,
    buildingId: any
  ): Observable<any> {
    const formData = new FormData();
    formData.append('fileContract', fileContract);
    formData.append('data', JSON.stringify(data));
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.post<any>(`${this.baseUrl}/apartment/create`, formData, {
      headers,
      params: new HttpParams().set('buildingId', buildingId),
    });
  }

  public updateApartment(data: any, apartmentId: any): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.patch<any>(`${this.baseUrl}/apartment/update`, data, {
      headers,
      params: new HttpParams().set('apartmentId', apartmentId),
    });
  }

  public updateApartmentImage1(
    images1: any,
    apartmentId: string
  ): Observable<any> {
    const formData = new FormData();
    images1.forEach((images1: any) => formData.append('images1', images1));
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.patch<any>(
      `${this.baseUrl}/apartment/updateImages1`,
      formData,
      { headers, params: new HttpParams().set('apartmentId', apartmentId) }
    );
  }

  public updateApartmentImage2(
    images2: any,
    images2Description: any,
    images2Counter: any,
    apartmentId: string
  ): Observable<any> {
    const formData = new FormData();
    images2.forEach((images2: any) => formData.append('images2', images2));
    formData.append('images2Description', images2Description);
    formData.append('images2Counter', images2Counter);
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.patch<any>(
      `${this.baseUrl}/apartment/updateImages2`,
      formData,
      { headers, params: new HttpParams().set('apartmentId', apartmentId) }
    );
  }

  public updateApartmentImagePlan(
    imagesPlan: any,
    apartmentId: string
  ): Observable<any> {
    const formData = new FormData();
    imagesPlan.forEach((imagesPlan: any) =>
      formData.append('imagesPlan', imagesPlan)
    );
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.patch<any>(
      `${this.baseUrl}/apartment/updateImagesPlan`,
      formData,
      { headers, params: new HttpParams().set('apartmentId', apartmentId) }
    );
  }

  public enableBuildings(buildingId: string): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.patch<any>(`${this.baseUrl}/building/enable`, null, {
      headers,
      params: new HttpParams().set('buildingId', buildingId),
    });
  }

  public disableBuildings(buildingId: string): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.patch<any>(`${this.baseUrl}/building/disable`, null, {
      headers,
      params: new HttpParams().set('buildingId', buildingId),
    });
  }

  public enableApartment(apartmentId: string): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.patch<any>(`${this.baseUrl}/apartment/enable`, null, {
      headers,
      params: new HttpParams().set('apartmentId', apartmentId),
    });
  }

  public disableApartment(apartmentId: string): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.patch<any>(`${this.baseUrl}/apartment/disable`, null, {
      headers,
      params: new HttpParams().set('apartmentId', apartmentId),
    });
  }

  public enableInvestment(investmentId: string): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.patch<any>(`${this.baseUrl}/investment/enable`, null, {
      headers,
      params: new HttpParams().set('investmentId', investmentId),
    });
  }

  public disableInvestment(investmentId: string): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.patch<any>(`${this.baseUrl}/investment/disable`, null, {
      headers,
      params: new HttpParams().set('investmentId', investmentId),
    });
  }

  public searchUser(str: any): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.get<any>(`${this.baseUrl}/admin/searchUser`, {
      headers,
      params: new HttpParams().set('str', str),
    });
  }

  public getApartmentsDeposit(from: string, to: string): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.get<any>(
      `${this.baseUrl}/apartment/getApartmentsDeposit`,
      { headers, params: new HttpParams().set('from', from).set('to', to) }
    );
  }

  public getApartmentsSold(from: string, to: string): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.get<any>(`${this.baseUrl}/apartment/getApartmentsSold`, {
      headers,
      params: new HttpParams().set('from', from).set('to', to),
    });
  }

  public getApartment(apartmentId: string): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.get<any>(`${this.baseUrl}/apartment/getApartment`, {
      headers,
      params: new HttpParams().set('apartmentId', apartmentId),
    });
  }

  public getApartments(): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.get<any>(`${this.baseUrl}/apartment/getApartments`, {
      headers,
    });
  }

  public getBooking(from: string, to: string): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.get<any>(`${this.baseUrl}/booking/getBookings`, {
      headers,
      params: new HttpParams().set('from', from).set('to', to),
    });
  }

  public getBuildingsPaginationOrder(
    from: string,
    to: string,
    order: any
  ): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };
    if (order !== null) {
      return this._http.get<any>(`${this.baseUrl}/building/getBuildings`, {
        headers,
        params: new HttpParams()
          .set('from', from)
          .set('to', to)
          .set('order', order),
      });
    } else {
      return this._http.get<any>(`${this.baseUrl}/building/getBuildings`, {
        headers,
        params: new HttpParams().set('from', from).set('to', to),
      });
    }
  }

  public getBuildingsPaginationFilter(
    from: string,
    to: string,
    filter: any
  ): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };
    if (filter !== null) {
      return this._http.get<any>(`${this.baseUrl}/building/getBuildings`, {
        headers,
        params: new HttpParams()
          .set('from', from)
          .set('to', to)
          .set('filter', filter),
      });
    } else {
      return this._http.get<any>(`${this.baseUrl}/building/getBuildings`, {
        headers,
        params: new HttpParams().set('from', from).set('to', to),
      });
    }
  }

  public getInvestmentsPaginationOrder(
    from: string,
    to: string,
    order: any
  ): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };
    if (order !== null) {
      return this._http.get<any>(`${this.baseUrl}/investment/getInvestments`, {
        headers,
        params: new HttpParams()
          .set('from', from)
          .set('to', to)
          .set('order', order),
      });
    } else {
      return this._http.get<any>(`${this.baseUrl}/investment/getInvestments`, {
        headers,
        params: new HttpParams().set('from', from).set('to', to),
      });
    }
  }

  public getInvestmentsPaginationFilter(
    from: string,
    to: string,
    filter: any
  ): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };
    if (filter !== null) {
      return this._http.get<any>(`${this.baseUrl}/investment/getInvestments`, {
        headers,
        params: new HttpParams()
          .set('from', from)
          .set('to', to)
          .set('filter', filter),
      });
    } else {
      return this._http.get<any>(`${this.baseUrl}/investment/getInvestments`, {
        headers,
        params: new HttpParams().set('from', from).set('to', to),
      });
    }
  }

  public getApartmentsBuildingPaginationOrder(
    buildingId: any,
    from: string,
    to: string,
    order: any
  ): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };
    if (order !== null) {
      return this._http.get<any>(`${this.baseUrl}/apartment/getApartments`, {
        headers,
        params: new HttpParams()
          .set('buildingId', buildingId)
          .set('from', from)
          .set('to', to)
          .set('order', order),
      });
    } else {
      return this._http.get<any>(`${this.baseUrl}/apartment/getApartments`, {
        headers,
        params: new HttpParams()
          .set('buildingId', buildingId)
          .set('from', from)
          .set('to', to),
      });
    }
  }

  public getApartmentsBuildingPaginationFilter(
    buildingId: any,
    from: string,
    to: string,
    filter: any
  ): Observable<any> {
    const headers = { 'Content-Type': 'application/json' };
    if (filter !== null) {
      return this._http.get<any>(`${this.baseUrl}/apartment/getApartments`, {
        headers,
        params: new HttpParams()
          .set('buildingId', buildingId)
          .set('from', from)
          .set('to', to)
          .set('filter', filter),
      });
    } else {
      return this._http.get<any>(`${this.baseUrl}/apartment/getApartments`, {
        headers,
        params: new HttpParams()
          .set('buildingId', buildingId)
          .set('from', from)
          .set('to', to),
      });
    }
  }

  public getInvestmentsPagination(from: string, to: string): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.get<any>(`${this.baseUrl}/investment/getInvestments`, {
      headers,
      params: new HttpParams().set('from', from).set('to', to),
    });
  }

  public getAllWithdrawRequestsPagination(
    from: string,
    to: string
  ): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.get<any>(
      `${this.baseUrl}/wallet/getAllWithdrawRequests`,
      { headers, params: new HttpParams().set('from', from).set('to', to) }
    );
  }

  public getAllDepositRequestsPagination(
    from: string,
    to: string
  ): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.get<any>(`${this.baseUrl}/wallet/getAllDepositRequests`, {
      headers,
      params: new HttpParams().set('from', from).set('to', to),
    });
  }

  public confirmBooking(data: any, apartmentId: any): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.post<any>(`${this.baseUrl}/booking/add`, data, {
      headers,
      params: new HttpParams().set('apartmentId', apartmentId),
    });
  }

  public updateBooking(data: any, bookingId: any): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.patch<any>(`${this.baseUrl}/booking/changeStatus`, data, {
      headers,
      params: new HttpParams().set('bookingId', bookingId),
    });
  }

  public deleteBooking(bookingId: any): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.delete<any>(`${this.baseUrl}/booking/delete`, {
      headers,
      params: new HttpParams().set('bookingId', bookingId),
    });
  }

  public updateUser(data: any): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.patch<any>(`${this.baseUrl}/user/update`, data, {
      headers,
    });
  }

  public updateImageProfile(image: any) {
    const headers = { Authorization: 'Bearer ' + this.token };
    const formData = new FormData();
    image.forEach((images: any) => formData.append('profileImage', images));
    return this._http.patch(
      `${this.baseUrl}/user/updateProfileImage`,
      formData,
      { headers }
    );
  }

  public updateIDImages(idImages: any) {
    const headers = { Authorization: 'Bearer ' + this.token };
    const formData = new FormData();
    idImages.forEach((idImages: any) => formData.append('idImages', idImages));
    return this._http.patch(`${this.baseUrl}/user/updateIDImages`, formData, {
      headers,
    });
  }

  public addMoney(amount: any): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.patch<any>(`${this.baseUrl}/wallet/addMoney`, amount, {
      headers,
    });
  }

  public requestWithdraw(amount: any): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.post<any>(
      `${this.baseUrl}/wallet/requestWithdraw`,
      amount,
      { headers }
    );
  }

  public addUpdate(update: any, investmentId: any): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.patch<any>(
      `${this.baseUrl}/investment/addUpdate`,
      update,
      { headers, params: new HttpParams().set('investmentId', investmentId) }
    );
  }

  public updateRoleInvestor(userId: any, role: any): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.patch<any>(
      `${this.baseUrl}/admin/changeInvestorRole`,
      '',
      {
        headers,
        params: new HttpParams().set('userId', userId).set('role', role),
      }
    );
  }

  public earnW2(objInvestmentId: any): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.post<any>(`${this.baseUrl}/investment/earnW2`, '', {
      headers,
      params: new HttpParams().set('objInvestmentId', objInvestmentId),
    });
  }
  public markDepositAsAccepted(objWalletId: any, amount: any): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.patch<any>(
      `${this.baseUrl}/wallet/markDepositAsAccepted`,
      amount,
      { headers, params: new HttpParams().set('objWalletId', objWalletId) }
    );
  }
  public markDepositAsRejected(objWalletId: any): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.patch<any>(
      `${this.baseUrl}/wallet/markDepositAsRejected`,
      '',
      { headers, params: new HttpParams().set('objWalletId', objWalletId) }
    );
  }
  public markWithdrawAsDone(objWalletId: any): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.patch<any>(
      `${this.baseUrl}/wallet/markWithdrawAsDone`,
      '',
      { headers, params: new HttpParams().set('objWalletId', objWalletId) }
    );
  }
  public changeIsDeposit(apartmentId: any): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.patch<any>(`${this.baseUrl}/apartment/reset`, null, {
      headers,
      params: new HttpParams().set('apartmentId', apartmentId),
    });
  }

  public finalizeApartment(apartmentId: string): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.post<any>(`${this.baseUrl}/apartment/finalize`, null, {
      headers,
      params: new HttpParams().set('apartmentId', apartmentId),
    });
  }

  public resetApartment(apartmentId: string): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.post<any>(`${this.baseUrl}/apartment/reset`, null, {
      headers,
      params: new HttpParams().set('apartmentId', apartmentId),
    });
  }

  public finalize(investmentId: any): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.post<any>(`${this.baseUrl}/investment/finalize`, '', {
      headers,
      params: new HttpParams().set('investmentId', investmentId),
    });
  }

  public updateNotification(notification: any): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.patch<any>(
      `${this.baseUrl}/user/updateNotifications`,
      notification,
      { headers }
    );
  }

  public getInvestmentByW3Index(w3Index: string): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.get<any>(
      `${this.baseUrl}/investment/getInvestmentByW3Index`,
      { headers, params: new HttpParams().set('w3Index', w3Index) }
    );
  }

  public getInvestmentsByW3Indexes(w3Indexes: any): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.post<any>(
      `${this.baseUrl}/investment/getInvestmentsByW3Indexes`,
      { w3Indexes },
      { headers }
    );
  }

  public getProposerInvestments(from: any, to: any): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.get<any>(
      `${this.baseUrl}/investment/getProposerInvestments`,
      { headers, params: new HttpParams().set('from', from).set('to', to) }
    );
  }

  public revokeInvestmentW2(objInvestmentId: any): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.post<any>(
      `${this.baseUrl}/investment/revokeInvestmentW2`,
      null,
      {
        headers,
        params: new HttpParams().set('objInvestmentId', objInvestmentId),
      }
    );
  }

  public withdrawInvestmentW2(investmentId: any): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.post<any>(
      `${this.baseUrl}/investment/withdrawInvestmentW2`,
      null,
      { headers, params: new HttpParams().set('investmentId', investmentId) }
    );
  }

  public chargeInvestmentW2(investmentId: any): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.post<any>(
      `${this.baseUrl}/investment/chargeInvestmentW2`,
      null,
      { headers, params: new HttpParams().set('investmentId', investmentId) }
    );
  }

  public setFunded(investmentId: any): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.post<any>(`${this.baseUrl}/investment/setFunded`, null, {
      headers,
      params: new HttpParams().set('investmentId', investmentId),
    });
  }

  public setNotFunded(investmentId: any): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.post<any>(
      `${this.baseUrl}/investment/setNotFunded`,
      null,
      { headers, params: new HttpParams().set('investmentId', investmentId) }
    );
  }
  public sendNotificationSoldInvestmentMarketplaceW3(
    data: any
  ): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.post<any>(
      `${this.baseUrl}/investment/sendNotificationSoldInvestmentMarketplaceW3`,
      data,
      { headers }
    );
  }
  public getApartmentByW3Index(w3Index: string): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.get<any>(
      `${this.baseUrl}/apartment/getApartmentByW3Index`,
      { headers, params: new HttpParams().set('w3Index', w3Index) }
    );
  }
  public getInvestmentProposal(investmentProposalId: any): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.get<any>(
      `${this.baseUrl}/investmentProposal/getInvestmentProposal`,
      {
        headers,
        params: new HttpParams().set(
          'investmentProposalId',
          investmentProposalId
        ),
      }
    );
  }
  public getInvestmentProposals(from: any, to: any): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.get<any>(
      `${this.baseUrl}/investmentProposal/getInvestmentProposals`,
      { headers, params: new HttpParams().set('from', from).set('to', to) }
    );
  }
  public getInvestmentProposalsFilter(from: any, to: any,isActive:string): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.get<any>(
      `${this.baseUrl}/investmentProposal/getInvestmentProposals`,
      { headers, params: new HttpParams().set('from', from).set('to', to).set('filter', isActive) }
    );
  }
  public getDepositData(): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.post<any>(`${this.baseUrl}/wallet/requestDeposit`, null, {
      headers,
    });
  }
  public getHausesBank(): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.get<any>(`${this.baseUrl}/config/getHausesBank`, {
      headers,
    });
  }
  public getConfig(): Observable<any> {
    const headers = {
      Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json',
    };
    return this._http.get<any>(`${this.baseUrl}/config/getConfig`, { headers });
  }
  public updateConfig(data: any): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.patch<any>(`${this.baseUrl}/config/updateConfig`, data, {
      headers,
    });
  }
  public sentDeposit(transferId: any): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.post<any>(`${this.baseUrl}/wallet/sentDeposit`, null, {
      headers,
      params: new HttpParams().set('transferId', transferId),
    });
  }
  public getCoordinate(address:any,city:any,state:any): Observable<any> {
    return this._http.get<any>(`https://nominatim.openstreetmap.org/search?q=${address},+${city},+${state}&format=json&polygon=1&addressdetails=1`);
  }
  public getCoordinates(): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.get<any>(`${this.baseUrl}/investment/getCoordinates`,{headers});
  }

  public setupOTP(setupOTP:any): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.patch<any>(`${this.baseUrl}/user/setupOTP`, setupOTP, {
      headers,
    });
  }

  public activateOTP(data:any): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.patch<any>(`${this.baseUrl}/user/activateOTP`, data, {
      headers,
    });
  }

  public resetOTP(userId): Observable<any> {
    const headers = { Authorization: 'Bearer ' + this.token };
    return this._http.patch<any>(`${this.baseUrl}/user/resetOTP`, null, {
      headers,params: new HttpParams().set('userId', userId)
    });
  }
}
