<div class="container_dialog" fxLayout="column" (close)="dialog.closeAll()">
  <label class="title">CME</label>
  <label class="subtitle">Preferisci visualizzare o modificare il CME?</label>
  <div
    class="container_button"
    fxLayout="row"
    fxLayoutAlign="center"
    fxLayoutGap="20px"
  >
    <button class="button_register" (click)="openView()">VISUALIZZA</button>
    <button
      *ngIf="!this.info.isDisabled"
      class="button_register"
      (click)="openEdit()"
    >
      MODIFICA
    </button>
    <button
      [matTooltipClass]="{ 'tool-tip': true }"
      [matTooltipPosition]="'above'"
      matTooltip="Il CME non può essere modificato perche è gia stato confermato"
      *ngIf="this.info.isDisabled"
      class="button_register_disable"
    >
      MODIFICA
    </button>
  </div>
</div>
