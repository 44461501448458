<div *ngIf="this.connectionW3">
  <mat-grid-list
    cols="12"
    rowHeight="105px"
    [gutterSize]="'10px'"
    fxLayoutGap="30px"
    style="margin: 30px 0"
  >
    <mat-grid-tile
      [colspan]="innerWidth > 1000 ? 4 : 12"
      [rowspan]="innerWidth > 1000 ? 6 : 6"
      class="card"
    >
      <div class="container_card" fxLayout="column" fxLayoutGap="10px">
        <div class="container_title">
          <label class="title_card">Bilancio</label>
        </div>
        <div class="container_token">
          <label class="token">{{ this.HTBalance }}</label>
          <label class="text_show_all">Hauses Token </label>
        </div>
        <div class="line"></div>
        <div fxLayout="row" fxLayoutAlign="flex-start" fxLayoutGap="10px">
          <label class="label_header_table">Lista movimenti</label>
          <mat-spinner
            *ngIf="loadTransfer"
            diameter="20"
            color="accent"
          ></mat-spinner>
        </div>
        <div
          *ngIf="listTransfer && listTransfer.length > 0"
          class="container_transaction"
          fxLayout="column"
          fxLayoutGap="10px"
        >
          <div
            fxLayout="column"
            fxLayoutGap="10px"
            *ngFor="let list of this.listTransfer"
          >
            <div
              [class]="
                list.type === 'in' ? 'card_custom_in' : 'card_custom_out'
              "
              fxLayout="row"
              fxLayoutGap="5px"
              fxLayoutAlign="space-between"
            >
              <div fxLayout="column" fxLayoutGap="5px">
                <label class="label_time">{{
                  list.date | date: "dd/MM/YYYY HH:mm:ss":"it"
                }}</label>
                <label class="amount_transfer"
                  >{{ list.type === "in" ? "+" : "-" }}
                  {{ list.amount }} HT</label
                >
              </div>
              <div fxLayout="column" fxLayoutGap="5px">
                <img
                  [matTooltipClass]="{ 'tool-tip': true }"
                  [matTooltipPosition]="'above'"
                  matTooltip="Visualizza transazione"
                  class="image_view"
                  src="assets/image/dashboard/view_icon_white.svg"
                  alt=""
                  (click)="openTransaction(list.hash)"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="!loadTransfer && listTransfer && listTransfer.length <= 0"
          class="container_transaction"
          fxLayout="column"
          fxLayoutGap="10px"
        >
          <label class="no_apartments">Nessuna lista movimenti</label>
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile
      [colspan]="innerWidth > 1000 ? 8 : 12"
      [rowspan]="innerWidth > 1000 ? 3 : 4"
      class="card"
    >
      <div *ngIf="this.type === 'wallet'" class="container_card" fxLayout="column" fxLayoutGap="10px">
        <div class="container_title">
          <label class="title_card">Acquista Hauses Token</label>
          <img class="image_view" src="assets/image/dashboard/info.svg" alt="" (click)="infoSwap()"/>
        </div>
        <form [formGroup]="selectForm">
          <select
            id="how"
            class="styling_select"
            (change)="setSelect()"
            formControlName="select"
          >
            <option value="wallet" class="option">Crypto wallet</option>
            <option value="euro" class="option">Portafoglio €</option>
          </select>
        </form>
        <div class="container_card_swap">
          <form [formGroup]="swapTokenToTokenForm" class="container_swap">
            <div class="container_input">
              <label class="label_header_table">Seleziona l'importo</label>
              <div class="input_custom_swap" fxLayout="row">
                <input
                  class="form-input"
                  placeholder="0.00"
                  formControlName="amount"
                  (ngModelChange)="setHT($event)"
                />
                <div class="container_max" (click)="setMaxToken()">max</div>
                <div *ngIf="selectDisabled" class="container_menu" >
                  <mat-spinner class="loader_blue" diameter="20"></mat-spinner>
                  <mat-icon class="color_icon">keyboard_arrow_down</mat-icon>
                </div>
                <div *ngIf="!selectDisabled" class="container_menu" [matMenuTriggerFor]="profile">
                  <label>{{ valueSelected }}</label>
                  <mat-icon class="color_icon">keyboard_arrow_down</mat-icon>
                </div>
                <mat-menu
                  #profile="matMenu"
                  class="customize"
                  yPosition="below"
                  xPosition="before"
                >
                  <button
                    mat-menu-item
                    [class]="'button_menu'"
                    *ngFor="let token of arrayToken"
                    (click)="selectValue(token)"
                  >
                    <label [class]="'title_button'">{{ token }}</label>
                  </button>
                </mat-menu>
              </div>
              <label class="label_header_table"
              >Valore {{ valuePrice | number: "1.2-2":"it" }} €</label
              >
            </div>
            <img
              class="image"
              src="assets/image/dashboard/swap_arrow.svg"
              alt=""
            />
            <div class="container_input">
              <label class="label_header_table">Hauses Token (HT)</label>
              <div class="input_custom_swap" fxLayout="row">
                <input
                  class="form-input"
                  placeholder="0.00"
                  formControlName="HT"
                  readonly
                />
                <label class="title_button">HT</label>
              </div>
              <label class="label_header_table"></label>
            </div>
          </form>
          <div fxLayoutGap="5px" fxLayout="column" style="width: 100%">
            <div>
              <mat-slider thumbLabel tickInterval="auto" min="0.5" max="50" step="0.5" value="0.5" (input)="changeValue($event)"></mat-slider>
              <label class="title_button">Slippage: {{ slippage }}</label>
            </div>
            <div fxLayout="row" fxLayoutAlign="flex-start" fxLayoutGap="10px">
              <button
                *ngIf="!loadSwapTKToHT"
                [class]="
                  this.swapTokenToTokenForm.get('amount').value > 0 && !selectDisabled && this.swapTokenToTokenForm.get('amount').value <= myBalance
                    ? 'button_submit'
                    : 'button_submit_disabled'
                "
                [disabled]="this.swapTokenToTokenForm.get('amount').value <= 0 || selectDisabled || this.swapTokenToTokenForm.get('amount').value > myBalance"
                (click)="confirmSwapTKBuyHT()"
              >
                Conferma
              </button>
              <button *ngIf="loadSwapTKToHT" [class]="'button_submit'">
                <mat-spinner diameter="20"></mat-spinner>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="this.type === 'euro'" class="container_card" fxLayout="column" fxLayoutGap="10px">
        <div class="container_title">
          <label class="title_card">Acquista Hauses Token</label>
          <img class="image_view" src="assets/image/dashboard/info.svg" alt="" (click)="infoSwap()"/>
        </div>
        <form [formGroup]="selectForm">
          <select
            id="how1"
            class="styling_select"
            (change)="setSelect()"
            formControlName="select"
          >
            <option value="wallet" class="option">Crypto wallet</option>
            <option value="euro" class="option">Portafoglio €</option>
          </select>
        </form>
        <div class="container_card_swap">
          <form [formGroup]="swapEuroToTokenForm" class="container_swap">
            <div class="container_input">
              <label class="label_header_table">Totale in €</label>
              <div class="input_custom_swap" fxLayout="row">
                <input
                  class="form-input"
                  placeholder="0.00"
                  formControlName="euro"
                  (ngModelChange)="setEuroToHT($event)"
                />
                <div class="container_max" (click)="setMaxEur()">max</div>
                <label class="title_button">€</label>
              </div>
              <label class="label_header_table"
              >Bilancio: {{ balance | number: "1.2-2":"it" }} €</label
              >
            </div>
            <img
              class="image"
              src="assets/image/dashboard/swap_arrow.svg"
              alt=""
            />
            <div class="container_input">
              <label class="label_header_table">Hauses Token (HT)</label>
              <div class="input_custom_swap" fxLayout="row">
                <input
                  class="form-input"
                  placeholder="0.00"
                  formControlName="HT"
                  readonly
                />
                <label class="title_button">HT</label>
              </div>
              <label class="label_header_table"></label>
            </div>
          </form>
        </div>
        <div class="container_button">
          <button
            *ngIf="!loadSwapEuroToHT"
            [class]="
              this.swapEuroToTokenForm.get('euro').value > 0 &&
              this.swapEuroToTokenForm.get('euro').value <= balance
                ? 'button_submit'
                : 'button_submit_disabled'
            "
            [disabled]="
              this.swapEuroToTokenForm.get('HT').value <= 0 ||
              this.swapEuroToTokenForm.get('HT').value > balance
            "
            (click)="confirmBuyHT()"
          >
            Acquista
          </button>
          <button *ngIf="loadSwapEuroToHT" [class]="'button_submit'">
            <mat-spinner diameter="20"></mat-spinner>
          </button>
        </div>
      </div>
    </mat-grid-tile>
    <mat-grid-tile
      [colspan]="innerWidth > 1000 ? 8 : 12"
      [rowspan]="innerWidth > 1000 ? 3 : 4"
      class="card"
    >
      <div class="container_card" fxLayout="column" fxLayoutGap="10px">
        <div class="container_title">
          <label class="title_card">Converti Hauses Token</label>
          <img class="image_view" src="assets/image/dashboard/info.svg" alt="" (click)="infoSwap()"/>
        </div>
        <div class="container_card_swap">
          <form [formGroup]="swapTokenToEuroForm" class="container_swap">
            <div class="container_input">
              <label class="label_header_table">Hauses Token (HT)</label>
              <div class="input_custom_swap" fxLayout="row">
                <input
                  class="form-input"
                  placeholder="0.00"
                  formControlName="HT"
                  (ngModelChange)="setEuro($event)"
                />
                <div class="container_max" (click)="setMaxHT()">max</div>
                <label class="title_button">HT</label>
              </div>
              <label class="label_header_table"
                >Bilancio: {{ this.HTBalance | number: "1.2-2":"it" }} HT</label
              >
            </div>
            <img
              class="image"
              src="assets/image/dashboard/swap_arrow.svg"
              alt=""
            />
            <div class="container_input">
              <label class="label_header_table">Totale in €</label>
              <div class="input_custom_swap" fxLayout="row">
                <input
                  class="form-input"
                  placeholder="0.00"
                  formControlName="euro"
                  readonly
                />
                <label class="title_button">€</label>
              </div>
              <label class="label_header_table"></label>
            </div>
          </form>
        </div>
        <div class="container_button">
          <button
            *ngIf="!loadSwapHTToEuro"
            [class]="
                this.swapTokenToEuroForm.get('HT').value > 0 &&
                this.swapTokenToEuroForm.get('HT').value <= HTBalance
                  ? 'button_submit'
                  : 'button_submit_disabled'
              "
            [disabled]="
                this.swapTokenToEuroForm.get('HT').value <= 0 ||
                this.swapTokenToEuroForm.get('HT').value > HTBalance
              "
            (click)="confirmSellHT()"
          >
            Converti
          </button>
          <button *ngIf="loadSwapHTToEuro" [class]="'button_submit'">
            <mat-spinner diameter="20"></mat-spinner>
          </button>
        </div>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>
<app-wallet-not-connected
  *ngIf="this.connectionW3 === undefined && !this.connectionW3"
></app-wallet-not-connected>
